<template>
  <div v-if="uploadInProgress" class="w-full p-1 flex flex-col space-y-1 flex-grow">
    <div class="w-full flex flex-row space-x-2 justify-start items-center h-8">
      <CloudUploadIcon v-if="!uploadComplete" class="h-6 w-6 upload-to-cloud" />
      <CheckCircleIcon v-else class="h-6 w-6 text-green-700" />
      <div class=" font-semibold text-lg"
      :class="!uploadComplete ? 'animate-pulse' : 'text-green-700' "
      >{{!uploadComplete ? 'Uploading Photos..' : 'Complete'}}</div>
    </div>
    <div class="w-full flex flex-row justify-start p-1 items-center">
      <div class="text-md font-semibold flex-grow text-left">Files Processed: {{uploadPercentage.filter((x)=>x == 100).length + Object.keys(uploadResponseErrors).length}} of {{Object.keys(listOfUploads).length}}</div>
      <button v-if="uploadComplete" class="bn-solid px-2 py-1"
      @click="resetFileUpload()"
      >
        <div class="text-sm">Upload More..</div> 
      </button>
    </div>
    <div v-if="listOfUploads != '' || uploadErrors.length > 0 " class="w-full flex flex-col space-y-1 flex-grow flex-auto h-0 overflow-y-auto panel px-1">
      <div v-for="(item, index) in listOfUploads" :key="item" class="flex flex-row flex-wrap my-1 p-1 items-center w-full panel">
        <div class="p-1 w-full flex flex-col space-y-1">
          <div class="w-full flex flex-row items-center space-x-2">
            <PhotographIcon class="h-6 w-6" />
            <div class="w-full text-left truncate flex-grow">{{item.name}}</div>
          </div>
            <div v-if="uploadResponseErrors[index]" class="px-2">
            <div class="error py-1 truncate">{{uploadResponseErrors[index]}}</div> 
            </div>
            <div v-else class="w-full">
              <progress-bar :uploadPercentage="uploadPercentage[index]"/>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full flex flex-col flex-grow overflow-y-auto">
    <div class="flex flex-col items-center w-full p-2">
      <div class=" relative border-2 max-w-min rounded-md shadow-md p-2 self-center mb-2"
        :class="dropZoneActive ? ['bg-sky-600', 'border-sky-900'] : ''"
        @drop.prevent="listFiles"
        @dragover.prevent
        @dragenter.prevent="toggleDropZone"
        @dragleave.prevent="toggleDropZone"
      >
        <PhotographIcon class="h-12 w-12" />
      </div>
      <div class="self-center">
        <label class="btn-primary btn-text">
          <span class="text-xs">Add Photos</span>
          <input type="file" @change="listFiles($event)" @click="clearInput($event)" hidden multiple />
        </label>
      </div>
    </div>
    <div class="w-full flex flex-col flex-grow">
      <div class="w-full flex items-center justify-end px-1 pb-2">
          <button class="px-2 py-1 bn-solid-sky"
          @click="uploadPhotos()"
          v-if="Object.keys(listOfUploads).length > 0 && Object.keys(listOfUploads.filter((x)=>x.convertingFile == true)).length == 0 "
          >
            <UploadIcon />
            <div>Upload</div>
          </button>
      </div>
      <div v-if="listOfUploads != '' || uploadErrors.length > 0 " class="w-full flex flex-col space-y-1 flex-grow flex-auto h-0 overflow-y-auto panel px-1">
          <div class="w-full flex flex-row p-2 items-center sticky top-0 panel z-70 ">
            <div class="w-full flex items-start z-70">
              <div>Total photos to upload:  <span class="font-semibold">{{Object.keys(listOfUploads).length}}</span></div>
            </div>
            <div v-if="Object.keys(listOfUploads.filter((x)=>x.convertingFile == true)).length > 0" class="w-full flex items-start">
              <div class="text-sm animate-pulse">Converting Files:  <span class="font-semibold">{{Object.keys(listOfUploads.filter((x)=>x.convertingFile == true)).length}}</span></div>
            </div>
          </div>
          <div v-if="uploadErrors.length > 0" class="error text-left p-1 text-sm w-full">
            <div v-for="(err, index) in uploadErrors" :key="index" class="p-1 flex flex-row space-x-2">
              <div class="w-full flex-grow truncate">{{ err }}</div>
              <button class="bn-icon-small bn-red z-0" @click="removeFileFromErrorList(index)">
                <XIcon />
              </button>
            </div>
          </div>
        <div v-for="(item, index) in listOfUploads" :key="item" class="flex flex-row flex-wrap my-1 p-1 items-center w-full">
          <div v-if="item.convertingFile == true" class="w-full flex flex-row justify-start px-1 border">
            <div>
                <LoadingData :message="`Converting ${item.name} name to jpeg..`" />
            </div>
          </div>
          <div v-else class="w-full p-1 border z-0">
            <div class="w-full flex flex-row flex-nowrap items-center p-1">
              <div class="flex-grow truncate px-1 text-left">{{ item.name }}</div>
              <div class="flex-grow truncate px-3 text-right">{{ new Date(item.lastModified).toLocaleDateString() }}</div>
              <button class="bn-icon-small bn-solid-red z-0" @click="removeFileFromUploadList(index)">
                <XIcon />
              </button>
            </div>
            <div class="flex flex-row space-x-2 w-full">
              <div class="p-1">
                <img :src="imagePreview(index)" alt="Preview" class="w-16">
              </div>
              <div class="flex flex-col w-full">
                <div class="flex flex-row flex-wrap space-y-1 md:flex-nowrap md:space-x-2 md:space-y-0 p-1 items-start w-full">
                  <select v-model="item.roomId" v-if="Object(projectRooms).length !==0" class="w-full" @change="addRoomName($event,index)">
                    <option disabled hidden selected value="">Room</option>
                    <option v-for="room in projectRooms.filter(x=>x.nameOne)" :key="room._id" :value="room._id">{{room.nameOne || 'Not Named.'}}</option>
                  </select>
                  <input v-model.trim="item.description" type="text" placeholder="Description" class="w-full" maxlength="60">
                  <input v-model.trim="item.overrideDate" type="date" class="w-full">
                </div>
                <div class="w-full px-1">
                  <input v-model.trim="item.notes" type="text" placeholder="Notes" class=" w-full" maxlength="100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import progressBar from "../../utils/progressBar.vue";
import {PhotographIcon,XIcon,UploadIcon,CloudUploadIcon,CheckCircleIcon} from "@heroicons/vue/outline"
//import {format} from 'date-fns'
import {dateInputToISO,formatISODateforInput} from "@/shared"
import api from "@/api"
import global from '@/global'
import heic2any from 'heic2any'
import { v4 as uuidv4 } from 'uuid'
import { isValid } from 'date-fns';

export default {
  components: {
    progressBar,
    PhotographIcon,
    XIcon,
    UploadIcon,
    CloudUploadIcon,
    CheckCircleIcon
  },
  props: {
    uploadToId:{type:String,required:true},
    projectRooms:{type:Array,default:()=>{return []}}
  },
  emits:["uploading","fileUpdate"],
  data: function () {
    return {
      listOfUploads: [],
      dropZoneActive: false,
      uploadErrors: [],
      uploadResponseErrors:[],
      fileInput:'',
      currentUploading: false,
      uploadPercentage: [],
      uploadInProgress:false
    }
  },
  computed: {
        uploadComplete() {
          return (this.uploadPercentage.filter((x)=>x == 100).length + Object.keys(this.uploadResponseErrors).length) == Object.keys(this.listOfUploads).length 
        }
  },
  methods: {
    addRoomName: function(event,index) {
      let roomIndex = this.projectRooms.findIndex((x)=>x._id === event.currentTarget.value)
      if(roomIndex >= 0) {
       this.listOfUploads[index].roomName =  this.projectRooms[roomIndex].nameOne
      }
    },
    listFiles: async function (event) {
      let uploadFilesList;
      if (event?.target?.files) {
        uploadFilesList = event.target.files;
      }
      if (event?.dataTransfer?.files) {
        uploadFilesList = event.dataTransfer.files;
      }

      let sizeLimit = 10 * 1000 * 1000;
      let numberOfFilesLimit = 10;
      // check total number of files to be uploaded
      // Verify size is not greater than limit
      this.uploadErrors = [];

      if (
        uploadFilesList.length + this.listOfUploads.length >
        numberOfFilesLimit
      ) {
        this.uploadErrors.push(
          `File uploads limited to ${numberOfFilesLimit}.`
        );
      } else {
        for (let i = 0; i < uploadFilesList.length; i++) {
          const element = uploadFilesList[i];
          if (
            !this.listOfUploads.some(
              (e) => e.name.toLowerCase() === element.name.toLowerCase()
            ) &&
            element.type !== ""
          ) {
            if (element.size > sizeLimit) {
              this.uploadErrors.push(`${element.name} exceeds size limit of 10 MB`);
            } else {
              let slotId = uuidv4();
              element.slotId = slotId;
              element.fieldname = "Photo";
              element.roomId = "";
              element.roomName = "";
              element.description = "";
              element.overrideDate = element.lastModified && isValid(new Date(element.lastModified)) ? formatISODateforInput(new Date(element.lastModified).toISOString()) : null
              element.notes = "";
              element.convertingFile = element.type.toLowerCase().includes("hei")
                ? true
                : false;
              this.listOfUploads.push(element);
              if (element.type.toLowerCase().includes("hei")) {
                let newFileName = element.name.substring(
                  0,
                  element.name.lastIndexOf(".")
                ) || element.name;
                let blob = element;
                heic2any({
                  blob: blob,
                  toType: "image/jpeg",
                })
                .then((res) => {
                  let newElement = new File(
                    [res],
                    `${newFileName}.jpeg`,
                    { type: "image/jpeg" }
                  );
                  newElement.slotId = slotId;
                  newElement.fieldname = "Photo";
                  newElement.roomId = "";
                  newElement.roomName = "";
                  newElement.description = "";
                  newElement.overrideDate = newElement.lastModified && isValid(new Date(newElement.lastModified)) ? formatISODateforInput(new Date(newElement.lastModified).toISOString()) : null
                  newElement.notes = "";
                  newElement.convertingFile = false;
                  let eleToReplace = this.listOfUploads.findIndex(
                    (x) => x.slotId === slotId
                  );
                  if (eleToReplace >= 0) {
                    this.listOfUploads[eleToReplace] = newElement;
                  }
                });
              }
            }
          }
        }

      }
      this.dropZoneActive = false;
    },
    toggleDropZone: function () {
      this.dropZoneActive = !this.dropZoneActive;
    },
    removeFileFromUploadList: function (index) {
      if(Object.keys(this.listOfUploads).length === 1) {
        this.listOfUploads = []
        this.uploadErrors = [];
      } else {
        this.listOfUploads.splice(index, 1);
        this.uploadErrors = [];
      }
    },
    removeFileFromErrorList: function (index) {
      if(Object.keys(this.uploadErrors).length === 1) {
        this.uploadErrors = [];
      } else {
        this.uploadErrors.splice(index, 1);
      }
    },
    emitUploadList: function () {
      this.$emit("orgFilesToUpload", this.listOfUploads);
    },
    imagePreview: function (index) {
      return URL.createObjectURL(this.listOfUploads[index])
    },
    clearInput :function (event) {
      event.currentTarget.value = null
    },
    uploadPhotos: async function() {
      global.setModalBlocked(true)
      this.uploadInProgress = true
      this.$emit("uploading",true)
      this.uploadResponseErrors = []
      for await (let[index,e] of this.listOfUploads.entries()) {
        let metaData = {
          slotId:this.listOfUploads[index].slotId,
          roomId:this.listOfUploads[index].roomId,
          description:this.listOfUploads[index].description,
          photoDate:dateInputToISO(this.listOfUploads[index].overrideDate),
          notes:this.listOfUploads[index].notes,
          roomName:this.listOfUploads[index].roomName,
        }
        const form = new FormData();

        form.append(e.fieldname,e)
        form.append('roomId',metaData.roomId)
        form.append('roomName',metaData.roomName)
        form.append('description',metaData.description.substring(0, 60))
        form.append('photoDate',dateInputToISO(metaData.photoDate))
        form.append('notes',metaData.notes.substring(0, 100))

        await api
        .post("projects/uploadProjectPhotos/" + this.uploadToId , form,{
          headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage[index] = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 70));
            }.bind(this)
        })
        .then((res)=>{
          console.log(`${res.data.data} successfully uploaded.`)
          this.uploadPercentage[index] = 100
        })
        .catch((err)=>{
            this.uploadResponseErrors[index] = err.response.data?.error  || "Error Uploading File."
            this.uploadPercentage[index] = -1;
        })
      }
      this.$emit("fileUpdate")
      global.sendChangeEvent('photos',this.uploadToId,'',{projectId:this.uploadToId})
      this.$emit("uploading",false)
      global.setModalBlocked(false)
    },
    resetFileUpload: function() {
      this.uploadErrors = []
      this.uploadResponseErrors = []
      this.uploadPercentage = []
      this.listOfUploads = []
      this.uploadInProgress = false
      this.$emit("uploading",false)
    }
  },
};
</script>

<style>
</style>