<template>
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="24" 
  height="24" 
  viewBox="0 0 24 24" 
  stroke-width="2" 
  stroke="currentColor" 
  fill="currentColot" 
  stroke-linecap="round" 
  stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 17a4 4 0 1 1 4 4h-4v-4z" />
  <path d="M21 3a16 16 0 0 0 -9.309 4.704m-1.795 2.212a15.993 15.993 0 0 0 -1.696 3.284" />
  <path d="M21 3a16 16 0 0 1 -4.697 9.302m-2.195 1.786a15.993 15.993 0 0 1 -3.308 1.712" />
  <path d="M3 3l18 18" />
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>