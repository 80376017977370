<template>
    <div class="w-full flex flex-col space-y-0.5 border rounded-md p-0.5 sm:p-1">
       <div class="w-full flex flex-row items-center" :class="box.isKit ? 'py-1' : null">
            <div class="hidden sm:flex h-8 rounded-md items-center justify-center border flex-shrink-0 flex-grow-0" :class="box.boxNumber && box.boxNumber.toString().length > 5 ? 'w-14': 'w-10'">
                <div class="w-full px-0.5 text-center truncate font-semibold text-xs whitespace-normal">{{box.boxNumber}}</div>
            </div>
            <div class="w-full flex-grow flex flex-col space-y-0.5 truncate p-1">
                <div class="w-full flex flex-row items-center flex-wrap justify-between">
                    <div class="w-auto text-left whitespace-normal truncate max-h-10 p-1">{{box.alias?.manufacturer || box.manufacturer}}</div>
                    <div class="w-auto text-left whitespace-normal truncate max-h-10 p-1">{{box.alias?.partNumber || box.partNumber}}</div>
                </div>
            </div>
            <slot name="menuButton"/>
            <button class="bn-icon-only" tabindex="1" @click="toggleMenuBar()">
                <MenuAlt3Icon />
            </button>
       </div>
        <div v-if="boxItems && boxItems.length > 0" class="w-full flex flex-row items-center flex-wrap justify-between text-indigo-500">
            <div class="w-auto text-left whitespace-normal truncate max-h-10 p-1">Qty:</div>
            <div class="w-auto text-left whitespace-normal truncate max-h-10 p-1 font-semibold">{{boxItems.length}}</div>
        </div>
        <div v-if="box.description" class="w-full flex flex-row items-start truncate p-1 border-t" @click="showFullDescription = !showFullDescription">
            <div class="w-full text-left truncate" :class="{'whitespace-normal' :showFullDescription}">
                {{box.description}}
            </div>
        </div>
        <slot name=actions class="w-full flex flex-col p-1 truncate" />
        <div v-if="!box.multiBox && box.roomId && typeof box.roomId === 'object'" class="p-1 w-full text-left whitespace-normal truncate border-t">{{box.roomId.nameOne || box.roomId.nameTwo}}</div>
        <div v-else-if="!box.multiBox && box.rackId && typeof box.rackId === 'object'" class="p-1 w-full text-left whitespace-normal truncate border-t">{{box.rackId?.name}}</div>
        <div v-if="showProject && box.projectId" class="w-full whitespace-normal truncate text-left p-1 border-t">
            {{`${box.projectId?.companyProjectNumber ? box.projectId?.companyProjectNumber + '- ' : '' } ${box.projectId?.name ? box.projectId?.name : '' }`}}
        </div>
        <div v-if="showMenuBar" class="w-full flex flex-row items-center flex-wrap p-1 border-t">
            <div class="px-1">
                <Popper hover arrow placement="top" :content="'Tracking'">
                    <button class="bn-icon-only" @click="showMenu = showMenu !== 'description' ? 'description' : showMenu = null">
                        <MenuAlt4Icon/>
                    </button>
                </Popper>
            </div>
            <div v-if="box.auditTrail && box.auditTrail.length > 0" class="px-1">
                <Popper hover arrow placement="top" :content="'Audit'">
                    <button class="bn-icon-only" @click="showMenu = showMenu !== 'log' ? 'log' : null">
                        <DocumentTextIcon />
                    </button>
                </Popper>
            </div>
        </div>
        <div v-if="showMenu === 'description'" class="w-full flex flex-col max-h-96 overflow-y-auto p-1 space-y-1 border-t truncate">
            <div v-if="box.trackingNumber" class="w-full text-left whitespace-normal truncate flex-shrink-0">{{box.trackingNumber}}</div>
        </div>
        <div v-if="showMenu === 'numbers'" class="w-full flex flex-col max-h-96 overflow-y-auto p-1 space-y-1 border-t truncate">
            <div v-if="box.hasSerial" class="w-full flex flex-row items-center p-1 truncate flex-wrap justify-evenly">
                <div class="w-auto flex flex-col space-x-0.5 truncate p-1 items-start">
                    <div class="w-auto text-left whitespace-normal truncate">{{box.serialNumber}}</div>
                    <div class="w-auto text-left whitespace-normal truncate opacity-75">serial #</div>
                </div>
                <div v-for="item in box.serialNumbers || []" :key="item._id" class="w-auto flex flex-col space-x-0.5 truncate p-1 items-start">
                    <div class="w-auto text-left whitespace-normal truncate">{{item.serialNumber}}</div>
                    <div class="w-auto text-left whitespace-normal truncate opacity-75">{{item.name}}</div>
                </div>
            </div>
            <div v-if="box.hasMacAddress" class="w-full flex flex-row items-center p-1 truncate flex-wrap justify-evenly">
                <div class="w-auto flex flex-col space-x-0.5 truncate p-1 items-start">
                    <div class="w-auto text-left whitespace-normal truncate">{{box.macAddress}}</div>
                    <div class="w-auto text-left whitespace-normal truncate opacity-75">MAC</div>
                </div>
                <div v-for="item in box.macAddresses || []" :key="item._id" class="w-auto flex flex-col space-x-0.5 truncate p-1 items-start">
                    <div class="w-auto text-left whitespace-normal truncate">{{item.macAddress}}</div>
                    <div class="w-auto text-left whitespace-normal truncate opacity-75">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="showMenu === 'log'" class="w-full flex flex-col max-h-96 overflow-y-auto p-1 space-y-1 border-t">
            <div v-for="(log,index) in box.auditTrail" :key="index" class="w-full flex flex-col flex-grow space-y-0.5 truncate flex-shrink-0 p-1 border-b">
                <div v-if="isValid(new Date(log.date))" class="w-18 text-left font-semibold truncate">{{format(new Date(log.date), 'MMM dd, yyyy hh:mm aa')}}</div>
                <div class="w-auto text-left whitespace-normal truncate">{{log.message}}</div>
            </div>
        </div>
        <div v-if="box.isKit && box.items && box.items.length > 0 " class="w-full flex flex-col space-y-1 p-1 pr-0 pl-0.5 border-t border-t-sky-700">
            <slot name="kitBoxes" />
        </div>
    </div>
</template>

<script>
import {MenuAlt3Icon} from "@heroicons/vue/outline"
import {DocumentTextIcon,MenuAlt4Icon} from "@heroicons/vue/solid"
import { ref } from 'vue'
import {format,isValid} from 'date-fns'
export default {
    props:{
        box:{type:Object,default:()=>{return {}}},
        boxItems:{type:Array,default:()=>{return[]}},
        showProject:{type:Boolean,default:false}
    },
    components:{MenuAlt3Icon,DocumentTextIcon,MenuAlt4Icon},
    setup () {
        const showMenuBar = ref(false)
        const showMenu = ref(null)
        const showFullDescription = ref(false)


        const toggleMenuBar = ()=>{
            showMenuBar.value = !showMenuBar.value
            showMenu.value = null
        }

        return {
            showMenuBar,
            showMenu,
            toggleMenuBar,
            format,
            isValid,
            showFullDescription
        }
    }

}
</script>

<style>

</style>