<template>
    <div class="h-screen w-screen bg-gray-300 absolute top-0 left-0 pt-4 sm:pt-16 pb-4 px-4 overflow-auto flex justify-center min-h-768 z-90"
    @drop.prevent
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
    >
        <div class="bg-white w-full rounded-lg p-4 h-full max-w-7xl flex flex-col text-black">
            <div class="flex flex-row items-center border-b-2 w-full justify-between p-2">
                <div class="font-extrabold text-2xl text-primary overflow-y-auto">
                    <slot name="modalHeader"></slot>
                </div>
                <div>
                    <button class="btn-icon btn-primary" @click="closeModal" :disabled="isModalBlocked">
                        <XIcon class="h-5 w-5" />
                    </button>
                </div>
            </div>
            <div class="w-full p-1 flex flex-col overflow-y-auto flex-grow" id="ModalTop">
                <slot name="modalBody"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {XIcon} from "@heroicons/vue/outline"
import { inject } from 'vue'
export default {
    components:{XIcon},
    emits:["closeModal"],
    setup(_,{emit}) {

        const global = inject('global')
        const {isModalBlocked} = global

        const closeModal = ()=>{
            emit("closeModal")
        }

        return {
            closeModal,
            isModalBlocked
        }
    }
}
</script>

<style>

</style>