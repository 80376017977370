export default {
    mounted(el) {
      const autoResize = () => {
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + 'px';
      };
  
      // Initial resize
      autoResize();
  
      // Add event listeners
      el.addEventListener('input', autoResize);
      el.addEventListener('paste', autoResize);
    },
    updated(el) {
      // Resize when the component is updated
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 'px';
    },
};