<template>
    <div class="w-full flex flex-col flex-grow items-center">
    <!-- Filter -->
        <div v-if="shipment.shipmentEquipment && shipment.shipmentEquipment.length > 0" class="w-full flex flex-row items-center justify-center space-x-1 flex-nowrap p-1">
            <button class="bn-icon-only" @click="expandAll = !expandAll">
                <ArrowsExpandIcon />
            </button>
            <input v-model="equipmentFilter" type="search" class="w-full max-w-sm" placeholder="filter">
        </div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 space-y-1 truncate max-w-xl p-1 overflow-y-auto items-center">
            <div class="w-full flex flex-col flex-grow overflow-y-auto space-y-2">
    <!-- Equipment -->
                <div v-if="equipmentByManufacturer && equipmentByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Equipment</div>
                    <div v-for="(category,index) in equipmentByManufacturer " :key="index" class="w-full px-1">
                        <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                            <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                            <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                        </div>
                        <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                            <div v-for="(box,index) in category.items" :key="index" class="w-full">
                                <div v-if="box.multiBox" class="flex flex-row items-start space-x-1 p-1 truncate">
                                    <BoxReduced 
                                    :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                                    :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                                    >
                                        <template v-if="showBoxActions && (Array.isArray(box.items) && box.items.length > 1)" v-slot:actions>
                                            <div class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                <button class="bn-icon-small bn-solid-red"
                                                @click="removeItem(box.items[0]._id)"
                                                :disabled="isModalBlocked"
                                                >
                                                    <ArrowSmLeftIcon />
                                                </button>
                                                <div v-if="Array.isArray(box.items) && box.items.length > 1" class="flex flex-grow items-center justify-end px-1 space-x-1">
                                                    <button class="bn-icon-small bn-solid-sky" @click="($event)=>$event.currentTarget?.nextElementSibling ? removeMultiFromShipment($event.currentTarget.nextElementSibling,box.items[0]?.boxNumber) : null"
                                                    :disabled="isModalBlocked"
                                                    >
                                                        <ChevronDoubleLeftIcon />
                                                    </button>
                                                    <input type="number" min="2" :max="box.items.length" maxlength="4" :value="box.items.length"  class="w-10 text-center"
                                                    @input="$event?.target ? $event.target.value = formatNumberInput($event.target?.value,0,box.items.length) : null"
                                                    :disabled="isModalBlocked"
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </BoxReduced>
                                    <div v-if="Array.isArray(box.items) && !box.items.length > 1" class="py-1">
                                        <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                        @click="removeItem(box._id)"
                                        :disabled="isModalBlocked"
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                </div>
                                <div v-else class="flex flex-row items-start space-x-1 p-1 truncate">
                                    <BoxReduced :box="box" />
                                    <div class="py-1">
                                        <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                        @click="removeItem(box._id)"
                                        :disabled="isModalBlocked"
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    <!-- Kits -->
                <div v-if="kitsByManufacturer && kitsByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Kits</div>
                    <div v-for="(category,index) in kitsByManufacturer" :key="index" class="w-full px-1">
                        <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                            <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                            <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                        </div>
                        <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                            <div v-for="(box,index) in category.items" :key="index" class="w-full p-1">
                                <div class="w-full flex flex-row items-start space-x-2">
                                    <BoxReduced :box="box">
                                        <template v-slot:kitBoxes>
                                            <div v-for="kitBox in box.items" :key="kitBox._id" class="w-full pl-1">
                                                <BoxReduced :box="kitBox" />
                                            </div>
                                        </template>
                                    </BoxReduced>
                                    <div class="py-1">
                                        <button v-if="showBoxActions && box.isKit" class="bn-icon-small bn-solid-red"
                                        @click="removeItem(box._id)"
                                        :disabled="isModalBlocked"
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    <!-- Accessories -->
                <!--<div v-if="accessoriesByManufacturer && accessoriesByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Accessories</div>
                    <div v-for="(category,index) in accessoriesByManufacturer" :key="index" class="w-full px-1">
                        <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                            <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                            <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                        </div>
                        <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                            <div v-for="(box,index) in category.items" :key="index" class="w-full flex flex-row items-start space-x-1 p-1 truncate">
                                <BoxReduced 
                                :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                                :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                                >
                                    <template v-if="showBoxActions && (Array.isArray(box.items) && box.items.length > 1)" v-slot:actions>
                                        <div class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                            <button class="bn-icon-small bn-solid-red"
                                            @click="removeItem(box.items[0]._id)"
                                            :disabled="isModalBlocked"
                                            >
                                                <ArrowSmLeftIcon />
                                            </button>
                                            <div v-if="Array.isArray(box.items) && box.items.length > 1" class="flex flex-grow items-center justify-end px-1 space-x-1">
                                                <button class="bn-icon-small bn-solid-sky" @click="($event)=>$event.currentTarget?.nextElementSibling ? removeMultiFromShipment($event.currentTarget.nextElementSibling,box.items[0]?.boxNumber) : null"
                                                :disabled="isModalBlocked"
                                                >
                                                    <ChevronDoubleLeftIcon />
                                                </button>
                                                <input type="number" min="2" :max="box.items.length" maxlength="4" :value="box.items.length"  class="w-10 text-center"
                                                @input="$event?.target ? $event.target.value = formatNumberInput($event.target?.value,0,box.items.length) : null"
                                                :disabled="isModalBlocked"
                                                >
                                            </div>
                                        </div>
                                    </template>
                                </BoxReduced>
                                <div v-if="Array.isArray(box.items) && !box.items.length > 1" class="py-1">
                                    <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                    @click="removeItem(box._id)"
                                    :disabled="isModalBlocked"
                                    >
                                        <XIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
    <!-- Racks -->
                <div v-if="shipment.shipmentRacks && shipment.shipmentRacks.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full panel p-1 truncate has-details sticky top-0 z-10" @click="openDetails($event.target)">
                        <div class="w-full flex flex-row space-x-1 flex-nowrap truncate">
                            <div class="w-auto px-1 whitespace-normal truncate">Racks</div>
                            <div class="w-auto px-1 whitespace-normal truncate">{{shipment.shipmentRacks.length}}</div>
                        </div>
                    </div>
                    <div class="w-full hidden">
                        <div v-for="rack in shipment.shipmentRacks" :key="rack._id" class="w-full p-1">
                            <div class="w-full flex flex-row items-center space-x-2">
                                <RackReduced :rack="rack" />
                                <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                @click="removeRack(rack._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    <!-- Tools Required -->
                <div v-if="shipment.toolsRequired && shipment.toolsRequired.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full panel p-1 truncate has-details sticky top-0 z-10" @click="openDetails($event.target)">
                        <div class="w-full flex flex-row space-x-1 flex-nowrap truncate">
                            <div class="w-auto px-1 whitespace-normal truncate">Tools</div>
                            <div class="w-auto px-1 whitespace-normal truncate">{{shipment.toolsRequired.length}}</div>
                        </div>
                    </div>
                    <div class="w-full hidden">
                        <div v-for="tool in shipment.toolsRequired" :key="tool._id" class="w-full p-1">
                            <div class="w-full flex flex-row items-center space-x-2">
                                <div class="w-full flex flex-row items-center p-1 rounded-md border truncate justify-between">
                                    <div class="w-auto p-1 whitespace-normal truncate">{{tool.toolName}}</div>
                                    <div v-if="isValid(parseISO(tool.returnDate))" class="w-auto p-1">{{format(parseISO(tool.returnDate),'MM-dd-yyyy')}}</div>
                                </div>
                                <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                @click="removeTool(tool._id)"
                                :disabled="isToolAssigned(tool._id) || isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    <!-- Consumables -->
                <div v-if="shipment.consumables && shipment.consumables.length > 0" class="w-full flex flex-col space-y-1">
                    <div class="w-full panel p-1 truncate has-details sticky top-0 z-10" @click="openDetails($event.target)">
                        <div class="w-full flex flex-row space-x-1 flex-nowrap truncate">
                            <div class="w-auto px-1 whitespace-normal truncate">Consumables</div>
                            <div class="w-auto px-1 whitespace-normal truncate">{{shipment.consumables.length}}</div>
                        </div>
                    </div>
                    <div class="w-full hidden">
                        <div v-for="item in shipment.consumables" :key="item._id" class="w-full p-1">
                            <div class="w-full flex flex-row items-center space-x-2">
                                <div class="w-full flex flex-row items-center p-1 rounded-md border truncate justify-between">
                                    <div class="w-auto text-left p-1 whitespace-pre-wrap truncate">{{item.description}}</div>
                                </div>
                                <button v-if="showBoxActions" class="bn-icon-small bn-solid-red"
                                @click="removeConsumable(item._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {openDetails,getItemsByManufacturer,formatNumberInput} from '@/shared'
import {XIcon,ArrowsExpandIcon,ChevronDoubleLeftIcon, ArrowSmLeftIcon} from "@heroicons/vue/outline"
import BoxReduced from '@/components/cards/BoxReduced.vue'
import { ref,computed, inject} from 'vue'
import RackReduced from '@/components/cards/RackReduced.vue'
import {format,isValid,parseISO} from 'date-fns'
export default {
    props:{
        shipment:{type:Object,default:()=>{return {}}},
        showBoxActions:{type:Boolean,default:false}
    },
    components:{BoxReduced,RackReduced,XIcon,ArrowsExpandIcon,ChevronDoubleLeftIcon,ArrowSmLeftIcon},
    emits:["removeItem","removeRack","removeTool","removeConsumable","removeItemMulti"],
    setup (props,{emit}) {

        const global = inject('global')
        const {isModalBlocked} = global
        const equipmentFilter = ref(null)
        const expandAll = ref(false)

        const filteredEquipment = computed(()=>{
            if(!props.shipment?.shipmentEquipment) {return []}
            let filtered = props.shipment.shipmentEquipment
            if(equipmentFilter.value) {
                let filter = equipmentFilter.value.toLowerCase()
                filtered = filtered.filter(item=>
                    (
                        (item.manufacturer && item.manufacturer.toLowerCase().includes(filter)) ||
                        (item.partNumber && item.partNumber.toLowerCase().includes(filter)) ||
                        (item.deviceType && item.deviceType.toLowerCase().includes(filter)) ||
                        (item.description && item.description.toLowerCase().includes(filter))
                    )
                )
            }
            // Collect kitIds from filtered items
            const kitIds = new Set(filtered.map(item => item.kitId).filter(kitId => kitId));

            // Include all items with a kitId that matches any kitId in the filtered results
            const withKitIds = props.shipment.shipmentEquipment.filter(item => kitIds.has(item.kitId));

            // Combine filtered items with those that have matching kitIds, avoiding duplicates
            const combined = [...filtered, ...withKitIds.filter(item => !filtered.includes(item))];

            return combined;
        })

        const equipmentByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'equipment')
        });

        const kitsByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'kits')
        });

        //const accessoriesByManufacturer = computed(() => {
        //    return getItemsByManufacturer(filteredEquipment.value,'accessories')
        //});

        const removeItem = (id)=>{
            emit("removeItem",id)
        }

        const removeRack = (id)=>{
            emit("removeRack",id)
        }

        const removeTool = (id)=>{
            emit("removeTool",id)
        }

        const removeConsumable = (id)=>{
            emit("removeConsumable",id)
        }

        const removeMultiFromShipment = (event,boxNumber)=>{
            if(event.value && Number(event.value)) {
                emit("removeItemMulti",{amount:event.value,boxNumber:boxNumber})
            } else {
                console.error('Data Error removing multiBox Items.')
            }
            
        }

        const isToolAssigned = (id)=>{
            return Array.isArray(props.shipment.shipmentTools) && props.shipment.shipmentTools.some(tool => tool.requirementId === id);
        }

        return {
            isModalBlocked,
            equipmentFilter,
            expandAll,
            openDetails,
            removeItem,
            removeRack,
            removeTool,
            removeConsumable,
            removeMultiFromShipment,
            formatNumberInput,
            format,
            isValid,
            parseISO,
            equipmentByManufacturer,
            kitsByManufacturer,
            isToolAssigned
            //accessoriesByManufacturer,
        }
    }
}
</script>

<style>

</style>