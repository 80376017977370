<template>
<!-- Top Data -->
    <div class="w-full flex flex-row items-center space-x-2 p-1">
        <div>
            <Popper hover arrow placement="top" :content="'View Invoices'">
                <button class="bn-icon-small" :class="showIndividualInvoices ? 'bn-solid-sky' : null" @click="showIndividualInvoices = !showIndividualInvoices" :disabled="isWorking"><DocumentDuplicateIcon /></button>
            </Popper>
        </div>
        <div v-if="Object.keys(creditDetails).length > 0" class="w-full px-1 flex flex-row items-center truncate justify-end flex-wrap">
            <div v-if="creditDetails.creditLimit?.creditLimit && Number(creditDetails.creditLimit?.creditLimit)" class="px-1 truncate"
            :class="Number(creditDetails.outstandingBalance) > Number(creditDetails.creditLimit?.creditLimit) ? 'font-semibold text-red-700' : 'text-green-500'"
            >
                {{`credit limit: ${showAsUSCurrency(creditDetails.creditLimit?.creditLimit)}`}}
            </div>
            <div v-if="creditDetails.outstandingBalance && Number(creditDetails.outstandingBalance)" class="px-1 truncate"
            :class="(Number(creditDetails.outstandingBalance) + Number(invoiceAmount)) > Number(creditDetails.creditLimit?.creditLimit) ? 'font-semibold text-red-700' : null"
            >
                <div v-if="creditDetails.outstandingBalance && Number(creditDetails.outstandingBalance)" class="px-1 truncate">
                    {{`balance: ${showAsUSCurrency(creditDetails.outstandingBalance)}`}}
                </div>
            </div>
        </div>
    </div>
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- New Invoices -->
    <div v-if="!showIndividualInvoices" class="w-full flex flex-col flex-grow flex-auto h-0 items-center p-2" >
        <div class="w-full flex flex-col items-center p-1">
            <div class="w-full flex p-1">
                <button class="py-1 px-2" :class="showNewInvoiceDialog ? 'bn-solid' : 'bn-solid-green'" @click="toggleNewInvoice()" :disabled="isWorking">
                    <div>Invoice</div>
                    <PlusIcon :class="showNewInvoiceDialog ? 'transform rotate-45' : null" />
                </button>
            </div>
            <div v-if="showNewInvoiceDialog" class="w-full flex flex-col items-center p-1 justify-center">
                <div class="w-full flex flex-col space-y-1 p-1 items-center rounded-md border max-w-4xl">
                    <div class="w-full flex flex-row items-center truncate p-1 border-b">
                        <div class="w-full p-1 text-left truncate">New Invoice</div>
                        <button class="px-1 bn-solid-green" :disabled="!canSaveInvoice || isWorking" @click="saveInvoice()">
                            <div>Send</div>
                            <MailIcon />
                        </button>
                    </div>
    <!-- Invoice Type Buttons and Total -->
                    <div class="w-full flex flex-row space-x-2 items-center p-1">
                        <button class="bn-icon-small" :class="invoiceMethod.type === 'amount' ? 'bn-solid-sky' : null" @click="setType('amount')" :disabled="isWorking">
                            <div>$</div>
                        </button>
                        <button class="bn-icon-small" :class="invoiceMethod.type === 'percentage' ? 'bn-solid-sky' : null" @click="setType('percentage')" :disabled="isWorking">
                            <div>%</div>
                        </button>
                        <button class="bn-icon-small" :class="invoiceMethod.type === 'other' ? 'bn-solid-sky' : null" @click="setType('other')" :disabled="isWorking">
                            <div>?</div>
                        </button>
                        <button class="px-1" :class="isCredit ? 'bn-solid-red' : 'bn-solid opacity-60'" @click="isCredit = !isCredit" :disabled="invoiceMethod.method === 'remaining' || isWorking">
                            <div>Credit</div>
                        </button>
                        <div class="w-full flex items-center justify-end truncate" :class="isCredit ? 'text-red-500' : null">
                            <div v-if="isCredit">-</div>
                            <div class="text-right truncate">{{displayedInvoiceTotal}}</div>
                        </div>
                    </div>
    <!-- Invoice Methods -->
                    <div v-if="invoiceMethod.type === 'percentage'" class="w-full flex flex-col space-y-1 p-1">
                        <div class="w-full flex flex-wrap items-center p-1">
                            <div class="px-1">
                                <button class="px-1" :class="invoiceMethod.method === 'percentTotal' ? 'bn-solid-sky' : 'bn-solid opacity-60'" @click="setMethod('percentTotal')" :disabled="isWorking">
                                    <div>% Total</div>
                                </button>
                                </div>
                            <div class="px-1">
                                <button class="px-1" :class="invoiceMethod.method === 'percentRemaining' ? 'bn-solid-sky' : 'bn-solid opacity-60'" @click="setMethod('percentRemaining')" :disabled="isWorking">
                                    <div>% Balance</div>
                                </button>
                            </div>
                        </div>
                        <div v-if="invoiceMethod.method && invoiceMethod.method.includes('percent') " class="w-full flex flex-row items-center space-x-1 max-w-md px-2">
                            <input v-model="invoicePercentage" type="text" class="w-16 text-right" @input="handleInput('percentage',$event)" :disabled="isWorking" />
                            <div class="text-base font-semibold">%</div>
                        </div>
                    </div>
                    <div v-else-if="invoiceMethod.type !== 'other'" class="w-full flex flex-col space-y-1 p-1">
                        <div class="w-full flex flex-wrap items-center p-1">
                            <div class="px-1">
                                <button class="px-1" :class="invoiceMethod.method === 'remaining' ? 'bn-solid-sky' : 'bn-solid opacity-60'" @click="setMethod('remaining')" :disabled="isWorking">
                                    <div>Remaining</div>
                                </button>
                                </div>
                            <div class="px-1">
                                <button class="px-1" :class="invoiceMethod.method === 'custom' ? 'bn-solid-sky' : 'bn-solid opacity-60'" @click="setMethod('custom')" :disabled="isWorking">
                                    <div>Custom</div>
                                </button>
                            </div>
                        </div>
                        <div v-if="invoiceMethod.method === 'custom'" class="w-full flex flex-row items-center space-x-1 max-w-md px-1">
                            <div class="text-base font-semibold">$</div>
                            <input type="text" class="w-36 text-right" @input="handleInput('currency',$event)" placeholder="custom amount" :disabled="isWorking">
                        </div>
                    </div>
                    <div class="w-full flex flex-col p-1 items-center truncate">
                        <div class="w-full text-left truncate p-0.5">{{`Internal Comment ${invoiceMethod.type === 'other' ? '*' : ''}`}}</div>
                        <textarea v-model="internalComment" v-auto-resize maxlength="1000" class="w-full min-h-12 resize-y" :disabled="isWorking"/>
                    </div>
                </div>
            </div>
        </div>
    <!-- Summary -->
        <div class="w-full flex max-w-4xl p-1">{{`${invoices && invoices.length > 0 ? invoices.length : 0 } invoice${invoices && invoices.length !== 1 ? 's': ''}`}}</div>
        <div class="w-full flex flex-col items-center space-y-1 max-w-4xl">
            <div class="w-full flex flex-row item-center panel p-1  justify-between flex-wrap truncate">
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate text-green-600">{{showAsUSCurrency(sales)}}</div>
                    <div>Sales</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate">{{showAsUSCurrency(totalRequested)}}</div>
                    <div>Requested</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate">{{showAsUSCurrency(totalInvoicedAmount)}}</div>
                    <div>Invoiced</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate">{{showAsUSCurrency(totalPayments)}}</div>
                    <div>Revenue</div>
                </div>
            </div>
            <div class="w-full flex flex-row item-center panel p-1 justify-evenly flex-wrap truncate">
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate text-yellow-700">{{showAsUSCurrency(sales-totalInvoicedAmount)}}</div>
                    <div>Unbilled</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="text-sm truncate text-red-500">{{showAsUSCurrency(totalInvoicedAmount-totalPayments)}}</div>
                    <div>Outstanding</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center justify-center truncate">
                    <div class="text-sm sm:text-lg font-semibold truncate">{{sales ? ((totalInvoicedAmount/sales)*100).toFixed(0) : 0}}%</div>
                </div>
            </div>
        </div>
    </div>
<!-- Current Invoices -->
    <div v-else class="w-full flex flex-col flex-grow space-y-1 max-w-3xl">
        <div class="w-full flex p-1">{{`${invoices && invoices.length > 0 ? invoices.length : 0 } invoice${invoices && invoices.length !== 1 ? 's': ''}`}}</div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 items-center p-2 overflow-y-auto">
            <div class="w-full flex flex-col flex-grow flex-shrink-0 items-center overflow-y-auto space-y-1">
                <div v-for="inv in invoices" :key="inv._id" class="w-full truncate">
                    <div class="w-full flex flex-col space-y-1 justify-center truncate rounded-md border py-1 px-2">
                        <div class="w-full flex flex-row space-x-1 items-center truncate border-b py-1">
                            <div v-if="inv.requestDate" class="w-full text-left text-yellow-600">{{format(new Date(inv.requestDate),'MMM dd, yyyy')}}</div>
                            <div v-else-if="inv.invoicedDate" class="w-full text-left text-green-700">{{format(new Date(inv.invoicedDate),'MMM dd, yyyy')}}</div>
                            <div class="w-full text-right">{{inv.requestor}}</div>
                        </div>
                        <div class="w-full flex flex-row space-x-1 items-center justify-between flex-wrap truncate">
                            <div class="flex flex-col items-center truncate">
                                <div :class="Number(inv.requestedAmount) < 0 ? 'text-red-700' : null">{{showAsUSCurrency(inv.requestedAmount)}}</div>
                                <div>requested</div>
                            </div>
                            <div class="flex flex-col items-center truncate">
                                <div :class="Number(inv.requestedAmount) < 0 ? 'text-red-700' : null">{{showAsUSCurrency(inv.invoicedAmount)}}</div>
                                <div>invoiced</div>
                            </div>
                            <div class="flex flex-col items-center truncate">
                                <div>{{showAsUSCurrency(inv.totalPayments)}}</div>
                                <div>paid</div>
                            </div>
                        </div>
                        <div class="w-full text-left truncate p-1">
                            <div class="w-full p-1 border-b font-semibold">Internal Comment</div>
                            <textarea v-model="inv.internalComment" v-auto-resize maxlength="1000" class="w-full min-h-12 resize-y" placeholder="internal comment" :disabled="isWorking || !inv.isOpen"
                            @focus="prevValue = inv.internalComment" @blur="inv.internalComment !== prevValue ? updateInvoice(inv._id,'internalComment',inv.internalComment,prevValue) : null"
                            />
                        </div>
                        <div v-if="inv.invoicingComment" class="w-full text-left truncate p-1">
                            <div class="w-full p-1 border-b text-sky-600 font-semibold">Invoicing Comment (Internal)</div>
                            <div class="w-full whitespace-normal truncate p-1" v-html="inv.invoicingComment"></div>
                        </div>
                        <div class="w-full flex flex-row space-x-1 items-center justify-start flex-wrap truncate p-1 border-t">
                            <div v-if="!inv.isOpen" class="text-left px-1 truncate text-green-700">Closed</div>
                            <div v-else-if="inv.dateInvoiced && isValid(new Date(inv.dateInvoiced))" :class="getAge(inv.dateInvoiced) > 90 ? 'text-red-700' : null">
                                {{`Invoiced ` + formatDistanceToNow(new Date(inv.dateInvoiced),{addSuffix:true})}}
                            </div>
                            <div v-else-if="inv.requestDate && isValid(new Date(inv.requestDate))" :class="getAge(inv.requestDate) > 90 ? 'text-red-700' : null">
                                {{`Requested ` + formatDistanceToNow(new Date(inv.requestDate),{addSuffix:true})}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, computed, inject, onMounted, onUnmounted } from 'vue'
import api from "@/api"
import {format,isValid,differenceInCalendarDays,formatDistanceToNow} from "date-fns"
import {PlusIcon,MailIcon,DocumentDuplicateIcon } from "@heroicons/vue/outline"
import {formatUSCurrency,formatPercentage,showAsUSCurrency} from "@/shared"
import autoResize from '@/components/ui/autoResize'

export default {
    directives:{
        autoResize
    },
    props:{
        projectId:{type:String,required:true},
        clientId:{type:String,required:true},
        invoices:{type:Array,default:()=>{return[]}},
        sales:{type:Number,default:0}
    },
    components:{PlusIcon,MailIcon,DocumentDuplicateIcon},
    emits:["triggerProjectUpdate"],
    setup (props,{emit}) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const showNewInvoiceDialog = ref(false)
        const invoiceMethod = ref({
            type:'amount',
            method:null
        })
        const invoiceAmount = ref(0)
        const invoicePercentage = ref(0)
        const isCredit = ref(false)
        const internalComment = ref(null)
        const showIndividualInvoices = ref(false)
        const creditDetails = ref({})

        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getCreditDetails()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const remaining = computed(()=>{
            console.log(props.sales - (props.invoices ? props.invoices.reduce((acc,currentVal) => {return acc + currentVal.balance},0) : 0))
            return props.sales - (props.invoices ? props.invoices.reduce((acc,currentVal) => {return acc + currentVal.balance},0) : 0)
        })

        const totalRequested = computed(()=>{
            return props.invoices ? props.invoices.reduce((acc,currentVal) => {return acc + currentVal.requestedAmount},0) : 0
        })

        const totalInvoicedAmount = computed(()=>{
            return props.invoices ? props.invoices.reduce((acc,currentVal) => {return acc + currentVal.invoicedAmount},0) : 0
        })

        const totalPayments = computed(()=>{
            return props.invoices ? props.invoices.reduce((acc,currentVal) => {return acc + currentVal.totalPayments},0) : 0
        })

        const canSaveInvoice = computed(()=>{
            if(invoiceMethod.value.type === 'other') {
                return internalComment.value && internalComment.value.trim().length > 10
            } else {
                return Object.values(invoiceMethod.value).every(value => value !== null) && Number(invoiceAmount.value) && invoiceAmount.value !== 0
            }
        })

        const getCreditDetails = async ()=>{
            await api
            .get('organizations/credit/' + props.clientId)
            .then((res)=>{
                creditDetails.value = res.data.data
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const toggleNewInvoice = ()=>{
            invoiceAmount.value = 0
            invoicePercentage.value = 0
            invoiceMethod.value = {
                type:'amount',
                method:null
            }
            internalComment.value = null
            showNewInvoiceDialog.value = !showNewInvoiceDialog.value
        }

        const displayedInvoiceTotal = computed(()=>{
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(invoiceAmount.value);
        })

        const setType = (val)=>{
            if(invoiceMethod.value.type !== val) {
                invoiceAmount.value = 0
                invoicePercentage.value = 0
                invoiceMethod.value.type = val
                invoiceMethod.value.method = null
            }
        }

        const setMethod = (val)=>{
            if(invoiceMethod.value.method !== val) {
                invoiceAmount.value = 0
                invoiceMethod.value.method = val
                switch (val) {
                    case 'remaining':
                        isCredit.value = false
                        invoiceAmount.value  = remaining.value
                        break;
                    case 'percentTotal':
                        invoiceAmount.value = (invoicePercentage.value/100) * props.sales
                        break;
                    case 'percentRemaining':
                        invoiceAmount.value = (invoicePercentage.value/100) * remaining.value
                        break;
                    default:
                        break;
                }
            }
        }

        const handleInput = (type,event)=>{
            switch (type) {
                case 'percentage':
                if(event.target?.value) {
                const formattedValue = formatPercentage(event.target.value)
                event.target.value = formattedValue
                const decimalPercentage = formattedValue / 100;
                invoiceAmount.value =  invoiceMethod.value.method === 'percentRemaining' ? remaining.value * decimalPercentage : props.sales * decimalPercentage
                } else {
                    invoiceAmount.value = 0
                }
                break;
                case 'currency':
                    if(event.target?.value) {
                    const formattedValue = formatUSCurrency(event.target.value,100000000000)
                    event.target.value = formattedValue
                    invoiceAmount.value = formattedValue.replace(/[^\d.]+/g, '')
                    } else {
                        invoiceAmount.value = 0
                    }
                    break;
                default:
                    break;
            }
        }

        const getAge = (date)=>{
            let compareDate = new Date(date)
            return differenceInCalendarDays(new Date(),compareDate)
        }

        const saveInvoice = async ()=>{
            isWorking.value = true
            responseError.value = null
            setModalBlocked(true)
            let body = {
                requestDate:new Date().toISOString(),
                requestedAmount:isCredit.value ? Number(-invoiceAmount.value) || 0 : Number(invoiceAmount.value),
                internalComment:internalComment.value,
                projectId:props.projectId,
                clientId:props.clientId
            }
            await api
            .post('invoices/create',body)
            .then((res)=>{
                emit("triggerProjectUpdate")
                getCreditDetails()
                toggleNewInvoice()
                sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }
        
        const updateInvoice = async (id,key,value)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            let body = {[key]:value}
            await api
            .put(`invoices/edit/${id}`,body)
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
                    emit('triggerProjectUpdate')
                }
            })
            .catch(err=>{
                responseError.value = err.data?.response?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'invoice':
                        e.detail.data?.oganizationId === props.clientId
                        ? getCreditDetails()
                        : null
                        break;
                    case 'organization':
                        e.detail.data?.id === props.clientId
                        ? getCreditDetails()
                        : null
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            showNewInvoiceDialog,
            toggleNewInvoice,
            invoiceAmount,
            displayedInvoiceTotal,
            handleInput,
            isCredit,
            setMethod,
            invoiceMethod,
            setType,
            invoicePercentage,
            internalComment,
            canSaveInvoice,
            saveInvoice,
            isWorking,
            responseError,
            totalRequested,
            totalInvoicedAmount,
            totalPayments,
            showAsUSCurrency,
            showIndividualInvoices,
            remaining,
            format,
            isValid,
            getAge,
            creditDetails,
            updateInvoice,
            formatDistanceToNow
        }
    }
}
</script>

<style>

</style>