<template>
<div class="w-full flex flex-col flex-grow space-y-1 py-1 overflow-y-auto">
    <div class="w-full flex flex-row items-center p-1">
        <div v-for="button in menuButtons" :key="button.label" class="px-0.5">
            <button class="bn-icon-small bn-solid rounded-sm"
            :class="button.isActive ? {'bg-sky-600': button.isActive() } : null"
            :disabled="button.isAllowed ? !button.isAllowed() : false"
            @click="button.action">
                <component v-if="button.icon" :is="button.icon" />
                <div v-else-if="button.label">{{button.label}}</div>
            </button>
        </div>
        <div class="px-0.5">
            <button class="bn-icon-small bn-solid rounded-sm"
            :class="editor.isActive('textStyle', { color: '#EF4444' }) ? 'bg-red-500 text-white': 'text-red-500' "
            @click="editor.isActive('textStyle', { color: '#EF4444' }) ? editor.commands.unsetColor() :editor.chain().focus().setColor('#EF4444').run()">
                <div>T</div>
            </button>
        </div>
        <div class="px-0.5">
            <button class="bn-icon-small bn-solid rounded-sm"
            :class="editor.isActive('textStyle', { color: '#15803D' }) ? 'bg-green-700 text-white':'text-green-500' "
            @click="editor.isActive('textStyle', { color: '#15803D' }) ? editor.commands.unsetColor() :editor.chain().focus().setColor('#15803D').run()">
                <div>T</div>
            </button>
        </div>
        <div class="px-0.5">
            <button class="bn-icon-small bn-solid rounded-sm"
            @click="editor.commands.removeTextColor()">
                <BrushOffIcon  />
            </button>
        </div>
    </div>
    <div class="w-full flex flex-col flex-grow p-1 overflow-y-auto" :class="limitHeight ? 'flex-auto h-0' :  null">
        <editor-content :editor="editor" v-model="content" />
    </div>
    <div v-if="content && content.length" class="w-full px-1 py-0.5 text-left" :class="content && content.length >= 50000 ? 'text-red-500': null">{{`${content.length} chars`}}</div>
</div>


</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import { Extension } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import {onUnmounted, ref,computed} from 'vue'
import MdListIcon from '@/components/customIcons/MdListIcon.vue'
import MdHorizontalRuleVue from '@/components/customIcons/MdHorizontalRule.vue'
import TbClearFormattingIcon from '@/components/customIcons/TbClearFormattingIcon.vue'
import UndoIcon from '@/components/customIcons/UndoIcon.vue'
import RedoIcon from '@/components/customIcons/RedoIcon.vue'
import BrushOffIcon from '@/components/customIcons/BrushOffIcon.vue'
import {MenuAlt2Icon,MenuAlt3Icon,MenuIcon} from '@heroicons/vue/outline'

export default {
    props:{
        currentContent:{type:String,default:''},
        limitHeight:{type:Boolean,default:false}
    },
    components: {
        EditorContent,
        MdListIcon,
        MdHorizontalRuleVue ,
        TbClearFormattingIcon,
        UndoIcon,
        RedoIcon,
        BrushOffIcon,
        MenuAlt2Icon,
        MenuAlt3Icon,
        MenuIcon
    },
    emits:["content","blur"],
    setup(props,{emit}) {

        const content = ref(props.currentContent)
        const RemoveTextColorExtension = Extension.create({
        name: 'removeTextColor',

        addCommands() {
            return {
            removeTextColor: () => ({ tr, state, dispatch }) => {
                let updated = false;
                state.doc.descendants((node, pos) => {
                if (node.isInline && node.marks.length) {
                    const marksToRemove = node.marks.filter(mark => mark.type.name === 'textStyle' && mark.attrs.color);
                    if (marksToRemove.length) {
                    updated = true;
                    marksToRemove.forEach(mark => {
                        tr.removeMark(pos, pos + node.nodeSize, mark.type);
                    });
                    }
                }
                });

                if (updated && dispatch) {
                dispatch(tr);
                }
                return updated;
            },
            };
        },
        })
        const editor = new Editor({
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                Color.configure({
                types: ['textStyle'],
                }),
                Highlight,
                Link.configure({
                    openOnClick:true,
                    linkOnPaste:true,
                    protocols:['mailto'],
                    HTMLAttributes:{
                        class:'text-sky-600 underline hover:cursor-pointer'
                    }
                }),
                RemoveTextColorExtension
            ],
            content: content.value, // Initialize the editor with the content
            onUpdate: ({ editor }) => {
                content.value = editor.getHTML() // Update the content when the editor changes
                emit("content",content.value)
            },
            onBlur:()=>{
                emit("blur")
            },
            editorProps:{
                attributes:{
                    class:' flex flex-col flex-grow h-auto overflow-y-auto bg-neutral-200 rounded-md mt-0 py-3 px-6 overflow-y-auto text-left text-xs focus:outline-none focus:ring-1 placeholder-neutral-400 text-neutral-700 dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:ring-neutral-300 list-disc'
                },
                // eslint-disable-next-line no-unused-vars
                handlePaste: (view, event, slice) => {
                    const html = (event.clipboardData || window.clipboardData).getData('text/html');

                    if (html) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(html, 'text/html');

                        // Helper function to retain essential styles and remove others
                        const retainEssentialStyles = (element) => {
                            const allowedStyles = ['font-weight', 'text-decoration','margin-left'];
                            const style = element.getAttribute('style');
                            if (style) {
                                const styleRules = style.split(';').filter(rule => {
                                    const [property] = rule.split(':').map(item => item.trim());
                                    return allowedStyles.includes(property);
                                });
                                element.setAttribute('style', styleRules.join('; '));
                            }
                        };

                        // Remove comments like <!--StartFragment--> and <!--EndFragment-->
                        const comments = doc.createTreeWalker(doc.body, NodeFilter.SHOW_COMMENT);
                        let node = comments.nextNode();
                        while (node) {
                            const nextNode = comments.nextNode();
                            if (node.nodeValue.trim().startsWith('StartFragment') || node.nodeValue.trim().startsWith('EndFragment')) {
                                node.parentNode.removeChild(node);
                            }
                            node=nextNode
                        }

                        // Remove empty span elements and clean up styles
                        doc.querySelectorAll('span').forEach(span => {
                            if (!span.hasAttributes() && !span.textContent.trim()) {
                                span.parentNode.removeChild(span);
                            } else {
                                retainEssentialStyles(span);
                            }
                        });

                        // Clean up styles of all elements and remove unnecessary attributes
                        doc.querySelectorAll('*').forEach(element => {
                            retainEssentialStyles(element);
                            // Remove unnecessary attributes
                            const attributesToRemove = ['class', 'id'];
                            attributesToRemove.forEach(attr => {
                                if (element.hasAttribute(attr)) {
                                    element.removeAttribute(attr);
                                }
                            });
                        });

                        // Remove unwanted tags (e.g., <script>, <style>)
                        const unwantedTags = ['script', 'style'];
                        unwantedTags.forEach(tag => {
                            doc.querySelectorAll(tag).forEach(element => {
                                element.parentNode.removeChild(element);
                            });
                        });

                        // Optionally, remove empty tags (other than <span>)
                        doc.querySelectorAll('*').forEach(element => {
                            if (element.tagName !== 'SPAN' && !element.textContent.trim() && !element.hasChildNodes()) {
                                element.parentNode.removeChild(element);
                            }
                        });

                        // Preserve lists and their styles
                        doc.querySelectorAll('ul, ol').forEach(list => {
                            retainEssentialStyles(list);
                        });

                        // Preserve list items and their styles
                        doc.querySelectorAll('li').forEach(listItem => {
                            retainEssentialStyles(listItem);
                        });

                        const newHtml = doc.body.innerHTML;

                        // Prevent the default paste behavior
                        event.preventDefault();

                        // Insert the modified HTML as content
                        if (newHtml) {
                            editor.commands.insertContent(newHtml, {
                                parseOptions: {
                                    html: true
                                },
                                at: editor.state.selection.from
                            });
                        }

                        return true;
                    }

                    return false; // Use default paste handler if no HTML content
                }
            }
         })

        const menuButtons = computed(()=>{
            return [
                {
                label: 'B',
                action: () => editor.chain().focus().toggleBold().run(),
                isActive: ()=> editor.isActive('bold')
            },
            {
                label: 'I',
                action: () => editor.chain().focus().toggleItalic().run(),
                isActive: ()=> editor.isActive('italic')
            },
            {
                label: 'H1',
                action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
                isActive: () => editor.isActive('heading', { level: 1 })
            },
            {
                label: 'h2',
                action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: () => editor.isActive('heading', { level: 2 })
            },
            {
                icon:MdListIcon,
                action: () => editor.chain().focus().toggleBulletList().run(),
                isActive: () => editor.isActive('bulletList')
            },
            {
                icon:MdHorizontalRuleVue,
                action: () => editor.chain().focus().setHorizontalRule().run()
            },
            {
                icon:TbClearFormattingIcon,
                action: () => editor.chain().focus().clearNodes().run()
            },
            {
                icon:MenuAlt2Icon,
                action: () => editor.chain().focus().setTextAlign('left').run(),
                isActive: () => editor.isActive({ textAlign: 'left' })
            },
            {
                icon:MenuIcon,
                action: () => editor.chain().focus().setTextAlign('center').run(),
                isActive: () =>  editor.isActive({ textAlign: 'center' })
            },            
            {
                icon:MenuAlt3Icon,
                action: () => editor.chain().focus().setTextAlign('right').run(),
                isActive: () => editor.isActive({ textAlign: 'right' })
            },
            {
                icon:UndoIcon,
                action: () => editor.chain().focus().undo().run(),
                isAllowed: () => editor.can().chain().focus().undo().run()
            },
            {
                icon:RedoIcon,
                action: () => editor.chain().focus().redo().run(),
                isAllowed: () => editor.can().chain().focus().redo().run()
            },
            {
                label: 'Hi',
                action: () => editor.chain().focus().toggleHighlight().run(),
                isActive: () => editor.isActive('highlight')
            },
            ]
        })
        

        onUnmounted(()=>{
            editor ? editor.destroy() : null
        })



        return { editor,content,menuButtons }
    },
}
</script>
<style >
/* Basic editor styles */

</style>