<template>
  <WorkingAndError :isWorking="isWorking" :error="responseError" />
  <div class="w-full flex flex-col flex-grow items-center space-y-1 p-1 max-w-4xl">
<!-- Top Menu -->
    <div class="w-full p-1 flex items-center justify-between">
      <button class="bn-solid-green px-2 py-0.5" @click="showNewShipmentDialog = !showNewShipmentDialog"
      :disabled="isWorking || !allowEdit"
      >
        <div>shipment</div>
        <PlusIcon :class="{ 'rotate-45': showNewShipmentDialog }" />
      </button>
      <div class="w-auto flex items-center">
        <div class="px-1">
          <Popper hover arrow placement="top" :content="isGettingEquipment ? 'getting equipment': !equipmentError ? 'equipment obtained' : 'equip. error, click to retry'">
            <button class="bn-icon-only" 
            :class="[{ 'animate-pulse': isGettingEquipment }, { 'text-green-700 dark:text-green-500': !isGettingEquipment && !equipmentError }, { 'text-red-500': equipmentError }]"
            :disabled="isWorking || isGettingEquipment || !equipmentError"
            @click="getProjectEquipment()"
            >
              <BoxIcon />
            </button>
          </Popper>
        </div>
        <div class="px-1">
          <Popper hover arrow placement="top" :content="isGettingRacks ? 'getting racks': !rackError ? 'racks obtained' : 'rack error, click to retry'">
            <button class="bn-icon-only" 
            :class="[{ 'animate-pulse': isGettingRacks }, { 'text-green-700 dark:text-green-500': !isGettingRacks && !rackError }, { 'text-red-500': rackError }]"
            :disabled="isWorking || isGettingRacks || !rackError"
            @click="getProjectRacks()"
            >
              <BsBookshelfIcon />
            </button>
          </Popper>
        </div>
        <div class="px-1">
          <Popper hover arrow placement="top" :content="isGettingTools ? 'getting racks': !toolsError ? 'tools obtained' : 'tool error, click to retry'">
            <button class="bn-icon-only" 
            :class="[{ 'animate-pulse': isGettingTools }, { 'text-green-700 dark:text-green-500': !isGettingTools && !toolsError }, { 'text-red-500': toolsError }]"
            :disabled="isWorking || isGettingTools || !toolsError"
            @click="getToolsCatalog()"
            >
              <ToolboxIcon />
            </button>
          </Popper>
        </div>
        <div v-if="allowEdit" class="px-1">
          <ToolReturns ref="toolReturnComponent" :projectId="project._id" @update="$event.shipment ? addNewShipment($event.shipment) : null" />
        </div>
      </div>
    </div>
<!-- New Shipment -->
    <Modal v-if="showNewShipmentDialog" :title="'New Shipment'" @closeModal="showNewShipmentDialog = false" >
      <template v-slot:content>
        <NewShipment :project="project" :organization="organization" @newShipment="addNewShipment($event)" @updateShipment="updateShipment($event)" />
      </template>
    </Modal>
<!-- Shipments -->
    <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-1">
      <div class="w-full flex flex-col space-y-1 flex-grow overflow-y-auto">
        <div v-for="shipment in sortedShipments" :key="shipment._id" class="w-full">
          <div class="w-full p-1 flex whitespace-normal">
            <div class="w-full flex flex-col py-1 px-2 border rounded-md" :class="{'border-2 border-sky-700' : shipment.method === 'integrator'}">
              <div class="w-full flex flex-row justify-between truncate p-1 border-b">
                  <div class="w-full text-left whitespace-normal truncate font-semibold">
                      {{`${shipment.projectId?.companyProjectNumber ? `${shipment.method === 'integrator' ? 'Integrator Delivery - ' : ''}${shipment.projectId.companyProjectNumber}` : ''}${shipment.shipmentNumber ? ' - ' + shipment.shipmentNumber : ''}`}}
                  </div>
                  <div v-if="shipment.shipmentAddress" class="px-1">
                      <Popper hover arrow placement="top" :content="'show address'">
                          <button class="bn-icon-only" @click="showAddressId !== shipment._id ? showAddressId = shipment._id : showAddressId = null " 
                          :class="{'text-sky-600': showAddressId === shipment._id}">
                              <LocationMarkerIcon />
                          </button>
                      </Popper>
                  </div>
                  <div class="px-1">
                    <Popper hover arrow placement="top" :content="'delivery contact'">
                        <button class="bn-icon-only" @click="showContactId !== shipment._id ? showContactId = shipment._id : showContactId = null"
                        :class="(shipment.internalDeliveryContact?._id || shipment.externalDeliveryContact) ? 'text-green-600' : 'text-red-600'"
                        >
                            <XIcon v-if="showContactId === shipment._id" />
                            <UserIcon v-else />
                        </button>
                    </Popper>
                  </div>
                  <div v-if="shipment.method !== 'integrator'" class="flex items-center">
                    <div v-if="shipment.shipmentState > 2" class="px-1 icon-only text-green-700">
                      <Popper hover arrow placement="top" :content="'shipped'">
                          <CheckCircleIcon />
                      </Popper>
                    </div>
                    <div v-else-if="shipment.shipmentState > 1" class="px-1 icon-only">
                      <Popper hover arrow placement="top" :content="'locked by warehouse'">
                        <LockClosedIcon />
                      </Popper>
                    </div>
                    <div v-if="shipment.shipmentState < 3" class="px-1">
                        <Popper hover arrow placement="top" :content="'Ship'">
                            <button class="bn-icon-only"
                            @click="moveShipmentForward(shipment._id)"
                            :class="{'text-green-500':shipment.shipmentState > 0}"
                            :disabled="isWorking || !canShip(shipment._id) || shipment.shipmentState > 0"
                            >
                                <TruckIcon />
                            </button>
                        </Popper>
                    </div>
                    <div v-if="shipment.packingListId" class="px-1">
                        <Popper hover arrow placement="top" :content="'Packing List'">
                            <button class="bn-icon-only" @click="showPackingListId = shipment._id;showShipmentId = null;"
                            :disabled="isWorking"
                            >
                                <DocumentTextIcon />
                            </button>
                        </Popper>
                    </div>
                  </div>
                  <div class="px-1">
                      <Popper hover arrow placement="top" :content="'manage shipment'">
                          <button class="bn-icon-only" @click="showShipmentId = shipment._id;showPackingListId = null;">
                              <ExternalLinkIcon />
                          </button>
                      </Popper>
                  </div>
              </div>
              <div class="w-full flex flex-row items-center justify-between flex-wrap truncate">
                <div class="w-48 flex flex-row items-center flex-wrap">
                  <div class="p-1 w-auto flex-shrink-0">
                      <div v-if="isValid(parseISO(shipment.shipmentDate))">{{format(parseISO(shipment.shipmentDate),'MMM dd, yyyy h:mm aaa')}}</div>
                  </div>
                  <div class="w-auto px-2" :class="shipment.scheduleType === 'firm' ? 'text-red-500' : 'text-green-600'">
                    {{shipment.scheduleType}}
                  </div>
                </div>
                <div class="p-1 w-20">
                    <div v-if="shipment.method === 'tool'" class="text-sky-700 dark:text-sky-600">Tool Pickup</div>
                    <div v-else-if="shipment.method">{{shipment.method.charAt(0).toUpperCase() + shipment.method.slice(1)}}</div>
                </div>
                <div class="p-1 w-20">
                    <div v-if="shipment.locationType && shipment.method !== 'tool'" class="text-left whitespace-normal truncate">{{shipment.locationType.charAt(0).toUpperCase() + shipment.locationType.slice(1)}}</div>
                </div>
              </div>
              <div v-if="shipment.method === 'courier'" class="w-full flex flex-col items-center truncate py-1 px-2 border-t">
                  <div class="w-full flex flex-row items-center justify-between p-1 flex-wrap sm:flex-nowrap">
                      <div class="w-auto text-left whitespace-normal truncate">{{shipment.carrier}}</div>
                      <div class="w-auto text-left whitespace-normal truncate">{{shipment.acctNumber}}</div>
                  </div>
                  <div v-if="shipment.courierTrackingNumber" class="w-full p-1 flex items-center">
                    <div class="w-full text-left whitespace-normal truncate">{{shipment.courierTrackingNumber}}</div>
                  </div>
              </div>
              <div v-if="showContactId === shipment._id" class="w-full flex flex-col p-1 border-t">
                <div v-if="!shipment.internalDeliveryContact?._id && !shipment.externalDeliveryContact" class="w-full p-1 flex flex-col">
                    <div class="w-full flex flex-col space-y-2 py-2">
                        <div class="w-full p-1 border rounded-md">
                            <div class="w-full p-1 border-b text-left whitespace-normal truncate">Search DLSTracker</div>
                            <div class="w-full flex p-1 max-w-md">
                                <searchApi :apiEndpoint="'search/internalContacts'" :displayResult="false" :placeholder="'DLSTracker Contact'" @resultSelected="updateInternalDeliveryContact(shipment._id,$event)" />
                            </div>
                        </div>
                        <div class="w-full p-1 border rounded-md">
                            <div class="w-full p-1 border-b text-left whitespace-normal truncate">External Contact</div>
                            <ExternalContact @emitContact="updateExternalDeliveryContact(shipment._id,$event)" />
                        </div>
                    </div>
                </div>
                <div v-else class="w-full flex flex-col">
                    <div class="w-full p-1 text-left whitespace-normal truncate font-semibold border-b">Shipment Contact</div>
                      <div class="w-full flex flex-row items-center truncate">
                          <div v-if="shipment.internalDeliveryContact?._id" class="flex-grow text-left whitespace-normal truncate p-1 font-semibold">{{shipment.internalDeliveryContact?.name}}</div>
                          <div v-else-if="Object.keys(shipment.externalDeliveryContact).length > 0" class="w-full flex flex-row items-center truncate flex-wrap">
                              <div class="w-full max-w-48 text-left p-1 flex items-center font-semibold">
                                  <div class="w-full">
                                      <input v-model.trim="shipment.externalDeliveryContact.name" type="text" class="w-full" placeholder="name"
                                      @blur="updateExternalDeliveryContact(shipment._id,shipment.externalDeliveryContact)"
                                      >
                                  </div>
                              </div>
                              <div class="w-full max-w-48 p-1 whitespace-normal truncate">
                                  <input v-model.trim="shipment.externalDeliveryContact.email" type="email" class="w-full" placeholder="email"
                                  :class="{'border border-red-600':shipment.externalDeliveryContact.email && !isEmailValid(shipment.externalDeliveryContact.email)}"
                                  @focus="prevValue = shipment.externalDeliveryContact.email"
                                  @blur="isEmailValid(shipment.externalDeliveryContact.email) || shipment.externalDeliveryContact.email === null || shipment.externalDeliveryContact.email === '' ? updateExternalDeliveryContact(shipment._id,shipment.externalDeliveryContact) : shipment.externalDeliveryContact.email = prevValue"
                                  >
                              </div>
                              <div class="w-auto p-1 whitespace-normal truncate">
                                  <PhoneInput :allowEdit="true" :currentValue="shipment.externalDeliveryContact?.phone" 
                                    @phoneValidated="
                                    if(!(Object.keys($event).length === 0 && !shipment.externalDeliveryContact?.phone?.rawValue)) {
                                        shipment.externalDeliveryContact.phone = $event;
                                        updateExternalDeliveryContact(shipment._id, shipment.externalDeliveryContact);
                                    }
                                  "
                                  />
                              </div>
                          </div>
                          <div class="p-1">
                            <Popper hover arrow placement="top" :content="'clear contacts'">
                                <button class="bn-icon-only" @click="clearContacts(shipment._id)">
                                    <TrashIcon />
                                </button>
                            </Popper>
                          </div>
                    </div>
                </div>
              </div>
              <div v-if="shipment.method === 'integrator'" class="w-full flex flex-col p-1 border-t">
                <div class="w-full whitespace-normal truncate p-1 border-b text-left font-semibold">Driver Contact</div>
                <div v-if="!shipment.integratorDeliveryDriver" class="w-full p-1">
                  <ExternalContact @emitContact="updateIntegratorDeliveryDriver(shipment._id,$event)" />
                </div>
                <div v-else-if="Object.keys(shipment.integratorDeliveryDriver).length > 0" class="w-full p-1 flex items-center truncate">
                  <div class="w-full flex flex-row items-center truncate flex-wrap">
                      <div class="w-full max-w-48 text-left p-1 flex items-center font-semibold">
                          <div class="w-full">
                              <input v-model.trim="shipment.integratorDeliveryDriver.name" type="text" class="w-full" placeholder="name"
                              @blur="updateIntegratorDeliveryDriver(shipment._id,shipment.integratorDeliveryDriver)"
                              >
                          </div>
                      </div>
                      <div class="w-full max-w-48 p-1 whitespace-normal truncate">
                          <input v-model.trim="shipment.integratorDeliveryDriver.email" type="email" class="w-full" placeholder="email"
                          :class="{'border border-red-600':shipment.integratorDeliveryDriver.email && !isEmailValid(shipment.integratorDeliveryDriver.email)}"
                          @focus="prevValue = shipment.integratorDeliveryDriver.email"
                          @blur="isEmailValid(shipment.integratorDeliveryDriver.email) || shipment.integratorDeliveryDriver.email === null || shipment.integratorDeliveryDriver.email === '' ? updateIntegratorDeliveryDriver(shipment._id,shipment.integratorDeliveryDriver) : shipment.integratorDeliveryDriver.email = prevValue"
                          >
                      </div>
                      <div class="w-auto p-1 whitespace-normal truncate">
                          <PhoneInput :allowEdit="true" :currentValue="shipment.integratorDeliveryDriver?.phone" 
                            @phoneValidated="
                            if(!(Object.keys($event).length === 0 && !shipment.integratorDeliveryDriver?.phone?.rawValue)) {
                                shipment.integratorDeliveryDriver.phone = $event;
                                updateIntegratorDeliveryDriver(shipment._id, shipment.integratorDeliveryDriver);
                            }
                          "
                          />
                      </div>
                  </div>
                  <div class="p-1">
                    <Popper hover arrow placement="top" :content="'clear integrator driver'">
                        <button class="bn-icon-only" @click="clearIntegratorDeliveryDriver(shipment._id)">
                            <TrashIcon />
                        </button>
                    </Popper>
                  </div>
                </div>
              </div>
              <div v-if="shipment.shipmentInstructions" class="w-full flex flex-col items-center overflow-y-auto border-t">
                <div class="w-full flex items-center whitespace-normal truncate p-1 font-semibold">Instructions:</div>
                <div class="w-full whitespace-pre-wrap text-left px-2">{{shipment.shipmentInstructions}}</div>
              </div>
              <div v-if="shipment.warehouseNote" class="w-full flex flex-col items-center overflow-y-auto border-t">
                <div class="w-full flex items-center whitespace-normal truncate p-1 font-semibold text-sky-600">Warehouse Note:</div>
                <div class="w-full whitespace-pre-wrap text-left px-2">{{shipment.warehouseNote}}</div>
              </div>
              <div v-if="showAddressId === shipment._id && shipment.shipmentAddress" class="w-full flex flex-col py-1 px-3 border-t">
                  <DisplayAddress :address="shipment.shipmentAddress" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- Shipment -->
    <Modal v-if="showShipmentObject?._id" :title="`${showShipmentObject?.projectId?.companyProjectNumber || ''}${showShipmentObject?.shipmentNumber ? ' - ' + showShipmentObject?.shipmentNumber : ''}${showShipmentObject?.method === 'integrator' ? '- Integrator Delivery' : ''}`" @closeModal="showShipmentId = null">
      <template v-slot:content>
        <Shipment :shipmentId="showShipmentObject?._id" :equipment="equipment" :racks="racks" :tools="tools" :allowEdit="allowEdit && showShipmentObject.shipmentState < 2"
        @updateShipment="updateShipment($event)" @deleteShipment="getShipments('silent')" @updateEquipment="updateEquipment($event)" @updateRack="updateRack($event)" @moveShipmentForward="showShipmentId = null;moveShipmentForward($event)" @updateToolReturn="updateToolReturn($event)" />
      </template>
    </Modal>
    <Modal v-if="showPackingListObject?._id" :title="`Packing List: ${showPackingListObject?.projectId?.companyProjectNumber || ''} - ${showPackingListObject?.shipmentNumber || ''} `" @closeModal="showPackingListId = null" >
      <template v-slot:content>
        <ShowPackingList :shipment="showPackingListObject" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, onMounted, ref, inject, onUnmounted } from 'vue'
import {isEmailValid} from '@/shared'
import {format,isValid,parseISO} from 'date-fns'
import {PlusIcon,ExternalLinkIcon,TrashIcon,XIcon} from "@heroicons/vue/outline"
import {LocationMarkerIcon,TruckIcon,LockClosedIcon,CheckCircleIcon,DocumentTextIcon,UserIcon} from "@heroicons/vue/solid"
import NewShipment from '@/components/projects/shipping/NewShipment.vue'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import Shipment from '@/components/projects/shipping/Shipment.vue'
import ShowPackingList from '@/components/projects/shipping/ShowPackingList.vue'
import BoxIcon from '@/components/customIcons/BoxIcon.vue'
import BsBookshelfIcon from '@/components/customIcons/BsBookshelfIcon.vue'
import ToolboxIcon from '@/components/customIcons/ToolboxIcon.vue'
import api from '@/api'
import searchApi from '@/components/ui/searchApi.vue'
import ExternalContact from '@/components/ui/ExternalContact.vue'
import PhoneInput from '@/components/ui/PhoneInput.vue'
import ToolReturns from '@/components/warehouse/tools/ToolReturns.vue'


export default {
  props:{
    project:{type:Object,default:()=>{return {}}},
    allowEdit:{type:Boolean,default:false}
  },
  components:{NewShipment,Shipment,DisplayAddress,ShowPackingList,searchApi,PhoneInput,ExternalContact,ToolReturns,PlusIcon,ExternalLinkIcon,LocationMarkerIcon,BoxIcon,BsBookshelfIcon,ToolboxIcon,TruckIcon,LockClosedIcon,CheckCircleIcon,DocumentTextIcon,UserIcon,TrashIcon,XIcon},
  setup(props) {
    const global = inject('global')
    const {setModalBlocked,sendChangeEvent} = global
    const isWorking = ref(false)
    const isGettingEquipment = ref(false)
    const isGettingRacks = ref(false)
    const isGettingTools = ref(false)
    const responseError = ref(null)
    const equipmentError = ref(false)
    const rackError = ref(false)
    const toolsError = ref(false)
    
    const showNewShipmentDialog = ref(false)
    const shipments = ref([])
    const equipment = ref([])
    const racks = ref([])
    const tools = ref([])
    const organization = ref({})
    const showAddressId = ref(null)
    const showShipmentId = ref(null)
    const showPackingListId = ref(null)
    const showContactId = ref(null)

    const toolReturnComponent = ref(null)

    onMounted(()=>{
      window.addEventListener("data_change_from_socket",handleSocketChange)
      getShipments()
      getProjectEquipment()
      getProjectRacks()
      getToolsCatalog()
      getShippingOptions()
    })

    onUnmounted(()=>{
      window.removeEventListener("data_change_from_socket",handleSocketChange)
    })

    const sortedShipments = computed(()=>{
      return shipments.value.slice().sort((a, b) => {
        // Sort by shipmentNumber in reverse order
        if (a.shipmentNumber && b.shipmentNumber) {
          return b.shipmentNumber - a.shipmentNumber;
        }

        // If only one has shipmentNumber, sort the one without it to the bottom
        if (a.shipmentNumber && !b.shipmentNumber) {
          return -1;
        }
        if (!a.shipmentNumber && b.shipmentNumber) {
          return 1;
        }

        // If neither have shipmentNumber, sort by shipmentDate
        if (a.shipmentDate && b.shipmentDate) {
          return new Date(a.shipmentDate) - new Date(b.shipmentDate);
        }

        // Handle cases where shipmentDate might be missing
        if (a.shipmentDate && !b.shipmentDate) {
          return -1;
        }
        if (!a.shipmentDate && b.shipmentDate) {
          return 1;
        }

        return 0; // If both fields are missing, consider them equal
      });
    }
  )

    const showShipmentObject = computed(()=>{
      return showShipmentId.value ? shipments.value.find(x=>x._id === showShipmentId.value) : {}
    })

    const showPackingListObject = computed(()=>{
      return showPackingListId.value ? shipments.value.find(x=>x._id === showPackingListId.value) : {}
    })

    const getShipments = async (type)=>{
      startAPI(type)
      await api
      .get(`shipments/project/${props.project._id}`)
      .then((res)=>{
        Array.isArray(res.data?.data)
        ? shipments.value = res.data.data
        : null
      })
      .catch((err)=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const getShippingOptions = async (type)=>{
      startAPI(type)
      await api
      .get(`projects/shippingOptions/${props.project._id}`)
      .then((res)=>{
          if(res.data.data && typeof res.data.data === 'object') {
              organization.value = res.data.data
          }
      })
      .catch((err)=>{
          console.error(err.response?.data?.error || err.message)
      })
      .finally(()=>{
          stopAPI()
      })
    }

    const getProjectEquipment = async (type)=>{
      type !== 'silent' ? isGettingEquipment.value = true : null
      equipmentError.value = false
      await api
      .get(`equipment/${props.project._id}`)
      .then((res)=>{
          Array.isArray(res.data?.data)
          ? equipment.value = res.data.data
          :null
      })
      .catch((err)=>{
          type !== 'silent' ? equipmentError.value = true : null
          console.error(err.response?.data?.error || err.message)
      })
      .finally(()=>{
          isGettingEquipment.value = false
      })
    }

    const getProjectRacks = async (type)=>{
      type !== 'silent' ? isGettingRacks.value = true : null
      rackError.value = false
      await api
      .get(`racks/${props.project._id}`)
      .then((res)=>{
          Array.isArray(res.data?.data)
          ? racks.value = res.data.data
          :null
      })
      .catch((err)=>{
          type !== 'silent' ? rackError.value = true : null
          console.error(err.response?.data?.error || err.message)
      })
      .finally(()=>{
          isGettingRacks.value = false
      })
    }

    const getToolsCatalog = async(type)=>{
      type !== 'silent' ? isGettingTools.value = true : null
      toolsError.value = false
      await api
      .get('toolsCatalog')
      .then((res)=>{
        Array.isArray(res.data?.data)
        ? tools.value = res.data.data
        : null
      })
      .catch((err)=>{
          type !== 'silent' ? toolsError.value = true : null
          console.error(err.response?.data?.error || err.message)
      })
      .finally(()=>{
          isGettingTools.value = false
      })
    }

    const moveShipmentForward = async (id)=>{
      startAPI()
      await api
      .put(`shipments/state/forward/${id}`,{})
      .then((res)=>{
        if(res.data?.data && typeof res.data.data && !Array.isArray(res.data.data)) {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',props.project._id,'',{
            projectId:props.project._id,
            shipmentId:res.data?.data?._id,
            shipmentState:res?.data?.data?.shipmentState,
            shipmentDate:res?.data?.data?.shipmentDate,
            driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId,
          })
        }
      })
      .catch((err)=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const addNewShipment = (event)=>{
      let shipmentIndex = shipments.value.findIndex(x=>x._id === event._id)
      shipmentIndex > -1
      ? shipments.value[shipmentIndex] = event
      : shipments.value.push(event)
      sendChangeEvent('shipment',props.project._id,'',{
        projectId:props.project._id,
        shipmentId:event._id,
        shipmentState:event.shipmentState,
        shipmentDate:event.shipmentDate,
        driverId:event.driverId?._id || event.driverId,
        new:true
      })
    }

    const updateInternalDeliveryContact = async (id,event)=>{
      let body = {
        internalDeliveryContact:event?._id || null
      }
      startAPI()
      await api
      .put(`shipments/${id}`,body)
      .then(res=>{
        if(res.data?.data && typeof res.data.data === 'object') {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
        }
      })
      .catch(err=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const updateExternalDeliveryContact = async (id,event)=>{
      let body = {
        externalDeliveryContact:event
      }
      startAPI()
      await api
      .put(`shipments/${id}`,body)
      .then(res=>{
        if(res.data?.data && typeof res.data.data === 'object') {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
        }
      })
      .catch(err=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const updateIntegratorDeliveryDriver = async (id,event)=>{
      let body = {
        integratorDeliveryDriver:event
      }
      await api
      .put(`shipments/${id}`,body)
      .then(res=>{
        if(res.data?.data && typeof res.data.data === 'object') {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
        }
      })
      .catch(err=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const clearIntegratorDeliveryDriver = async (id)=>{
      let body = {
        integratorDeliveryDriver:null
      }
      await api
      .put(`shipments/${id}`,body)
      .then(res=>{
        if(res.data?.data && typeof res.data.data === 'object') {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
        }
      })
      .catch(err=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const clearContacts = async (id)=>{
      let body = {
        internalDeliveryContact: null,
        externalDeliveryContact:null
      }
      startAPI()
      await api
      .put(`shipments/${id}`,body)
      .then(res=>{
        if(res.data?.data && typeof res.data.data === 'object') {
          updateShipment(res.data.data)
          sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
        }
      })
      .catch(err=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        stopAPI()
      })
    }

    const updateShipment = (event)=>{
      let shipmentIndex = shipments.value.findIndex(x=>x._id === event._id)
      if(shipmentIndex > -1) {
        shipments.value[shipmentIndex] = event
      }
    }

    const updateEquipment = (event)=>{
      if(Array.isArray(event)) {
        event.forEach(eq=>{
          let eIndex =  equipment.value.findIndex(x=>x._id === eq._id)
          eIndex > -1
          ? equipment.value[eIndex] = eq
          : null
        })
      } else if (typeof event === 'object') {
          let eIndex =  equipment.value.findIndex(x=>x._id === event._id)
          eIndex > -1
          ? equipment.value[eIndex] = event
          : null
      }
      sendChangeEvent('equipment',props.project._id,'',{projectId:props.project._id})
    }

    const updateRack = (event)=>{
      if(Array.isArray(event)) {
        event.forEach(rack=>{
          let rIndex =  racks.value.findIndex(x=>x._id === rack._id)
          if(rIndex > -1) {
            racks.value[rIndex] = rack
            sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:rack.roomId?._id,rackId:rack._id,shipmentId:rack.shipmentId?._id,rackState:rack.rackState})
          }
        })
      } else if (typeof event === 'object') {
          let rIndex =  racks.value.findIndex(x=>x._id === event._id)
          if(rIndex > -1) {
            racks.value[rIndex] = event
            sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:event.roomId?._id,rackId:event._id,shipmentId:event.shipmentId?._id,rackState:event.rackState})
          }
      }
    }

    const updateToolReturn = (event)=>{
      if(toolReturnComponent.value && toolReturnComponent.value.toolSocketUpdate) {
        toolReturnComponent.value.toolSocketUpdate(event?._id)
        sendChangeEvent('tool','','',{toolId:event?._id})
      }
    }

    const canShip = (id)=>{
      let shipmentIndex = shipments.value.findIndex(x=>x._id === id)
      if(shipmentIndex > -1) {
        if(shipments.value[shipmentIndex].method === 'tool') {
          return (shipments.value[shipmentIndex].toolsToReturn && shipments.value[shipmentIndex].toolsToReturn.length > 0) 
        } else {
            return ((shipments.value[shipmentIndex].shipmentEquipment && shipments.value[shipmentIndex].shipmentEquipment.length > 0) ||
            (shipments.value[shipmentIndex].shipmentRacks && shipments.value[shipmentIndex].shipmentRacks.length > 0) ||
            (shipments.value[shipmentIndex].toolsRequired && shipments.value[shipmentIndex].toolsRequired.length > 0)) ||
            (shipments.value[shipmentIndex].consumables && shipments.value[shipmentIndex].consumables.length > 0)
        }
      } else {
        return false
      }
    }

    const startAPI = (type)=>{
      if(type !== 'silent') {
        setModalBlocked(true)
        isWorking.value = true
        responseError.value = null
      }
    }

    const stopAPI = ()=>{
      setModalBlocked(false)
      isWorking.value = false
    }

    const handleSocketChange = (e)=>{
      if(e.detail?.type) {
          switch(e.detail.type) {
              case 'shipment':
                  if(e.detail.data?.projectId === props.project._id || e.detail.projectId === props.project._id ) {
                      getShipments('silent')
                  }
                break;
              case 'organization':
                  if(e.detail.organizationId === organization.value._id ) {
                      getShippingOptions('silent')
                  }
                break;
              case 'toolsCatalog':
                getToolsCatalog('silent')
                break;
              case 'room':
              case 'new_equipment':
              case 'equipment':
              case 'rack':
                  if(e.detail.data?.projectId === props.project._id || e.detail.projectId === props.project._id ) {
                      getProjectEquipment('silent')
                      getProjectRacks('silent')
                  }
                  break;
              default:
                  break;
          }
      }
    }

    return {
      isWorking,
      responseError,
      format,
      isValid,
      parseISO,
      showNewShipmentDialog,
      getShipments,
      shipments,
      equipment,
      racks,
      tools,
      showAddressId,
      organization,
      addNewShipment,
      showShipmentId,
      showShipmentObject,
      isGettingEquipment,
      getProjectEquipment,
      isGettingRacks,
      getProjectRacks,
      isGettingTools,
      toolsError,
      equipmentError,
      rackError,
      updateShipment,
      updateEquipment,
      updateRack,
      getToolsCatalog,
      canShip,
      moveShipmentForward,
      showPackingListId,
      showPackingListObject,
      showContactId,
      updateInternalDeliveryContact,
      updateExternalDeliveryContact,
      updateIntegratorDeliveryDriver,
      clearContacts,
      clearIntegratorDeliveryDriver,
      isEmailValid,
      sortedShipments,
      updateToolReturn,
      toolReturnComponent
    }
  }

}
</script>

<style>

</style>