<template>
  <div class="w-full flex flex-col flex-grow items-center p-1 max-w-3xl">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div v-if="box && Object.keys(box).length > 0" class="w-full flex flex-col p-1 border rounded-md">
        <div class="w-full flex flex-row items-center p-1 justify-between">
            <div class="w-14 h-full border rounded-md p-1 font-semibold flex items-center justify-center text-sm flex-shrink-0">{{box.boxNumber}}</div>
            <div class="w-full flex flex-col space-y-1 px-1">
                <div class="w-full flex flex-row items-center flex-wrap justify-start sm:justify-between">
                    <div class="p-1">{{box.manufacturer}}</div>
                    <div class="p-1">{{box.partNumber}}</div>
                </div>
                <div class="w-full text-left whitespace-normal truncate p-1">
                    {{`${box.projectId?.companyProjectNumber ? box.projectId?.companyProjectNumber + '- ' : ''} ${box.projectId?.name}`}}
                </div>
            </div>
        </div>
        <div v-if="box.description" class="w-full flex flex-row items-start truncate p-1 border-t" @click="showFullDescription = !showFullDescription">
            <div class="w-full text-left truncate" :class="{'whitespace-normal' :showFullDescription}">
                {{box.description}}
            </div>
        </div>
        <div v-if="box.roomId?.nameOne || box.roomId?.nameTwo || box.rackId?.name" class="w-full text-left whitespace-normal truncate p-1 border-t">
            {{box.roomId?.nameOne || box.roomId?.nameTwo || box.rackId?.name}}
        </div>
    </div>
    <div class="w-full flex flex-row items-center py-1 justify-start sm:justify-between flex-wrap">
        <div class="w-full max-w-xs p-1">
            <div class="w-full flex flex-col">
                <input v-model="box.serialNumber" type="text" class="w-full" placeholder="serial #" tabindex="1" :disabled="!allowEdit"
                @focus="prevValue = box.serialNumber"
                @blur="prevValue !== box.serialNumber ? updateSerialorMac(prevValue,{'serialNumber':box.serialNumber}) : null"
                @keyup.enter="($event) => focusNextElement($event.target)"
                @keyup.tab.prevent="($event) => focusNextElement($event.target)"
                >
                <div class="w-full text-left px-2">serial #</div>
            </div>
        </div>
        <div class="w-full max-w-xs p-1">
            <div class="w-full flex flex-col">
                <input v-model="box.macAddress" type="text" class="w-full" placeholder="MAC addr." tabindex="2" :disabled="!allowEdit"
                @focus="prevValue = box.macAddress"
                @blur="prevValue !== box.macAddress ? updateSerialorMac(prevValue,{'macAddress':box.macAddress}) : null"
                @keyup.enter="($event) => focusNextElement($event.target)"
                @keyup.tab.prevent="($event) => focusNextElement($event.target)"
                >
                <div class="w-full text-left px-2">MAC address</div>
            </div>
        </div>
    </div>
    <div v-if="(box.serialNumbers && box.serialNumbers.length > 0) || (box.macAddresses && box.macAddresses.length > 0)" class="w-full flex flex-col flex-grow p-1 overflow-y-auto flex-auto h-0 border-t">
        <div class="w-full flex flex-row p-1 flex-grow overflow-y-auto flex-wrap sm:flex-nowrap">
            <div v-if="(box.serialNumbers && box.serialNumbers.length > 0)" class="w-full overflow-y-auto p-1 max-w-sm">
                <div class="w-full text-left p-1 whitespace-normal truncate border-b">Add'l Serial #</div>
                <div v-for="serial in box.serialNumbers" :key="serial._id" class="p-1 flex flex-col w-full">
                    <input v-model="serial.serialNumber" type="text" class="w-full" placeholder="serial #" tabindex="1" :disabled="!allowEdit || isWorking"
                    @focus="prevValue = serial.serialNumber"
                    @blur="prevValue !== serial.serialNumber ? updateOtherSerialOrMac(prevValue,'serialNumbers',serial._id, {'serialNumber':serial.serialNumber}) : null"
                    @keyup.enter="($event) => focusNextElement($event.target)"
                    @keyup.tab.prevent="($event) => focusNextElement($event.target)"
                    >
                    <div class="w-full text-left px-2 whitespace-normal">{{serial.name}}</div>
                </div>
            </div>
            <div v-if="(box.macAddresses && box.macAddresses.length > 0)" class="w-full overflow-y-auto p-1 max-w-sm">
                <div class="w-full text-left p-1 whitespace-normal truncate border-b">Add'l Mac</div>
                <div v-for="macAddress in box.macAddresses" :key="macAddress._id" class="p-1 flex flex-col w-full">
                    <input v-model="macAddress.macAddress" type="text" class="w-full" placeholder="MAC" tabindex="2" :disabled="!allowEdit"
                    @focus="prevValue = macAddress.macAddress"
                    @blur="prevValue !== macAddress.macAddress ? updateOtherSerialOrMac(prevValue,'macAddresses',macAddress._id, {'macAddress':macAddress.macAddress}) : null"
                    @keyup.enter="($event) => focusNextElement($event.target)"
                    @keyup.tab.prevent="($event) => focusNextElement($event.target)"
                    >
                    <div class="w-full text-left px-2 whitespace-normal">{{macAddress.name}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
export default {
    emits:["updateBox"],
    props:{
        equipmentId:{type:String,default:null},
        allowEdit:{type:Boolean,default:false},
        external:{type:Boolean,default:false},
        project:{type:Object,default:()=>{return {}}}
    },
    setup (props,{emit}) {
        const global = inject("global")
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const box = ref({})
        const showFullDescription = ref(false)

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getEquipment()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const getEquipment = async (type)=>{
            if(type !== 'silent') {
                setModalBlocked(true)
                isWorking.value = true
            }
            responseError.value = null
            const route = props.external ? `external/equipment/box/${props.project?._id}/${props.project?.secureCode}/${props.equipmentId}` : `equipment/box/${props.equipmentId}`

            await api
            .get(route)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object' && !Array.isArray(res.data.data)) {
                    box.value = res.data.data
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const updateSerialorMac = async (previousValue,body)=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            await api
            .put(`equipment/${props.equipmentId}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object' && !Array.isArray(res.data.data)) {
                    box.value = res.data.data
                    emit("updateBox",res.data.data)
                    sendChangeEvent('equipment','','',{projectId:res.data?.data?.projectId?._id,equipmentId:res.data?.data?._id})
                }
            })
            .catch((err)=>{
                box.value.serialNumber = previousValue || null
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const updateOtherSerialOrMac = async (previousValue,route,id,body)=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            await api
            .put(`equipment/${route}/${props.equipmentId}/${id}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object' && !Array.isArray(res.data.data)) {
                    box.value = res.data.data
                    emit("updateBox",res.data.data)
                    sendChangeEvent('equipment','','',{projectId:res.data?.data?.projectId?._id,equipmentId:res.data?.data?._id})
                }
            })
            .catch((err)=>{
                let itemToEdit = box.value[route].find(x=>x._id === id)
                if(itemToEdit) {
                    itemToEdit.serialNumber ? itemToEdit.serialNumber = previousValue : null
                    itemToEdit.macAddress ? itemToEdit.macAddress = previousValue : null
                }
                responseError.value = err.response?.data?.error || err.message
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const focusNextElement = (target)=>{
            const focusableElements = 'input'
            const currentElement = target
            const focusable = Array.from(document.querySelectorAll(focusableElements))
                    .sort((a, b) => { // Sort elements according to their tabindex. Elements without tabindex are treated as 0
            const tabIndexA = a.hasAttribute('tabindex') ? parseInt(a.getAttribute('tabindex'), 10) : 0;
            const tabIndexB = b.hasAttribute('tabindex') ? parseInt(b.getAttribute('tabindex'), 10) : 0;
            return tabIndexA - tabIndexB;
        })
            const currentElementIndex = focusable.indexOf(currentElement);
            const nextElementIndex = currentElementIndex + 1;

            if (nextElementIndex >= focusable.length) {
                focusable[0].focus(); // Optionally wrap around to the first element
            } else {
                focusable[nextElementIndex].focus();
            }
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                if(e.detail?.type && e.detail?.type === 'equipment') {
                    if(e.detail?.data?.equipmentId && e.detail?.data?.equipmentId === props.equipmentId) {
                        getEquipment('silent')
                    }
                }
            }
        }

        return {
            isWorking,
            responseError,
            box,
            showFullDescription,
            focusNextElement,
            updateSerialorMac,
            updateOtherSerialOrMac
        }
    }
}
</script>

<style>

</style>