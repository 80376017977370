<template>
    <div class="w-full flex flex-col flex-grow items-center space-y-1">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
        <!--<div class="w-full flex flex-col items-center space-y-1 border-b rounded-md py-1 max-w-4xl">
            <div class="w-full flex flex-col items-center space-y-0.5">
                <input v-model.trim="changeOrderNumber" type="search" class="w-full max-w-sm" placeholder="Change Order #" maxlength="50" >
                <div class=" w-full max-w-sm text-left px-1 whitespace-normal truncate opacity-70 py-0.5">
                    No change order # will modify initial contract.
                </div>
            </div>
        </div>-->
        <div v-if="newServices && newServices.length > 0" class="w-full flex flex-row items-center justify-between truncate flex-wrap px-2 panel">
            <div class="text-left p-1 whitespace-normal truncate font-semibold">Total Changes</div>
            <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
                <div class="px-3 whitespace-nowrap">{{`${servicesSummary.totalHours} ${servicesSummary.totalHours && servicesSummary.totalHours === 1 ? ' hour' : ' hours'}`}}</div>
                <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesSummary.totalRevenue)}}</div>
            </div>
        </div>
        <div v-if="newServices && newServices.length > 0" class="w-full p-1">
            <div class="w-full flex flex-row items-center justify-end px-1">
                <button class="bn-icon-small bn-solid-green" :disabled="isWorking" @click="saveServiceChanges">
                    <SaveIcon />
                </button>
            </div>
        </div>
        <div class="w-full flex flex-col items-center py-1 panel">
            <div class="p-1 w-full max-w-md">
                <searchApi :apiEndpoint="'search/partNumbers'" :placeholder="'add part # or description'" :displayResult="false" @resultSelected="addServices($event)"  />
            </div>
        </div>
<!-- Contract Changes -->
        <div v-if="newServices && newServices.length > 0" class="w-full flex flex-col flex-auto flex-grow h-0 overflow-y-auto items-center p-1">
            <div class="w-full flex flex-col flex-grow overflow-y-auto">
                <div v-if="contractSummary && Object.keys(contractSummary).length > 0" class="w-full flex flex-col">
                    <div v-for="(object,document) in contractSummary" :key="document" class="w-full flex flex-col p-1">
                        <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-300 dark:bg-indigo-600 sticky top-0">
                            <div class="text-left p-1 whitespace-normal truncate font-semibold">{{document}}</div>
                            <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
                                <div class="px-2 whitespace-nowrap">{{`${object.summary?.hours} ${object.summary?.hours && object.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                <div class="whitespace-nowrap truncate">{{showAsUSCurrency(object.summary?.revenue)}}</div>
                            </div>
                        </div>
                        <div v-if="object.categories && Object.keys(object.categories).length > 0" class="w-full p-1">
                            <div v-for="(categoryObject,category) in object.categories" :key="category" class="w-full p-1">
                                <div class="w-full flex flex-row items-center justify-between border rounded-md px-1 flex-wrap has-details font-semibold" @click="openDetails($event?.target)">
                                    <div class="text-left p-1 whitespace-normal truncate">{{category}}</div>
                                    <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                        <div class="px-2 whitespace-nowrap">{{`${categoryObject.summary?.hours} ${categoryObject.summary?.hours && categoryObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                        <div class="whitespace-nowrap truncate">{{showAsUSCurrency(categoryObject.summary?.revenue)}}</div>
                                    </div>
                                </div>
                                <div v-if="categoryObject.services && Object.keys(categoryObject.services).length > 0" class="w-full p-1 hidden">
                                    <div v-for="(servicesObject,partNumber) in categoryObject.services" :key="partNumber" class="w-full">
                                        <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-700 bg-opacity-20">
                                            <div class="text-left p-1 whitespace-normal truncate">{{partNumber}}</div>
                                            <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                                <div class="px-2 whitespace-nowrap">{{`${servicesObject.summary?.hours} ${servicesObject.summary?.hours && servicesObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                                <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesObject.summary?.revenue)}}</div>
                                            </div>
                                        </div>
                                        <div v-if="Array.isArray(servicesObject.items) && servicesObject.items.length > 0" class="w-full p-1">
                                            <div v-for="(item,index) in servicesObject.items" :key="index" class="w-full">
                                                <div class="w-full flex flex-row justify-between flex-wrap truncate">
                                                    <div class="w-full max-w-md p-1">
                                                        <input v-model="item.description" type="text" class="w-full" maxlength="100">
                                                    </div>
                                                    <div class="flex flex-row items-center space-x-2 truncate">
                                                        <div class="flex flex-row items-center space-x-2 truncate flex-wrap">
                                                            <div class="p-1 flex flex-row space-x-1 items-center">
                                                                <input v-model="item.hours" type="number" class="w-20 text-center" max="1000000">
                                                                <div>hours</div>
                                                            </div>
                                                            <div class="p-1 flex flex-row space-x-1 items-center truncate ">
                                                                <div>$</div>
                                                                <input v-model="item.revenue" type="number" class="text-right w-28 truncate" max="10000000000">
                                                            </div>
                                                        </div>
                                                        <div class="p-1">
                                                            <ButtonWithConfirm :popperContent="'delete item'" :iconComponent="TrashIcon" @confirmed="deleteServiceItem(item.localId)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!-- Change Orders -->
                <div v-if="changeSummary && Object.keys(changeSummary).length > 0" class="w-full flex flex-col">
                    <div v-for="(object,document) in changeSummary" :key="document" class="w-full flex flex-col p-1">
                        <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-300 dark:bg-indigo-600 sticky top-0">
                            <div class="text-left p-1 whitespace-normal truncate font-semibold">{{document}}</div>
                            <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
                                <div class="px-2 whitespace-nowrap">{{`${object.summary?.hours} ${object.summary?.hours && object.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                <div class="whitespace-nowrap truncate">{{showAsUSCurrency(object.summary?.revenue)}}</div>
                            </div>
                        </div>
                        <div v-if="object.categories && Object.keys(object.categories).length > 0" class="w-full p-1">
                            <div v-for="(categoryObject,category) in object.categories" :key="category" class="w-full p-1">
                                <div class="w-full flex flex-row items-center justify-between border rounded-md px-1 flex-wrap has-details font-semibold" @click="openDetails($event?.target)">
                                    <div class="text-left p-1 whitespace-normal truncate">{{category}}</div>
                                    <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                        <div class="px-2 whitespace-nowrap">{{`${categoryObject.summary?.hours} ${categoryObject.summary?.hours && categoryObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                        <div class="whitespace-nowrap truncate">{{showAsUSCurrency(categoryObject.summary?.revenue)}}</div>
                                    </div>
                                </div>
                                <div v-if="categoryObject.services && Object.keys(categoryObject.services).length > 0" class="w-full p-1 hidden">
                                    <div v-for="(servicesObject,partNumber) in categoryObject.services" :key="partNumber" class="w-full">
                                        <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-700 bg-opacity-20">
                                            <div class="text-left p-1 whitespace-normal truncate">{{partNumber}}</div>
                                            <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                                <div class="px-2 whitespace-nowrap">{{`${servicesObject.summary?.hours} ${servicesObject.summary?.hours && servicesObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                                <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesObject.summary?.revenue)}}</div>
                                            </div>
                                        </div>
                                        <div v-if="Array.isArray(servicesObject.items) && servicesObject.items.length > 0" class="w-full p-1">
                                            <div v-for="(item,index) in servicesObject.items" :key="index" class="w-full">
                                                <div class="w-full flex flex-row justify-between flex-wrap truncate">
                                                    <div class="w-full max-w-xs p-1">
                                                        <input v-model="item.description" type="text" class="w-full" maxlength="100">
                                                    </div>
                                                    <div class="flex flex-row items-center space-x-2 truncate">
                                                        <div class="flex flex-row items-center space-x-2 truncate flex-wrap">
                                                            <div class="p-1 flex flex-row space-x-1 items-center">
                                                                <input v-model="item.hours" type="number" class="w-20 text-center" max="1000000">
                                                                <div>hours</div>
                                                            </div>
                                                            <div class="p-1 flex flex-row space-x-1 items-center truncate ">
                                                                <div>$</div>
                                                                <input v-model="item.revenue" type="number" class="text-right w-28 truncate" max="10000000000">
                                                            </div>
                                                        </div>
                                                        <div class="p-1">
                                                            <ButtonWithConfirm :popperContent="'delete item'" :iconComponent="TrashIcon" @confirmed="deleteServiceItem(item.localId)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {groupServices,showAsUSCurrency,openDetails} from '@/shared'
import searchApi from '@/components/ui/searchApi.vue'
import { v4 as uuidv4 } from 'uuid'
import { computed, inject, ref } from 'vue'
import {TrashIcon,SaveIcon} from '@heroicons/vue/outline'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
import api from '@/api'
export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
    },
    emits:["triggerProjectUpdate"],
    components:{
        searchApi,
        ButtonWithConfirm,
        SaveIcon
    },
    setup (props,{emit}) {
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const changeOrderNumber = ref(null)
        const newServices = ref([])

        const contractSummary = computed(()=>{
            return groupServices(newServices.value)
        })

        const changeSummary = computed(()=>{
            return groupServices(newServices.value,false)
        })

        const servicesSummary = computed(()=>{
            let totalHours = 0
            let totalRevenue = 0

            for (let item of newServices.value) {
                if(typeof item.hours === 'number') {
                    totalHours += item.hours
                }
                if (typeof item.revenue === 'number') {
                    totalRevenue += item.revenue;
                }
            }

            return {
                totalHours,
                totalRevenue
            }

        })

        const addServices = (event)=>{
            let localId = uuidv4()
            if(typeof event === 'object' && !Array.isArray(event)) {
                let objectToAdd = {
                    localId:localId,
                    document:changeOrderNumber.value || 'contract',
                    category:event.categoryId?.category || 'Undefined',
                    displayOrder:event.categoryId?.displayOrder || 1,
                    description:event.description,
                    partNumber:event.partNumber,
                    hours:0,
                    revenue:0
                }
                newServices.value.push(objectToAdd)
            }
        }

        const deleteServiceItem = (id)=>{
            let index = newServices.value.findIndex(x=>x.localId === id)
            if(index > -1) {
                newServices.value.splice(index,1)
            }
        }

        const saveServiceChanges = async ()=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            await api
            .put(`projects/changeOrder/${props.project._id}`,newServices.value)
            .then(()=>{
                newServices.value = []
                emit("triggerProjectUpdate")
                sendChangeEvent('project',props.project._id,'',{projectId:props.project._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        return {
            isWorking,
            responseError,
            newServices,
            addServices,
            changeOrderNumber,
            contractSummary,
            changeSummary,
            showAsUSCurrency,
            openDetails,
            deleteServiceItem,
            TrashIcon,
            servicesSummary,
            saveServiceChanges
        }
    }
}
</script>

<style>

</style>