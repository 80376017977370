<template>
  <div class="w-full flex flex-col flex-grow rounded-md p-1 overflow-y-auto">
    <div class="w-full flex flex-col flex-grow p-1 overflow-y-auto">
        <div class="w-full flex-grow flex flex-row space-x-2 justify-between overflow-y-auto">
            <div class="w-full max-w-sm min-w-52 border rounded-md overflow-y-auto p-1">
                <div v-for="tool in shipment.toolsToReturn" :key="tool._id" class="w-full p-1">
                    <div class="w-full flex flex-row items-center truncate border rounded-md">
                        <div class="flex-grow truncate px-1">
                            <div v-if="tool.toolCatalogId?.toolName" class="w-full text-left whitespace-normal truncate line-clamp-2 p-1 border-b text-sky">{{tool.toolCatalogId?.toolName}}</div>
                            <div class="w-full text-left whitespace-normal line-clamp-2 p-1">{{tool.name}}</div>
                        </div>
                        <div class="w-auto p-1">
                            <button class="bn-icon-only" @click="removeToolReturnFromShipment(tool._id)" :disabled="!allowEdit || isModalBlocked">
                                <XIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="allowEdit && Array.isArray(toolsAvailable) && toolsAvailable.length > 0" class="w-full max-w-sm min-w-52 border rounded-md overflow-y-auto p-1">
                <WorkingAndError :isWorking="isWorking" :error="responseError" />
                <div v-for="tool in toolsAvailable" :key="tool._id" class="w-full p-1">
                    <div class="w-full flex flex-row items-center truncate border rounded-md">
                        <div class="w-auto p-1">
                            <button class="bn-icon-only" @click="addToolReturnToShipment(tool._id)" :disabled="!allowEdit || isModalBlocked">
                                <ArrowSmLeftIcon />
                            </button>
                        </div>
                        <div class="flex-grow truncate px-1">
                            <div v-if="tool.toolCatalogId?.toolName" class="w-full text-left whitespace-normal truncate line-clamp-2 p-1 border-b text-sky">{{tool.toolCatalogId?.toolName}}</div>
                            <div class="w-full text-left whitespace-normal line-clamp-2 p-1">{{tool.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {XIcon,ArrowSmLeftIcon} from '@heroicons/vue/outline'
import { inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
export default {
    props:{
        shipment:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false}
    },
    components:{XIcon,ArrowSmLeftIcon},
    emits:["update"],
    setup(props,{emit}) {

        const global = inject('global')
        const {sendChangeEvent,setModalBlocked,isModalBlocked} = global

        const isWorking = ref(false)
        const responseError = ref(null)
        const toolsAvailable = ref([])

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getToolReturns()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const getToolReturns = async (type)=>{
            type !== 'silent' ? isWorking.value = true : null
            responseError.value = null
            let body = {projectId:props.shipment?.projectId?._id}
            await api
            .post(`shipments/toolReturns`,body)
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? toolsAvailable.value = res.data.data
                :null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                console.error(err.response?.data?.error || err.message)
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const addToolReturnToShipment = async (id)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .put(`shipments/toolReturn/add/${props.shipment._id}/${id}`,{})
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("update",res.data.data)
                }
                if(res.data?.data?.tool && typeof res.data.data.tool === 'object') {
                    updateTool(res.data.data.tool)
                    sendChangeEvent('tool','','',{toolId:res.data.data.tool._id})
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const removeToolReturnFromShipment = async (id)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .put(`shipments/toolReturn/remove/${props.shipment._id}/${id}`,{})
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("update",res.data.data)
                }
                if(res.data?.data?.tool && typeof res.data.data.tool === 'object') {
                    updateTool(res.data.data.tool)
                    sendChangeEvent('tool','','',{toolId:res.data.data.tool._id})
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const updateTool = (tool)=>{
            if(tool.returnDate && tool.shipmentId && !tool.returnShipmentId) {
                if(!props.shipment?.projectId?._id || (props.shipment?.projectId?._id === tool.shipmentId?.projectId?._id)) {
                    let toolIndex = toolsAvailable.value.findIndex(x=>x._id === tool._id)
                    if(toolIndex > -1) {
                        toolsAvailable.value[toolIndex] = tool
                    } else {
                        toolsAvailable.value.push(tool)
                    }
                }
            } else {
                let toolIndex = toolsAvailable.value.findIndex(x=>x._id === tool._id)
                if(toolIndex > -1) {
                    toolsAvailable.value.splice(toolIndex,1)
                }
            }
        }

        const toolSocketUpdate = async (id)=>{
            await api
            .get(`tools/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    updateTool(res.data.data)
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type && e.detail?.type === 'tool') {
                if(e.detail?.data?.toolId) {
                    toolSocketUpdate(e.detail.data.toolId)
                }
            }
        }

        return {
            isWorking,
            responseError,
            isModalBlocked,
            toolsAvailable,
            addToolReturnToShipment,
            removeToolReturnFromShipment
        }
    }

}
</script>

<style>

</style>