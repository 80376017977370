<template>
    <div v-if="equipment && equipment.length > 0" class="w-full flex flex-row items-center justify-center space-x-1 flex-nowrap p-1">
        <button class="bn-icon-only" @click="expandAll = !expandAll">
            <ArrowsExpandIcon />
        </button>
        <input v-model="equipmentFilter" type="search" class="w-full max-w-sm" placeholder="filter">
    </div>
  <div class="w-full flex flex-col flex-grow flex-auto h-0 space-y-1 truncate max-w-xl p-1 overflow-y-auto">
    <div class="w-full flex flex-col flex-grow overflow-y-auto space-y-2">
<!-- Equipment -->
        <div v-if="equipmentByManufacturer && equipmentByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
            <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Equipment</div>
            <div v-for="(category,index) in equipmentByManufacturer " :key="index" class="w-full px-1">
                <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                    <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                    <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                </div>
                <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                    <div v-for="(box,index) in category.items" :key="index" class="w-full flex flex-row items-start space-x-1 p-1 truncate">
                        <BoxReduced v-if="box.multiBox"
                        :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                        :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                        >
                            <template v-if="showBoxActions && availableRacks && availableRacks.length > 0" v-slot:actions>
                                <div class="w-full flex items-center p-0.5 max-w-xs">
                                    <select class="w-full" @change="moveToRack(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id,$event.target?.value);$nextTick(() => $event.target.value = '')" :disabled="isModalBlocked">
                                        <option value="" hidden>+ to Rack</option>
                                        <option v-for="rack in availableRacks" :key="rack._id" :value="rack._id">{{rack.name}}</option>
                                    </select>
                                </div>
                            </template>
                        </BoxReduced>
                        <BoxReduced v-else :box="box">
                            <template v-if="showBoxActions && availableRacks && availableRacks.length > 0" v-slot:actions>
                                <div class="w-full flex items-center p-0.5 max-w-xs">
                                    <select v-model="selectedRack" class="w-full" @change="moveToRack(box._id,$event.target?.value);$nextTick(() => selectedRack = null)" :disabled="isModalBlocked">
                                        <option :value="null" hidden>+ to Rack</option>
                                        <option v-for="rack in availableRacks" :key="rack._id" :value="rack._id">{{rack.name}}</option>
                                    </select>
                                </div>
                            </template>
                        </BoxReduced>
                        <div v-if="showBoxActions && box.multiBox" class="flex flex-row items-center justify-center space-x-2 py-2 px-0.5">
                            <div v-if="box.multiBox && box.items && box.items.length > 0 ? box.items[0]?.rackId : box.rackId">
                                <Popper hover arrow placement="top" :content="'Back to room'">
                                    <button class="bn-icon-small bn-solid-sky"
                                    @click="moveToRoom(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id)"
                                    :disabled="isModalBlocked"
                                    >
                                        <MdDoorFrontIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div>
                                <button class="bn-icon-small bn-solid-red"
                                @click="removeItem(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                        <div v-else-if="showBoxActions" class="flex flex-row items-center justify-center space-x-2 py-2 px-0.5">
                            <div v-if="box.rackId">
                                <Popper hover arrow placement="top" :content="'Back to room'">
                                    <button class="bn-icon-small bn-solid-sky"
                                    @click="moveToRoom(box._id)"
                                    :disabled="isModalBlocked"
                                    >
                                        <MdDoorFrontIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div>
                                <button class="bn-icon-small bn-solid-red"
                                @click="removeItem(box._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- Kits -->
        <div v-if="kitsByManufacturer && kitsByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
            <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Kits</div>
            <div v-for="(category,index) in kitsByManufacturer" :key="index" class="w-full px-1">
                <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                    <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                    <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                </div>
                <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                    <div v-for="(box,index) in category.items" :key="index" class="w-full p-1">
                        <div class="w-full flex flex-row items-start space-x-2">
                            <BoxReduced :box="box">
                                <template v-if="showBoxActions && availableRacks && availableRacks.length > 0" v-slot:actions>
                                    <div class="w-full flex items-center p-0.5 max-w-xs">
                                        <select v-model="selectedRack" class="w-full" @change="moveToRack(box._id,$event.target?.value);$nextTick(() => selectedRack = null)" :disabled="isModalBlocked">
                                            <option :value="null" hidden>+ to Rack</option>
                                            <option v-for="rack in availableRacks" :key="rack._id" :value="rack._id">{{rack.name}}</option>
                                        </select>
                                    </div>
                                </template>
                                <template v-slot:kitBoxes>
                                    <div v-for="kitBox in box.items" :key="kitBox._id" class="w-full pl-1">
                                        <BoxReduced :box="kitBox" />
                                    </div>
                                </template>
                            </BoxReduced>
                            <div class="flex flex-col space-y-2 items-center py-2">
                                <div v-if="showBoxActions && box.rackId">
                                    <Popper hover arrow placement="top" :content="'Back to room'">
                                        <button class="bn-icon-small bn-solid-sky"
                                        @click="moveToRoom(box._id)"
                                        :disabled="isModalBlocked"
                                        >
                                            <MdDoorFrontIcon />
                                        </button>
                                    </Popper>
                                </div>
                                <button v-if="showBoxActions && box.isKit" class="bn-icon-small bn-solid-red"
                                @click="removeItem(box._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- Accessories -->
        <!--<div v-if="accessoriesByManufacturer && accessoriesByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
            <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Accessories</div>
            <div v-for="(category,index) in accessoriesByManufacturer" :key="index" class="w-full px-1">
                <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                    <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                    <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                </div>
                <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                    <div v-for="(box,index) in category.items" :key="index" class="w-full flex flex-row items-start space-x-1 p-1 truncate">
                        <BoxReduced 
                        :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                        :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                        >
                            <template v-if="showBoxActions && availableRacks && availableRacks.length > 0" v-slot:actions>
                                <div class="w-full flex items-center p-0.5 max-w-xs">
                                    <select class="w-full" @change="moveToRack(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id,$event.target?.value);$nextTick(() => $event.target.value = '')" :disabled="isModalBlocked">
                                        <option value="" hidden>+ to Rack</option>
                                        <option v-for="rack in availableRacks" :key="rack._id" :value="rack._id">{{rack.name}}</option>
                                    </select>
                                </div>
                            </template>
                        </BoxReduced>
                        <div v-if="showBoxActions" class="flex flex-row items-center justify-center space-x-2 py-2 px-0.5">
                            <div v-if="box.multiBox && box.items && box.items.length > 0 ? box.items[0]?.rackId : box.rackId">
                                <Popper hover arrow placement="top" :content="'Back to room'">
                                    <button class="bn-icon-small bn-solid-sky"
                                    @click="moveToRoom(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id)"
                                    :disabled="isModalBlocked"
                                    >
                                        <MdDoorFrontIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div>
                                <button class="bn-icon-small bn-solid-red"
                                @click="removeItem(box.multiBox && box.items && box.items.length > 0 ? box.items[0]._id : box._id)"
                                :disabled="isModalBlocked"
                                >
                                    <XIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
  </div>
</template>

<script>
import {openDetails,getItemsByManufacturer} from '@/shared'
import {XIcon,ArrowsExpandIcon} from "@heroicons/vue/outline"
import BoxReduced from '@/components/cards/BoxReduced.vue'
import { computed, inject,ref } from 'vue'
import MdDoorFrontIcon from '@/components/customIcons/MdDoorFrontIcon.vue'
export default {
    props:{
        equipment:{type:Array,default:()=>{return []}},
        showBoxActions:{type:Boolean,default:false},
        racks:{type:Array,default:()=>{return []}}
    },
    components:{BoxReduced,XIcon,MdDoorFrontIcon,ArrowsExpandIcon},
    emits:["removeItem","moveToRack","moveToRoom"],
    setup (props,{emit}) {

        const global = inject("global")
        const {isModalBlocked} = global
        const equipmentFilter = ref(null)
        const expandAll = ref(false)
        const selectedRack = ref(null)

        const filteredEquipment = computed(()=>{
            let filtered = props.equipment
            if(equipmentFilter.value) {
                let filter = equipmentFilter.value.toLowerCase()
                filtered = filtered.filter(item=>
                    (
                        (item.manufacturer && item.manufacturer.toLowerCase().includes(filter)) ||
                        (item.partNumber && item.partNumber.toLowerCase().includes(filter)) ||
                        (item.deviceType && item.deviceType.toLowerCase().includes(filter)) ||
                        (item.description && item.description.toLowerCase().includes(filter))
                    )
                )
            }
            // Collect kitIds from filtered items
            const kitIds = new Set(filtered.map(item => item.kitId).filter(kitId => kitId));

            // Include all items with a kitId that matches any kitId in the filtered results
            const withKitIds = props.equipment.filter(item => kitIds.has(item.kitId));

            // Combine filtered items with those that have matching kitIds, avoiding duplicates
            const combined = [...filtered, ...withKitIds.filter(item => !filtered.includes(item))];

            return combined;
        })

        const availableRacks = computed(()=>{
            return props.racks && props.racks.length > 0
            ? props.racks.filter(r=>r.rackState < 2)
            : []
        })

        const equipmentByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'equipment')
        });

        const kitsByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'kits')
        });

        //const accessoriesByManufacturer = computed(() => {
        //    return getItemsByManufacturer(filteredEquipment.value,'accessories')
        //});

        const removeItem = (id)=>{
            emit("removeItem",id)
        }

        const moveToRack = (id,rackId)=>{
            emit("moveToRack",{id,rackId})
        }

        const moveToRoom = (id)=>{
            emit("moveToRoom",id)
        }

        return {
            isModalBlocked,
            equipmentFilter,
            expandAll,
            XIcon,
            equipmentByManufacturer,
            kitsByManufacturer,
            //accessoriesByManufacturer,
            openDetails,
            removeItem,
            moveToRack,
            moveToRoom,
            availableRacks,
            selectedRack
        }
    }

}
</script>

<style>

</style>