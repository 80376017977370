<template>
    <QueueDisplay :total="count">
        <template v-slot:icon>
            <WarehouseIcon class="h-5 w-5" />
        </template>
    </QueueDisplay>
</template>

<script>
import {ref,onMounted,onUnmounted} from 'vue'
import api from "@/api"
import WarehouseIcon from "../customIcons/WarehouseIcon.vue"
import QueueDisplay from "@/components/ui/QueueDisplay.vue"

export default {
    components:{QueueDisplay,WarehouseIcon},
    setup () {
        const count = ref(0)

        onMounted (()=>{
            getInitialCount()
            window.addEventListener('socket_connected', handleReconnect);
            window.addEventListener("warehouse_queue", handleWebSocketData);
        })

        onUnmounted (()=>{
            window.removeEventListener("socket_connected", handleReconnect);
            window.removeEventListener("warehouse_queue",handleWebSocketData)
        })

        const handleWebSocketData = (e)=>{
            Number(e.detail?.data)
            ? count.value = e.detail.data
            : null
        }

        const joinRoom = ()=>{
            let eventJoin = new CustomEvent("requestRoom", {detail: { route: "warehouse", room: "warehouse_queue" }});
            dispatchEvent(eventJoin);
        }

        const handleReconnect = ()=>{
            joinRoom()
        }

        const getInitialCount = async ()=>{
            await api
            .get("shipments/openCount")
            .then((res)=>{
                res.data?.data && Number(res.data.data) ? count.value = Number(res.data.data) : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        return {count}
    }
//    data:()=>{
//        return {
//            requestedShipments:[],
//            getShipmentError:false,
//        }
//    },
//    mounted:function() {
//        this.getShipmentRequests()
//
//        let eventJoin = new CustomEvent("requestRoom", {detail: { route: "warehouse", room: "warehouse_queue" }});
//        dispatchEvent(eventJoin);
//
//        window.addEventListener("new_data:warehouse_queue", this.handleWebSocketData);
//    },
//    unmounted:function() {
//        window.removeEventListener("new_data:warehouse_queue",() => {})
//    },
//    methods:{
//        getShipmentRequests: async function() {
//            this.getShipmentError = false
//            await api
//            .get("shipments/requests/openRequests")
//            .then((res)=>{
//                this.requestedShipments = res.data.data
//            })
//            .catch((err)=>{
//                console.error(err)
//                this.getShipmentError = true
//            })
//        },
//        handleWebSocketData: function(e) {
//            console.log('Received event in warhouse queue')
//            console.log(e)
//            let itemIndex = this.requestedShipments.findIndex((x)=>x._id == e.detail.data._id)
//            if(itemIndex >=0) {
//                if(e.detail.data.shipmentStatus !== 'requested' && e.detail.data.shipmentStatus !== 'inprocess' && e.detail.data.shipmentStatus !== 'ready') {
//                    this.requestedShipments.splice(itemIndex,1)
//                } else {
//                    this.requestedShipments[itemIndex] = e.detail.data
//                }
//            } else {
//                if(e.detail.data.shipmentStatus !== 'created' && e.detail.data.shipmentStatus !== 'out') {
//                    this.requestedShipments.push(e.detail.data)
//                }
//            }
//        }
//    }

}
</script>

<style>

</style>