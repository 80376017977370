<template>
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div v-if="Object.keys(shipment).length > 0" class="w-full flex flex-col flex-grow p-1 truncate overflow-y-auto">
<!-- Shipment Information -->
        <div class="w-full flex flex-col p-1">
            <div class="w-full flex flex-col px-2 py-1 rounded-md border space-y-1">
                <div class="w-full flex flex-row items-center flex-wrap justify-between">
                    <div class="flex-grow flex items-center flex-wrap">
                        <div class="w-44 p-0.5">
                            <input v-model="shipment.shipmentDate" type="datetime-local" class="w-full" :disabled="shipment.method !== 'integrator' && (isWorking || !allowEdit)"
                            @click="prevValue = formatISODateforDateTimeInput(shipment.shipmentDate)" @blur="prevValue !== $event.target?.value ? updateDate(shipment._id,'shipmentDate',$event.target.value,prevValue) : null"
                            >
                        </div>
                        <div class="py-0.5 px-2"  :class="[{'hover:opacity-70 hover:cursor-pointer':allowEdit},shipment.scheduleType === 'firm' ? 'text-red-600' : 'text-green-600']"
                        @click="allowEdit ? updateShipment(shipment._id,'scheduleType',shipment.scheduleType === 'firm' ? 'flexible':'firm',shipment.scheduleType) : null"
                        >
                            {{shipment.scheduleType}}
                        </div>
                    </div>
                    <div class="w-auto flex flex-row flex-nowrap p-0.5 items-center">
                        <div class="w-auto flex flex-row flex-nowrap items-center">
                            <div class="px-1">
                                <Popper hover arrow placement="top" :content="'ship'">
                                    <button class="bn-icon-small bn-solid-green" @click="moveShipmentForward()" :disabled="isWorking || !canShip || shipment.shipmentState > 0">
                                        <TruckIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div class="px-1">
                                <Popper hover arrow placement="top" :content="showInstructions ? 'hide instructions' : 'show instructions'">
                                    <button class="bn-icon-small bn-solid" @click="showInstructions = !showInstructions">
                                        <XIcon v-if="showInstructions" />
                                        <DocumentTextIcon v-else />
                                    </button>
                                </Popper>
                            </div>
                            <div v-if="!['integrator','tool'].includes(shipment?.method)" class="px-1">
                                <Popper hover arrow placement="top" :content="showToolsDialog ? 'equipment' : 'tools'">
                                    <button class="bn-icon-small bn-solid" @click="openToolsAndConsumables()" :disabled="isWorking || !allowEdit">
                                        <BoxIcon v-if="showToolsDialog" />
                                        <ToolboxFillIcon v-else />
                                    </button>
                                </Popper>
                            </div>
                            <div v-if="!['integrator','tool'].includes(shipment?.method)" class="px-1">
                                <Popper hover arrow placement="top" :content="showToolsDialog ? '' : 'consumables'">
                                    <button class="bn-icon-small bn-solid" @click="openToolsAndConsumables(true)" :disabled="isWorking || !allowEdit || (showConsumables || showToolsDialog)">
                                        <BanIcon v-if="showToolsDialog" />
                                        <ShoppingBagIcon v-else />
                                    </button>
                                </Popper>
                            </div>
                        </div>
                        <div class="w-auto p-0.5 flex flex-row items-center px-1">
                            <DeleteConfirm :size="'small'" :disableButton="shipment.method !== 'integrator' && (!canDelete || !allowEdit)" @confirmedDelete="deleteShipment()" />
                        </div>
                    </div>
                </div>
                <div v-if="shipment.method === 'courier'" class="w-full flex items-center py-1 border-t">
                    <div class="w-full max-w-xs whitespace-normal truncate p-0.5">
                        <input v-model="shipment.courierTrackingNumber" type="text" placeholder="tracking #" class="w-full" :disabled="isWorking || !allowEdit"
                        @focus="prevValue = shipment.courierTrackingNumber" @blur="prevValue !== shipment.courierTrackingNumber ? updateShipment(shipment._id,'courierTrackingNumber',shipment.courierTrackingNumber,prevValue) : null"
                        >
                    </div>
                </div>
                <div v-if="shipment.method === 'integrator' || showInstructions" class="w-full flex items-center py-1 border-t">
                    <textarea v-model="shipment.shipmentInstructions" v-auto-resize placeholder="instructions" rows="2" maxlength="500" class="w-full max-w-xl p-1 overflow-y-auto resize-none" :disabled="shipment.method !== 'integrator' && (isWorking || !allowEdit)"
                    @focus="prevValue = shipment.shipmentInstructions" @blur="prevValue !== shipment.shipmentInstructions ? updateShipment(shipment._id,'shipmentInstructions',shipment.shipmentInstructions,prevValue) : null"
                    />
                </div>
            </div>
        </div>
<!-- Filter -->
        <div v-if="allowEdit && shipment.method !== 'tool'" class="w-full flex flex-col items-center p-1">
            <div class="w-full flex flex-row items-center truncate p-1 panel justify-center max-w-4xl">
                <div class="w-auto flex flex-row flex-wrap truncate">
                    <div class="w-52 md:w-60 xl:w-64 p-1">
                        <input v-model="equipmentFilter" type="search" placeholder="filter" class="w-full">
                    </div>
                    <div class="w-52 md:w-60 xl:w-64 p-1">
                        <select v-model="roomFilter" class="w-full">
                            <option :value="null">Any Room</option>
                            <option v-for="room in roomOptions" :key="room._id" :value="room._id">{{room.name}}</option>
                        </select>
                    </div>
                    <div class="w-52 md:w-56 xl:w-64 p-1">
                        <select v-model="floorFilter" class="w-full">
                            <option :value="null">Any floor</option>
                            <option v-for="floor in floorOptions" :key="floor" :value="floor">{{floor}}</option>
                        </select>
                    </div>
                    <div class="w-28 p-1">
                        <select v-model="phaseFilter" class="w-full">
                            <option :value="null">Any phase</option>
                            <option v-for="phase in phaseOptions" :key="phase" :value="phase">{{phase}}</option>
                        </select>
                    </div>
                </div>
                <div class="p-1 flex items-center">
                    <button class="bn-icon-only" @click="clearFilter()">
                        <XIcon/>
                    </button>
                </div>
            </div>
        </div>
<!-- Equipment and Contents -->
        <div class="w-full flex flex-col flex-auto h-0 flex-grow space-y-1 overflow-auto p-1">
            <div v-if="shipment.method !== 'tool'" class="w-full flex flex-col flex-grow space-y-1 p-1 overflow-y-auto" :class="{'border-t':shipment.method !== 'integrator'}">
                <div class="w-full flex-grow flex flex-row overflow-y-auto flex-auto h-0">
                    <div v-show="!showToolsDialog && allowEdit" class="flex flex-col flex-auto min-h-full w-52 sm:w-72 md:w-80 lg:w-96 flex-shrink-0 flex-grow-0 overflow-y-auto p-1 space-y-2 border-r-2">
    <!-- Top Equipment Menu -->
                        <div class="w-full flex items-center justify-between flex-shrink-0 truncate p-1 panel">
                            <div class="flex items-center">
                                <Popper hover arrow placement="top" :content="expandAll ? 'collapse all' : 'expand all'">
                                    <button class="bn-icon-only" @click="expandAll = !expandAll"
                                    :disabled="isWorking"
                                    >
                                        <ArrowsExpandIcon />
                                    </button>
                                </Popper>
                            </div>
                            <button v-if="allowEdit" class="px-2 bn-solid" @click="moveAllToShipment()"
                            :disabled="isWorking"
                            >
                                <div>All</div>
                                <ArrowSmRightIcon />
                            </button>
                        </div>
    <!-- Equipment -->
                        <div class="w-full flex flex-col space-y-1 flex-grow overflow-y-auto">
                            <div v-if="equipmentByManufacturer && equipmentByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Equipment</div>
                                <div v-for="(category,index) in equipmentByManufacturer " :key="index" class="w-full px-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                                        <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                                    </div>
                                    <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                                        <div v-for="(box,index) in category.items" :key="index" class="w-full p-1 truncate">
                                            <BoxReduced v-if="box.multiBox"
                                            :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                                            :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                                            >
                                                <template v-slot:actions>
                                                    <div class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                        <div v-if="Array.isArray(box.items) && box.items.length > 1" class="flex flex-grow items-center justify-start px-1 space-x-1">
                                                            <input type="number" min="2" :max="box.items.length" maxlength="4" :value="box.items.length"  class="w-10 text-center"
                                                            @input="$event?.target ? $event.target.value = formatNumberInput($event.target?.value,0,box.items.length) : null"
                                                            :disabled="isWorking"
                                                            >
                                                            <button class="bn-icon-small bn-solid-sky" @click="($event)=>moveMultipeFromMulti($event.currentTarget.previousElementSibling,box.items)"
                                                            :disabled="isWorking"
                                                            >
                                                                <ChevronDoubleRightIcon />
                                                            </button>
                                                        </div>
                                                        <button class="bn-icon-small bn-solid-sky" 
                                                        @click="moveToShipment(box.items && box.items[0] ? box.items[0]._id : box._id)" 
                                                        :disabled="isWorking"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                            <BoxReduced v-else :box="box" >
                                                <template v-slot:actions>
                                                    <div class="w-full flex flex-row truncate p-1 items-center justify-end border-t">
                                                        <button class="bn-icon-small bn-solid-sky self-end" @click="moveToShipment(box._id)" :disabled="isWorking">
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                        </div>
                                    </div>
                                </div>
                            </div>
    <!-- Kits -->
                            <div v-if="kitsByManufacturer && kitsByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Kits</div>
                                <div v-for="(category,index) in kitsByManufacturer" :key="index" class="w-full px-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                                        <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                                    </div>
                                    <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                                        <div v-for="(box,index) in category.items" :key="index" class="w-full p-1">
                                            <BoxReduced :box="box">
                                                <template v-slot:actions>
                                                    <div class="w-full flex flex-row truncate p-1 items-center justify-end border-t">
                                                        <button class="bn-icon-small bn-solid-sky self-end" @click="moveToShipment(box._id)" :disabled="isWorking">
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                </template>
                                                <template v-slot:kitBoxes>
                                                    <div v-for="kitBox in box.items" :key="kitBox._id" class="w-full pl-1">
                                                        <BoxReduced :box="kitBox">
                                                            <template v-slot:actions>
                                                                <div class="w-full flex items-center justify-end border-t py-1">
                                                                    <DeleteConfirm :size="'small'" :iconComponent="XIcon" :popperContent="'remove from kit'" @confirmedDelete="removeFromKit(kitBox._id)"
                                                                    :disableButton="isWorking"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </BoxReduced>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                        </div>
                                    </div>
                                </div>
                            </div>
    <!-- Racks -->
                            <div v-if="filteredRacks && filteredRacks.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 flex flex-row space-x-2 flex-nowrap truncate panel sticky top-0 z-10 has-details" @click="openDetails($event.target)">
                                    <div class="w-auto whitespace-normal truncate">Racks</div>
                                    <div v-if="filteredRacks && filteredRacks.length > 0" class="w-auto">{{filteredRacks.length}}</div>
                                </div>
                                <div class="w-full p-1" :class="{'hidden' :!expandAll}">
                                    <div v-for="rack in filteredRacks" :key="rack._id" class="w-full py-1 px-2">
                                        <RackReduced :rack="rack">
                                            <template v-slot:actions>
                                                <div class="w-full flex flex-row truncate p-1 items-center justify-end border-t">
                                                    <button class="bn-icon-small bn-solid-sky self-end" @click="moveRackToShipment(rack._id)">
                                                        <ArrowSmRightIcon />
                                                    </button>
                                                </div>
                                            </template>
                                        </RackReduced>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    <!-- Tools -->
                    <div v-show="showToolsDialog" class="flex flex-col flex-auto min-h-full w-60 sm:w-72 md:w-80 lg:w-96 flex-shrink-0 flex-grow-0 overflow-y-auto p-1 space-y-2 border-r-2">
                        <div class="w-full flex items-center flex-shrink-0 truncate p-1 border-b">
                            <div v-if="!showConsumables" class="flex items-center flex-wrap">
                                <div class="p-1">
                                    <input v-model="toolReturnDate" type="date">
                                </div>
                                <div class="text-left opacity-75 px-2 sm:px-1">return date*</div>
                            </div>
                            <div v-else class="w-auto text-left">
                                Consumables
                            </div>
                            <div class="flex justify-end flex-grow p-1">
                                <Popper hover arrow placement="top" :content="showConsumables ? 'Tools' : 'Consumables'">
                                    <button class="bn-icon-small bn-solid-sky" @click="showConsumables = !showConsumables">
                                        <ToolboxFillIcon v-if="showConsumables" />
                                        <ArrowSmRightIcon v-else />
                                    </button>
                                </Popper>
                            </div>
                        </div>
                        <div v-show="!showConsumables" class="w-full flex flex-col space-y-1 flex-grow overflow-y-auto p-1">
                            <div v-if="toolsGrouped && Object.keys(toolsGrouped).length > 0" class="w-full flex flex-col space-y-1 overflow-y-auto">
                                <div v-for="category in Object.keys(toolsGrouped)" :key="category" class="w-full flex flex-col space-y-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.charAt(0).toUpperCase() + category.slice(1)}}</div>
                                        <div v-if="toolsGrouped[category] && toolsGrouped[category].length > 0" class="w-auto">{{toolsGrouped[category].length}}</div>
                                    </div>
                                    <div v-if="toolsGrouped[category] && toolsGrouped[category].length > 0" class="w-full whitespace-normal truncate p-1 hidden" >
                                        <div v-for="item in toolsGrouped[category]" :key="item._id" class="w-full p-1 truncate">
                                            <div class="w-full flex flex-row items-center justify-between border rounded-md">
                                                <div class="w-auto p-1 whitespace-normal truncate">{{item.toolName}}</div>
                                                <button class="bn-icon-only" 
                                                @click="addToolToShipment(item)"
                                                :disabled="isWorking || !canAddTool">
                                                    <ArrowSmRightIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="showConsumables" class="w-full flex flex-col flex-grow">
                            <div class="w-full flex items-center truncate p-1 border-b">
                                <div class="p-1">
                                    <Popper hover arrow placement="top" content="Add Drawings">
                                        <button class="bn-icon-small bn-solid-sky" @click="addDrawings()">
                                            <BlueprintIcon />
                                        </button>
                                    </Popper>
                                </div>
                            </div>
                            <div class="w-full flex flex-row items-center space-x-1 p-1 justify-between truncate overflow-y-auto">
                                <textarea v-model.trim="consumable" v-auto-resize class="w-full max-w-xs resize-none min-h-12" cols="1" rows="3" placeholder="consumable (5-500 chars)" maxlength="500" />
                                <button class="bn-icon-only"
                                    @click="addConsumableToShipment()"
                                    :disabled="isWorking || !consumable || consumable.length < 5">
                                    <ArrowSmRightIcon />
                                </button>
                            </div>
                        </div>
                    </div>
<!-- Shipment Contents -->
                    <div class="w-full flex flex-col flex-grow space-y-1 p-1 overflow-x-auto truncate">
                        <div v-if="allowEdit" class="w-full panel p-1 flex items-center justify-start">
                            <DeleteConfirm  :size="'small'" :popperContent="'clear equipment'"  :iconComponent="ArrowSmLeftIcon" @confirmedDelete="removeAllFromShipment()"
                            :disableButton="isWorking || !canRemoveAll || !allowEdit"
                            />
                        </div>
                        <div v-if="shipment.method !== 'integrator'" class="w-full sm:hidden flex flex-row space-x-1 truncate items-center justify-end px-1 py-0.5 border-b opacity-40">
                            <div class="w-auto truncate whitespace-normal">scroll</div>
                            <ArrowSmRightIcon class="w-4 h-4 flex-shrink-0" />
                        </div>
                        <div class="w-full flex flex-col items-start sm:items-center space-y-1 flex-grow overflow-y-auto p-1">
                            <div class="sm:w-full sm:flex-shrink max-w-2xl w-96 flex-shrink-0 flex-grow flex flex-col">
                                <ShipmentContents :shipment="shipment" :showBoxActions="allowEdit" 
                                @removeItem="removeFromShipment($event)" @removeRack="removeRackFromShipment($event)" 
                                @removeTool="removeToolFromShipment($event)" @removeConsumable="removeConsumableFromShipment($event)"
                                @removeItemMulti="removeMultipleFromMulti($event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToolsToReturn v-else-if="shipment.method === 'tool'" :shipment="{...shipment}" :allowEdit="allowEdit" @update="updateToolReturnAndShipment($event)" />
        </div>
    </div>
</template>

<script>
import {computed, onMounted, ref,inject, onUnmounted} from 'vue'
import api from '@/api'
import {isValid,isSameDay,isAfter} from 'date-fns'
import {getItemsByManufacturer ,formatISODateforInput,dateInputToISO,openDetails,formatNumberInput,formatISODateforDateTimeInput} from '@/shared'
import {XIcon,ArrowSmRightIcon,ArrowsExpandIcon,ArrowSmLeftIcon,DocumentTextIcon,ChevronDoubleRightIcon} from "@heroicons/vue/outline"
import {TruckIcon,ShoppingBagIcon,BanIcon} from '@heroicons/vue/solid'
import ToolboxFillIcon from '@/components/customIcons/ToolboxFillIcon.vue'
import BoxIcon from '@/components/customIcons/BoxIcon.vue'
import BoxReduced from '@/components/cards/BoxReduced.vue'
import ShipmentContents from '@/components/projects/shipping/ShipmentContents.vue'
import RackReduced from '@/components/cards/RackReduced.vue'
import autoResize from '@/components/ui/autoResize'
import BlueprintIcon from '@/components/customIcons/BlueprintIcon.vue'
import ToolsToReturn from '@/components/warehouse/tools/ToolsToReturn.vue'

export default {
    directives:{
        autoResize
    },
    props:{
        shipmentId:{type:String,default:null},
        equipment:{type:Array,default:()=>{return []}},
        racks:{type:Array,default:()=>{return []}},
        tools:{type:Array,default:()=>{return []}},
        allowEdit:{type:Boolean,default:false}
    },
    emits:["updateShipment","deleteShipment","updateEquipment","updateRack","moveShipmentForward","updateToolReturn"],
    components:{BoxReduced,RackReduced,ShipmentContents,ToolsToReturn,ArrowSmRightIcon,XIcon,ArrowsExpandIcon,ToolboxFillIcon,BoxIcon,DocumentTextIcon,ChevronDoubleRightIcon,TruckIcon,BlueprintIcon,ShoppingBagIcon,BanIcon},
    setup (props,{emit}) {
        let previousTitle
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const shipment = ref({})
        const equipmentFilter = ref(null)
        const roomFilter = ref(null)
        const phaseFilter = ref(null)
        const floorFilter = ref(null)
        const expandAll = ref(false)
        const showToolsDialog = ref(false)
        const showConsumables = ref(false)
        const toolReturnDate = ref(null)
        const consumable = ref(null)
        const showInstructions = ref(false)
        const multiBoxValues = ref({})


        onMounted(()=>{
            previousTitle = document.title
            getShipment()
            window.addEventListener("data_change_from_socket",handleSocketChange)
        })

        onUnmounted(()=>{
            document.title = previousTitle
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const availableEquipment = computed(()=>{
            return props.equipment.filter(x=>!x.shipmentId && !x.rackId)
        })

        const availableRacks = computed(()=>{
            return props.racks.filter(r=>!r.shipmentId)
        })

        const filteredEquipment = computed(()=>{
            let filtered = availableEquipment.value

            if(equipmentFilter.value) {
                let filter = equipmentFilter.value.toLowerCase()
                filtered = filtered.filter(item=>
                    (
                        (item.manufacturer && item.manufacturer.toLowerCase().includes(filter)) ||
                        (item.partNumber && item.partNumber.toLowerCase().includes(filter)) ||
                        (item.deviceType && item.deviceType.toLowerCase().includes(filter)) ||
                        (item.description && item.description.toLowerCase().includes(filter))
                    )
                )
            }

            if(roomFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId._id) && x.roomId._id === roomFilter.value)
            }

            if(floorFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId.floor) && x.roomId.floor === floorFilter.value)
            }

            if(phaseFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId.phase) && x.roomId.phase === phaseFilter.value)
            }

            // Collect kitIds from filtered items
            const kitIds = new Set(filtered.map(item => item.kitId).filter(kitId => kitId));

            // Include all items with a kitId that matches any kitId in the filtered results
            const withKitIds = availableEquipment.value.filter(item => kitIds.has(item.kitId));

            // Combine filtered items with those that have matching kitIds, avoiding duplicates
            const combined = [...filtered, ...withKitIds.filter(item => !filtered.includes(item))];

            return combined;
        })

        const filteredRacks = computed(()=>{
            let filtered = availableRacks.value

            if(equipmentFilter.value) {
                let filter = equipmentFilter.value.toLowerCase()
                filtered = filtered.filter(item=>item.name && item.name.toLowerCase().includes(filter))
            }

            if(roomFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId._id) && x.roomId._id === roomFilter.value)
            }

            if(floorFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId.floor) && x.roomId.floor === floorFilter.value)
            }

            if(phaseFilter.value) {
                filtered = filtered.filter(x=> (x.roomId && x.roomId.phase) && x.roomId.phase === phaseFilter.value)
            }

            return filtered
        })

        const roomOptions = computed(() => {
            let available =  props.equipment.filter(x=>!x.shipmentId)
            let availableRacks = props.racks.filter(x=>!x.shipmentId)
            const nameMap = new Map(); // Use a map to track unique names and their _ids

            available.forEach(item => {
                if (item.roomId) {
                const name = item.roomId.nameOne || item.roomId.nameTwo;
                if (name) {
                    // Use the name as the key and store the item's _id along with it if not already in the map
                    if (!nameMap.has(name)) {
                    nameMap.set(name, { name, _id: item.roomId?._id });
                    }
                }
                }
            });

            availableRacks.forEach(rack=> {
                if(rack.roomId) {
                    const name = rack.roomId.nameOne || rack.roomId.nameTwo
                    if(name) {
                        if(!nameMap.has(name)) {
                            nameMap.set(name, { name, _id: rack.roomId?._id })
                        }
                    }
                }
            })

            // Convert the map values to an array, then sort by name
            const sortedUniqueNames = Array.from(nameMap.values()).sort((a, b) => a.name.localeCompare(b.name));
            return sortedUniqueNames;
        })

        const phaseOptions = computed(() => {
            const phaseSet = new Set();

            availableEquipment.value.forEach(item => {
                if (item.roomId && typeof item.roomId.phase !== 'undefined') {
                const phase = item.roomId.phase;
                phaseSet.add(phase);
                }
            });

            // Convert the set to an array and sort it numerically
            return Array.from(phaseSet).sort((a, b) => a - b);
        });

        const floorOptions = computed(()=>{
            const floorSet = new Set()
            availableEquipment.value.forEach(item => {
                if(item.roomId) {
                    const floor = item.roomId.floor

                    if(floor) {
                        floorSet.add(floor)
                    }
                }
            })

            return Array.from(floorSet)
        })

        const equipmentByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'equipment')
        });

        const kitsByManufacturer = computed(() => {
            return getItemsByManufacturer(filteredEquipment.value,'kits')
        });

        const canDelete = computed(()=>{
            return (shipment.value.shipmentEquipment && shipment.value.shipmentEquipment.length === 0) &&
            (shipment.value.shipmentRacks && shipment.value.shipmentRacks.length === 0) &&
            (shipment.value.toolsRequired && shipment.value.toolsRequired.length === 0) && 
            (shipment.value.toolsToReturn && shipment.value.toolsToReturn.length === 0)
        })

        const canRemoveAll = computed(()=>{
            return (shipment.value.shipmentEquipment && shipment.value.shipmentEquipment.length > 0) || (shipment.value.shipmentRacks && shipment.value.shipmentRacks.length > 0)
        })

        const toolsGrouped = computed(()=>{
            const grouped = {}; // Use an object, not an array
            props.tools.forEach(item => {
                const category = item.category;

                if (!grouped[category]) {
                    grouped[category] =  [];
                }

                grouped[category].push(item);
            });

            return grouped;
        })

        const canAddTool = computed(()=>{
            const shipDate = dateInputToISO(shipment.value.shipmentDate)
            const returnDate = dateInputToISO(toolReturnDate.value)
            return isValid(returnDate) && (isSameDay(shipDate,returnDate) || isAfter(returnDate,shipDate))
        })

        const canShip = computed(()=>{
            if(shipment.value.method === 'tool') {
                return Array.isArray(shipment.value.toolsToReturn) && shipment.value.toolsToReturn.length > 0
            } else {
            return ((shipment.value.shipmentEquipment && shipment.value.shipmentEquipment.length > 0) ||
            (shipment.value.shipmentRacks && shipment.value.shipmentRacks.length > 0) ||
            (shipment.value.toolsRequired && shipment.value.toolsRequired.length > 0)) ||
            (shipment.value.consumables && shipment.value.consumables.length > 0)
            }
        })

        const getShipment = async (type)=>{
            startAPI(type)
            await api
            .get(`shipments/${props.shipmentId}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object'){
                    document.title = `${res.data?.data?.projectId?.companyProjectNumber || ''}${res.data?.data?.shipmentNumber ? ' - ' + res.data?.data?.shipmentNumber : ''}${res.data?.data?.method === 'integrator' ? ' - ' + 'Integrator Delivery' : ''}`
                    shipment.value = processDates(res.data.data)
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error && err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateShipment = async (id,key,value,prevValue)=>{
            startAPI()
            let body = {
                [key]:value
            }
            await api
            .put("shipments/" + shipment.value._id,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                }
                sendChangeEvent('shipment',res.data.data.projectId?._id,'',{
                    projectId:res.data.data.projectId?._id,
                    shipmentId:res?.data?.data?._id,
                    shipmentState:res?.data?.data?.shipmentState,
                    shipmentDate:res?.data?.data?.shipmentDate,
                    driverId:res?.data?.data?.driverId?._id ||res?.data?.data?.driverId
                    }
                )
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                shipment.value[key] = key === 'shipmentDate' ? formatISODateforDateTimeInput(prevValue) : prevValue
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const deleteShipment = async ()=>{
            startAPI()
            await api
            .delete("shipments/" + shipment.value._id)
            .then(()=>{
                sendChangeEvent('shipment',shipment.value.projectId?._id,'',{
                    projectId:shipment.value.projectId?._id,
                    shipmentId:shipment.value._id,
                    shipmentDate:shipment.value.shipmentDate,
                    driverId:shipment.value.driverId?._id ||shipment.value.driverId,
                    isDelete:true
                    })
                emit("deleteShipment")
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveToShipment = async (id)=>{
            startAPI()
            let body = {
                shipmentId:shipment.value._id
            }
            await api
            .put(`equipment/moveEquipmentToShipment/add/${id}`,body)
            .then((res)=>{
                updateEquipmentAndShipment(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveAllToShipment = async ()=>{
            let equipmentArray = filteredEquipment.value.map(x=>{return {_id:x._id}})
            let rackArray = filteredRacks.value.map(x=>{return {_id:x._id}})
            let body = {
                shipmentId:shipment.value._id,
                equipmentArray
            }
            if(equipmentArray.length > 0) {
                startAPI()
                await api
                .put(`equipment/moveEquipment/many/add`,body)
                .then(async (res)=>{
                    updateEquipmentAndShipment(res)
                })
                .then(async ()=>{
                    rackArray.length > 0
                    ? await moveAllRacksToShipment(rackArray,'silent')
                    : clearFilter()
                })
                .catch((err)=>{
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                    clearFilter()
                })
            } else if(rackArray.length > 0) {
                moveAllRacksToShipment(rackArray)
            }

        }

        const moveMultipeFromMulti = async (event,array)=>{
            if(event.value && Number(event.value) && Array.isArray(array) ) {
                let currentCount = array.length
                let neededCount = Math.max(1,event.value)
                if(neededCount > currentCount) {neededCount = currentCount}
                let newCount = currentCount - neededCount
                let equipmentArray = []
                if(neededCount === array.length) {
                    equipmentArray = array.map(x=>{return {_id:x._id}})
                } else {
                    equipmentArray = array.slice(0,neededCount).map(x=>{return {_id:x._id}})
                }
                let body = {
                shipmentId:shipment.value._id,
                equipmentArray
                }
                startAPI()
                await api
                .put(`equipment/moveEquipment/many/add`,body)
                .then(async (res)=>{
                    updateEquipmentAndShipment(res)
                    event?.value ? event.value = newCount : null
                })
                .catch((err)=>{
                    event?.value ? event.value = currentCount : null
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            } else {
                console.error('Data Error moving multiBox Items.')
            }
        }

        const removeMultipleFromMulti = async (event) =>{
            if(event.boxNumber && event.amount) {
                let body = {
                amount:event.amount,
                boxNumber:event.boxNumber
                }
                startAPI()
                await api
                .put(`equipment/moveEquipmentToShipment/removeMulti/${props.shipmentId}`,body)
                .then(async (res)=>{
                    updateEquipmentAndShipment(res)
                })
                .catch((err)=>{
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            } else {
                console.error('Data Error moving multiBox Items.')
            }
        }

        const moveAllRacksToShipment = async (array,type)=>{
            startAPI(type)
            let body = {
                shipmentId:shipment.value._id,
                rackArray:array
            }
            await api
            .put(`racks/moveRackToShipment/many/add`,body)
            .then((res)=>{
                updateRackAndShipment(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
                clearFilter()
            })
        }

        const removeFromShipment = async (id)=>{
            startAPI()
            await api
           .put(`equipment/moveEquipmentToShipment/remove/${id}`,{})
            .then(async (res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("updateEquipment",res.data.data)
                }
                await getShipment('silent')
                sendChangeEvent('shipment',shipment.value.projectId?._id,'',{
                    projectId:shipment.value.projectId?._id,
                    shipmentId:shipment.value._id,
                    shipmentState:res?.data?.data?.shipmentState,
                    shipmentDate:res?.data?.data?.shipmentDate,
                    driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                })
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveRackToShipment = async (id)=>{
            startAPI()
            let body = {
                shipmentId:shipment.value._id
            }
            await api
            .put(`racks/moveRackToShipment/add/${id}`,body)
            .then((res)=>{
                updateRackAndShipment(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const removeRackFromShipment = async (id)=>{
            startAPI()
            await api
            .put(`racks/moveRackToShipment/remove/${id}`,{})
            .then(async (res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("updateRack",res.data.data)
                }
                await getShipment('silent')
                sendChangeEvent('shipment',shipment.value.projectId?._id,'',{
                    projectId:shipment.value.projectId?._id,
                    shipmentId:shipment.value._id,
                    shipmentState:res?.data?.data?.shipmentState,
                    shipmentDate:res?.data?.data?.shipmentDate,
                    driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                })
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateEquipmentAndShipment = (res)=>{
            res.data?.data?.equipment
            ? emit("updateEquipment",res.data.data.equipment)
            : null

            if(res.data?.data?.shipment) {
                if(res.data?.data?.shipment && typeof res.data.data.shipment === 'object')
                {
                    shipment.value = processDates(res.data.data.shipment)
                    emit("updateShipment",res.data.data.shipment)
                    sendChangeEvent('shipment',res.data.data.shipment.projectId?._id,'',{
                        projectId:res.data.data.shipment.projectId?._id,
                        shipmentId:res?.data?.data?.shipment._id,
                        shipmentState:res?.data?.data?.shipmentState,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                }
            }
        }

        const updateRackAndShipment = (res)=>{
            res.data?.data?.rack
            ? emit("updateRack",res.data.data.rack)
            : null

            if(res.data?.data?.shipment) {
                if(res.data?.data?.shipment && typeof res.data.data.shipment === 'object')
                {
                    shipment.value = processDates(res.data.data.shipment)
                    emit("updateShipment",res.data.data.shipment)
                    sendChangeEvent('shipment',res.data.data.shipment.projectId?._id,'',{
                        projectId:res.data.data.shipment.projectId?._id,
                        shipmentId:res?.data?.data?.shipment._id,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                }
            }
        }

        const updateToolReturnAndShipment = (event)=>{
            if(event.shipment && typeof event.shipment === 'object') {
                shipment.value = processDates(event.shipment)
                emit("updateShipment",event.shipment)
                sendChangeEvent('shipment',event.shipment?.projectId?._id,'',{
                    projectId:event.shipment?.projectId?._id,
                    shipmentId:event.shipment?._id,
                    shipmentState:event.shipment?.shipmentState,
                    shipmentDate:event.shipment?.shipmentDate,
                    driverId:event.shipment?.driverId?._id || event.shipment?.driverId
                })
            }

            if(event.tool && typeof event.tool === 'object') {
                emit("updateToolReturn",event.tool)
            }
        }

        const removeFromKit = async (id)=>{
            startAPI()
            await api
            .put(`equipment/removeFromKit/${id}`,{})
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("updateEquipment",res.data.data)
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const removeAllFromShipment = async()=>{
            startAPI()
            await api
            .put(`shipments/removeAll/${shipment.value._id}`,{})
            .then(async ()=>{
                await getShipment('silent')
                const equipmentChanges = props.equipment.filter(e=>e.shipmentId).map(equip=>{
                    return {...equip,shipmentId:null}
                })
                const rackChanges = props.racks.filter(e=>e.shipmentId).map(equip=>{
                    return {...equip,shipmentId:null}
                })

                updateEquipmentAndShipment({data:{data:{equipment:equipmentChanges,shipment:shipment.value}}})
                updateRackAndShipment({data:{data:{rack:rackChanges,shipment:shipment.value}}})
            })

        }

        const addToolToShipment = async(tool)=>{
            startAPI()
            let body = {
                catalogId:tool._id,
                toolName:tool.toolName,
                category:tool.category,
                returnDate:dateInputToISO(toolReturnDate.value)
            }
            await api
            .put(`shipments/tool/add/${shipment.value._id}`,body)
            .then((res)=>{
                if(res.data.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data.data.projectId?._id,'',{
                        projectId:res.data.data.projectId?._id,
                        shipmentId:res?.data?.data?._id,
                        shipmentState:res?.data?.data?.shipmentState,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const removeToolFromShipment = async(id)=>{
            startAPI()
            await api
            .put(`shipments/tool/remove/${shipment.value._id}/${id}`)
            .then((res)=>{
                 if(res.data.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data.data.projectId?._id,'',{
                        projectId:res.data.data.projectId?._id,
                        shipmentId:res?.data?.data?._id,
                        shipmentState:res?.data?.data?.shipmentState,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const addConsumableToShipment = async()=>{
            startAPI()
            let body = {
                description:consumable.value
            }
            await api
            .put(`shipments/consumable/add/${shipment.value._id}`,body)
            .then((res)=>{
                if(res.data.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const addDrawings = async()=>{
            startAPI()
            let body = {
                description:'Print and Send Drawings.'
            }
            await api
            .put(`shipments/consumable/add/${shipment.value._id}`,body)
            .then((res)=>{
                if(res.data.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const removeConsumableFromShipment = async(id)=>{
            startAPI()
            await api
            .put(`shipments/consumable/remove/${shipment.value._id}/${id}`)
            .then((res)=>{
                if(res.data.data && typeof res.data.data === 'object') {
                    shipment.value = processDates(res.data.data)
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data.data.projectId?._id,'',{projectId:res.data.data.projectId?._id,shipmentId:res?.data?.data?._id,shipmentState:res?.data?.data?.shipmentState,shipmentDate:res?.data?.data?.shipmentDate,driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId})
                }
                consumable.value = null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveShipmentForward = ()=>{
            emit("moveShipmentForward",shipment.value._id)
        }

        const startAPI = (type)=>{
            if(type !== 'silent') {
                setModalBlocked(true)
                isWorking.value = true
                responseError.value = null
            }
        }

        const stopAPI = ()=>{
            setModalBlocked(false)
            isWorking.value = false
        }

        const processDates = (obj)=>{
            obj && typeof obj === 'object'
            ? obj.shipmentDate = formatISODateforDateTimeInput(obj.shipmentDate)
            : null
            
            return obj
        }

        const updateDate = (id,key,value,prevValue) => {
            let convertedDate =  isValid(new Date(value)) ? new Date(value).toISOString() : null
            updateShipment(id,`${key}`,convertedDate, prevValue);
        }

        const clearFilter = ()=>{
            equipmentFilter.value = null
            roomFilter.value = null
            phaseFilter.value = null
            floorFilter.value= null
        }

        const openToolsAndConsumables = (directConsumables=false)=>{
            if(showToolsDialog.value && showConsumables.value) {
                showConsumables.value = false
                showToolsDialog.value = false
            } else if (showToolsDialog.value && !showConsumables.value) {
                showToolsDialog.value = false
            } else if (!showToolsDialog.value && directConsumables) {
                showToolsDialog.value = true
                showConsumables.value = true
            } else {
                showToolsDialog.value = true
                showConsumables.value = false
            }
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'shipment':
                        if(e.detail.data?.shipmentId === shipment.value._id ) {
                            getShipment('silent')
                        }
                        break;
                    case 'room':
                    case 'new_equipment':
                    case 'equipment':
                    case 'rack':
                        if(e.detail.data?.projectId === shipment.value.projectId?._id || e.detail.projectId === shipment.value.projectId?._id) {
                            getShipment('silent')
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            isWorking,
            responseError,
            formatISODateforInput,
            shipment,
            availableEquipment,
            equipmentByManufacturer,
            kitsByManufacturer,
            //accessoriesByManufacturer,
            XIcon,
            canDelete,
            roomOptions,
            phaseOptions,
            floorOptions,
            equipmentFilter,
            roomFilter,
            phaseFilter,
            floorFilter,
            filteredEquipment,
            filteredRacks,
            clearFilter,
            updateDate,
            updateShipment,
            deleteShipment,
            openDetails,
            expandAll,
            moveToShipment,
            removeFromShipment,
            moveRackToShipment,
            removeRackFromShipment,
            removeFromKit,
            moveAllToShipment,
            //canAddAll,
            canRemoveAll,
            ArrowSmLeftIcon,
            removeAllFromShipment,
            showToolsDialog,
            toolsGrouped,
            toolReturnDate,
            canAddTool,
            addToolToShipment,
            removeToolFromShipment,
            consumable,
            addConsumableToShipment,
            addDrawings,
            removeConsumableFromShipment,
            showInstructions,
            multiBoxValues,
            moveMultipeFromMulti,
            formatNumberInput,
            removeMultipleFromMulti,
            formatISODateforDateTimeInput,
            canShip,
            moveShipmentForward,
            showConsumables,
            updateToolReturnAndShipment,
            openToolsAndConsumables
        }
    }

}
</script>

<style>

</style>