<template>
  <div class="w-full flex flex-col flex-grow items-center p-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div v-if="allowEdit && !external" class="w-full flex items-center border-b p-1">
        <div>
            <Popper hover arrow placement="bottom" :content="showUpload ? 'Show Documents' : 'Upload'">
                <button class="px-2 bn-solid-sky"
                @click="showUpload = !showUpload"
                :disabled="isWorking || isModalBlocked"
                >
                    <div v-if="showUpload">Documents</div>
                    <div v-else>Upload</div>
                    <ArrowSmRightIcon :class="{'rotate-180' : showUpload}" />
                </button>
            </Popper>
        </div>
    </div>
    <div v-show="!showUpload" class="w-full flex flex-col flex-grow items-center flex-auto h-0 overflow-y-auto p-1">
        <DocumentDisplay :entity="entity" :model="model" :route="route"  :allowEdit="allowEdit" :secure="secure" :locationOptions="locationOptions" :documentTypeOptions="documentTypeOptions" :fileUploaded="fileUploaded" @triggerProjectUpdate="triggerProjectUpdate()" :invoiceId="invoiceId" :external="external" :secureCode="secureCode" />
    </div>
    <div v-show="showUpload" class="w-full flex flex-col flex-grow">
        <FileUploads :entity="entity" :model="model" :route="route" :locationOptions="locationOptions" :secure="secure" :documentTypeOptions="documentTypeOptions" @fileUploaded="fileUploaded = $event" :invoiceId="invoiceId"  />
    </div>
  </div>
</template>

<script>
import {computed, inject, onMounted, onUnmounted, ref } from 'vue'
import {ArrowSmRightIcon} from '@heroicons/vue/solid'
import {openDetails} from '@/shared'
import FileUploads from '@/components/ui/FileUploads.vue'
import DocumentDisplay from '@/components/ui/DocumentDisplay.vue'
import api from '@/api'

export default {
    props:{
        entity:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false},
        secure:{type:Boolean,default:false},
        model:{type:String,default:'projects'},
        route:{type:String,default:'documents'},
        invoiceId:{type:String,default:null},
        external:{type:Boolean,default:false},
        secureCode:{type:String,default:null}
    },
    emits:["triggerProjectUpdate"],
    components:{FileUploads,DocumentDisplay,ArrowSmRightIcon},
    setup (props,{emit}) {

        const global = inject('global')
        const {setModalBlocked,isModalBlocked} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const showUpload = ref(false)

        const rooms = ref([])
        const documentTypeOptions = ref([])
        const fileUploaded = ref({})
        

        onMounted(()=>{
            if(props.model === 'organizations') {
                getOrganizationDocumentTypes()
            } else {
                !props.external ? getFileDocumentTypes() : null
                !props.secure ? getLocationOptions() : null
            }
            props.external ? document.title = `DLS Photos - ${props.entity?.name} ` : null
            window.addEventListener("data_change_from_socket",handleSocketChange)
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const locationOptions = computed(()=>{
            const locations = rooms.value.map(room => {
                const roomOptions = [
                { id: room._id, name: room.nameOne || room.nameTwo || `No Name - ${room.roomType}` }
                ];

                if (room.racks && room.racks.length > 0) {
                const rackOptions = room.racks.map(rack => ({ id: rack._id, name: ` - Rack: ${rack.name}`}));
                roomOptions.push(...rackOptions);
                }

                return roomOptions;
            });

            return locations.flat();
        })

        const getLocationOptions = async ()=>{
            await api
            .get(`${props.external ? 'external/':''}projects/assignedLocationOptions/${props.entity?._id}${props.external && props.secureCode ? '/'+props.secureCode :''}`)
            .then((res)=>{
                if(Array.isArray(res.data?.data)) {
                    rooms.value = res.data.data
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
            })
        }

        const getOrganizationDocumentTypes = async (type)=>{
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }
            responseError.value = null
            await api
            .get('organizations/documentOptions')
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? documentTypeOptions.value = res.data.data.map(x=>x.docOption)
                : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err?.message)
                type !== 'silent' 
                ? responseError.value = 'Failed to get document options.'
                : null
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const getFileDocumentTypes = async (type)=>{
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }
            responseError.value = null
            await api
            .get('projects/documentOptions')
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? documentTypeOptions.value = res.data.data.map(x=>x.docOption)
                : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err?.message)
                type !== 'silent' 
                ? responseError.value = 'Failed to get document options.'
                : null
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const triggerProjectUpdate = ()=>{
            emit("triggerProjectUpdate")
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'room':
                    case 'rack':
                        if(props.model === 'projects' && !props.secure) {
                            if(e.detail?.data?.projectId === props.entity?._id) {
                                getLocationOptions()
                            }
                        }
                        break;
                    case 'projectDocOption':
                        props.model === 'projects' && !props.external
                            ? getFileDocumentTypes()
                            : null
                        break;
                    case 'docOption':
                        props.model === 'organizations'
                            ? getOrganizationDocumentTypes()
                            : null
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            isWorking,
            responseError,
            showUpload,
            openDetails,
            isModalBlocked,
            locationOptions,
            documentTypeOptions,
            fileUploaded,
            triggerProjectUpdate 
        }
    }

}
</script>

<style>

</style>