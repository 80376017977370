<template>
  <div class="absolute h-full w-80 panel top-0 right-0 bg-opacity-90 overflow-auto z-70 modal-back-fade min-h-568"
    @drop.prevent
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
  >
      <div ref="modalContainer" class="w-full h-full flex flex-col flex-grow p-2 items-center overflow-y-auto">
        <div class="w-full flex flex-row items-center justify-end p-1">
            <button class="bn-icon-small bn-solid dark:bn-solid-sky" @click="closePanel()" :disabled="isModalBlocked">
                <XIcon />
            </button>
        </div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto py-2 px-1">
            <div class="w-full flex flex-col flex-grow overflow-y-auto rounded-md bg-neutral-100 dark:bg-neutral-800">
                <slot name="content" />
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import {XIcon} from "@heroicons/vue/outline"
import { inject,ref,onMounted, onUnmounted,computed,nextTick } from 'vue'
export default {
    components:{XIcon},
    emits:["closePanel"],
    setup (_,{emit}) {
        const global = inject('global')
        const {isModalBlocked} = global
        const modalContainer = ref(null);

        const focusableElementsInModal = computed(() => {
        if (modalContainer.value) {
            let elements = Array.from(modalContainer.value.querySelectorAll(
            'a[href], area[href], input:not([disabled]):not([type="file"][hidden]), select:not([disabled]), textarea:not([disabled]), ' +
            'button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]'
            ));

            // Sort elements by tabindex. Elements without a tabindex or with tabindex="0" are treated as 0 and sorted based on their position in the DOM.
            elements.sort((a, b) => {
            let tabindexA = a.getAttribute('tabindex') || 0;
            let tabindexB = b.getAttribute('tabindex') || 0;

            tabindexA = parseInt(tabindexA, 10);
            tabindexB = parseInt(tabindexB, 10);

            // Elements with a positive tabindex are navigated to first, in order of their tabindex.
            if (tabindexA > 0 && tabindexB > 0) {
                return tabindexA - tabindexB;
            }
            // Elements with a tabindex of 0 or no tabindex are navigated to last, in the order they appear in the DOM.
            if (tabindexA === 0 && tabindexB === 0) {
                return 0; // Keep original order if both are 0
            }
            // Any element with a positive tabindex should come before elements with a tabindex of 0 or no tabindex.
            if (tabindexA > 0) {
                return -1;
            }
            if (tabindexB > 0) {
                return 1;
            }
                // Fallback to original order if none of the above conditions are met, though this line is technically redundant.
                return 0;
            });
            
            return elements;
        } else {
            return [];
        }
        });

        const trapFocus = (e) => {
        if (!modalContainer.value) {
            console.error("Modal container is not available"); // Debugging log
            return;
        }

        const firstFocusableElement = focusableElementsInModal.value[0];
        const lastFocusableElement = focusableElementsInModal.value[focusableElementsInModal.value.length - 1];

        if (e.key !== 'Tab' && e.keyCode !== 9) return;

        const focusableElementsArray = Array.from(focusableElementsInModal.value);

            if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement || !focusableElementsArray.includes(document.activeElement)) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
            } else {
            if (document.activeElement === lastFocusableElement || !focusableElementsArray.includes(document.activeElement)) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
            }
        };

        onMounted(() => {
            nextTick(() => {
                document.addEventListener('keydown', trapFocus);
                if (modalContainer.value && modalContainer.value.querySelector) {
                const firstFocusableElement = modalContainer.value.querySelector(
                    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), ' +
                    'button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]'
                );
                if (firstFocusableElement) {
                    firstFocusableElement.focus();
                }
                }
            });
        });

        onUnmounted(() => {
        document.removeEventListener('keydown', trapFocus);
        });

        const closePanel = ()=>{
            emit("closePanel")
        }

        return {
            modalContainer,
            isModalBlocked,
            closePanel
        }
    }

}
</script>

<style>

</style>