import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import NotFound from '../views/NotFound.vue'
import Project from '@/components/projects/Project.vue'
import Error from '../components/Error.vue'

// import global state
import global from '../global'

const routes = [

//Login and Account Management
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (authenticated.value.loggedIn) next({ name: 'Home' })
      else next()
    }
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
  {
    path: '/resetpassword/:resetToken',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
  {
    path: '/verifyaccount/:verifytoken',
    name: 'VerifyAccount',
    component: () => import('../components/VerifyAccount.vue'),
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
  {
    path:'/external/:projectId/:secureCode',
    name:'External',
    component: ()=>import('@/components/external/ExternalEquipmentTracker.vue'),
    beforeEnter(to,from,next) {
      const {authenticated} = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
  {
    path:'/external/roomTracker/:projectId/:secureCode',
    name: 'ExternalRoomTracker',
    component: ()=>import('@/components/external/ExternalRoomTracker.vue'),
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
  {
    path:'/external/photos/:projectId/:secureCode',
    name: 'ExternalProjectPhotos',
    component: ()=>import('@/components/external/ExternalProjectPhotos.vue'),
    beforeEnter(to, from, next) {
      const { authenticated } = global
      if (!authenticated.value.loggedIn) { next() }
      else next({ name: 'Home' })
    }
  },
//Home, About & Error
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../components/home/Home.vue'),
    meta: { title: 'Home' },
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    props: true,
    beforeEnter(to, from, next) {
      if (to.params.status) { next() }
      else next({ name: 'Home' })
    }
  },
//Account Specific Info
{
  path: '/me',
  name: 'MyProfile',
  component: () => import('../components/users/MyProfile.vue')
},
{
  path: '/pto',
  name: 'RequestPTO',
  component: () => import('../components/timeManagement/PTO/PTO.vue'),
  meta:{
    title:'PTO Requests',
    accessControl:{pto:1}
  }
},
{
  path: '/timesheets',
  name: 'Timesheets',
  component: () => import('../components/timeManagement/timesheets/TimeSheetsUser.vue'),
  meta:{
    primeOnly:true,
    timesheet:true
  }
},
{
  path:'/approveTimesheets',
  name:'Approve Timesheets',
  component :()=> import('@/components/timeManagement/timesheets/TimesheetApproval.vue'),
  meta: {
    title:'Approve Timesheets',
    primeOnly:true,
    accessControl:{approveTimesheets:1}
  }
},
{
  path: '/timeclock',
  name: 'Time Clock',
  component: ()=> import ('../components/timeManagement/WorkTime.vue'),
},
{
  path: '/timeclockAdmin',
  name: 'Timeclock Admin',
  component:()=> import('@/components/timeManagement/AllUsersClockedTime.vue'),
  meta:{
    title:'Timeclock Admin',
    accessControl:{approveTimesheets:1}
  }
},
{
  path:'/myTimeclocks',
  name:'My Timeclock History',
  component:()=>import('@/components/timeManagement/AllUsersClockedTime.vue'),
  props:{userOnly:true},
  meta:{
    timesheet:true
  }
},
//Users
  {
    path: '/users',
    name: 'Users',
    component: () => import('../components/users/Users.vue'),
    meta: {
      title: 'Users',
      accessControl:{'manageAccounts':1}
    }
  },
//Projects
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../components/projects/ViewProjects.vue'),
    meta: {
      title: 'Projects',
      role: 0,
      accessControl:{projects:1}
    }
  },
  {
    path: '/project/:projectId/',
    name: 'Project',
    component:Project,
    meta: {
      title: 'Project',
      accessControl: {projects:1}
    }
  },
//Invoicing
  {
    path:'/invoices',
    name:'Invoices',
    component:()=>import('@/components/invoices/Invoices.vue'),
    meta:{
      title:'Invoices',
      accessControl:{invoices:1}
    }
  },
  {
    path:'/openInvoices',
    name:'Open Invoices',
    component:()=>import('@/components/invoices/OpenInvoicesByCustomer.vue'),
    meta:{
      title:'Open Invoices',
      accessControl:{invoices:1}
    }
  },
//Receiving and Equipment
  {
    path: '/findEquipment',
    name: 'findEquipment',
    component: () => import('../components/equipment/SearchEquipment.vue'),
    meta: {
      title: 'Find Equipment',
      role: 3
    }
  },
  {
    path: '/receiving',
    name: 'Receiving',
    component: () => import('../components/equipment/ReceiveEquipment.vue'),
    meta: {
      title: 'Receiving',
      accessControl:{receiving:1}
    }
  },
  {
    path: '/equipmentCatalog',
    name: 'EquipmentCatalog',
    component: () => import('../components/equipment/EquipmentCatalog'),
    meta: {
      title: 'Equipment Catalog',
      accessControl:{receiving:1}
    }
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../components/equipment/Tools.vue'),
    meta: {
      title: 'Tools',
      primeOnly:true,
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path: '/vehicleManagement',
    name: 'Vehicles',
    component: () => import('../components/vehicles/VehicleManagement.vue'),
    meta: {
      title: 'Vehicles',
      primeOnly:true,
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('../components/organizations/Organizations.vue'),
    meta: {
      title: 'Organizations',
      accessControl: {organizations:1}
    }
  },
  {
    path:'/shippingRequests',
    name:'Warehouse Requests',
    component: () => import('../components/warehouse/ShippingRequests.vue'),
    meta: {
      title: 'Warehouse Requests',
      accessControl:{warehouseQueue:1}
    }
  },
  {
    path:'/rackBuildRequests',
    name:'Rack Requests',
    component: () => import('@/components/warehouse/rackBuilds/RackBuildRequests.vue'),
    meta: {
      title: 'Rack Requests',
      accessControl:{rackQueue:1}
    }
  },
  {
    path:'/invoiceRequests',
    name:'Invoice Requests',
    component: () => import('../components/invoices/InvoiceRequests.vue'),
    meta: {
      title: 'Invoice Requests',
    }
  },
  {
    path:'/scanner',
    name:'Scanner',
    component:()=>import('../components/warehouse/Scanner.vue'),
    meta:{
      title:'QR Scanner',
      primeOnly:true
    }
  },
  {
    path:'/printLabels',
    name:'Labels',
    component:()=>import('../components/equipment/PrintLabels.vue'),
    meta:{
      title:'Labels',
      accessControlAny:{warehouseQueue:1,rackQueue:1}
    }
  },
  {
    path: '/DataManagement',
    name: 'DataManagement',
    component: () => import('../components/applicationData/DataManagement.vue'),
    props: true,
    meta: {
      title: 'Data Management'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/scheduleResources',
    name: 'Schedule Resources',
    component: ()=>import('@/components/scheduling/resources/Schedule.vue'),
    meta: { 
      title: 'Schedule Resources',
      primeOnly:true,
      accessControl:{scheduling:1}
    },
    props: true
  },
  {
    path:'/shipmentSchedule',
    name:'Shipment Schedule',
    component:()=>import('@/components/warehouse/schedule/ShipmentSchedule.vue'),
    props:{inModal:false},
    meta:{
      title:'Shipment Schedule',
      primeOnly:true,
      accessControlAny:{warehouseQueue:1,projects:2}
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  hash: false,
  routes
})

// maintains global auth state on refresh, redirects to intended target after login & nav guard for authenticated users
router.beforeEach(async (to, from, next) => {
  // save original target if not authenticated
  const redirectTarget = window.location.pathname || '/'
  // sets display title of document
  document.title = to.meta.title || to.name

  // verified authentication from /me route and repopulates state variables
  const { authenticated, closeSideBar, authOnRefresh } = global
  closeSideBar() // close sidebar on change of router views
  if (sessionStorage.getItem("refresh") === sessionStorage.getItem("refresh")) { await authOnRefresh() }
  window.scrollTo(0, 0) //set scroll position to top when changing router views

  // redirects to login if not authenticated unless target is in toList (no auth required)
  const toList = ['Login', 'ForgotPassword', 'ResetPassword', 'VerifyAccount','External','ExternalRoomTracker','ExternalProjectPhotos']

  if (!toList.includes(to.name) && !authenticated.value.loggedIn) {
    console.log('redirecting')
    sessionStorage.setItem("redirectTarget", redirectTarget)
    next({ name: 'Login' })
  }
  else next()
})

// navigation guard to verify user role has access to route based on route->meta->role(level)
router.beforeEach((to, from, next) => {
  const {authenticated } = global

  if (to.meta.primeOnly) {
    !authenticated.value.organization?.orgType?.includes('primary')
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied: Internal Only.' } })
    : next()
  } else if (to.meta.accessControl) {
    !Object.keys(to.meta.accessControl).every((key)=>{return authenticated.value.role.accessModel[key] >= to.meta.accessControl[key]})  
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied.' } }) : next()
  } else if (to.meta.accessControlAny) {
    (Object.keys(to.meta.accessControlAny).filter((a)=> authenticated.value.role.accessModel[a] >= to.meta.accessControlAny[a]).length === 0) 
    ? next({ name: 'Error', params: { status: 403, message: 'Access Denied at Access Control Any.' } }) : next()
  } else if (to.meta.timesheet && to.meta.timesheet === true) {
    !authenticated.value.timesheet ? next({ name: 'Error', params: { status: 403, message: 'Access Denied.' } }) : next()
  } else {
    next()
  }

})


export default router
