<template>
  <div class="w-full max-w-lg">
    <div class="flex h-16 w-16 place-content-center mx-auto">
          <img src="../assets/favicon.png" alt="DLS" class="object-contain self-center"/>
    </div>
    <div class=" w-full text-xl p-2">{{ msg }}</div>
    <div class="w-full text-center error">{{ errorMsg }}</div>
    <form class="flex flex-col space-y-3 p-4 w-full" @submit.prevent>
      <div class="w-full">
        <input v-model.trim="password" class="w-full input-text" type="password" placeholder="password" autocomplete="off" @keyup="validate"
        :class="validPassword ? 'bg-green-100' : ''"
        />
      </div>
      <div class="w-full">
        <input v-model.trim="confirmPassword" class="w-full input-text" type="password" placeholder="confirm password" autocomplete="off" @keyup="validate"
        :class="passwordConfirmed ? 'bg-green-100' : ''"
        />
      </div>
        <div class="flex flex-col flex-nowrap">
          <div class="text-sm text-left p-1 flex flex-row space-x-2"
          :class="!error.upper ? 'text-red-500' : 'text-green-500'"
          >
            <div v-if="!error.upper"><XCircleIcon class="h-5 w-5" /></div>
            <div v-else><CheckCircleIcon class="h-5 w-5" /></div>
            <div>Uppercase letter</div> 
          </div>
          <div class="text-sm text-left p-1 flex flex-row space-x-2"
          :class="!error.lower ? 'text-red-500' : 'text-green-500'"
          >
            <div v-if="!error.lower"><XCircleIcon class="h-5 w-5" /></div>
            <div v-else><CheckCircleIcon class="h-5 w-5" /></div>
            <div>Lowercase letter</div>
          </div>
          <div class="text-sm text-left p-1 flex flex-row space-x-2"
          :class="!error.numChar ? 'text-red-500' : 'text-green-500'"
          >
            <div v-if="!error.numChar"><XCircleIcon class="h-5 w-5" /></div>
            <div v-else><CheckCircleIcon class="h-5 w-5" /></div>
            <div>Number or special character</div>
          </div>
          <div class="text-sm text-left p-1 flex flex-row space-x-2"
            :class="!error.length ? 'text-red-500' : 'text-green-500'"
          >
            <div v-if="!error.length"><XCircleIcon class="h-5 w-5" /></div>
            <div v-else><CheckCircleIcon class="h-5 w-5" /></div>
            <div>Minimum of 8 characters in length</div>
          </div>
          <div class="text-sm text-left p-1 flex flex-row space-x-2"
            :class="!passwordConfirmed ? 'text-red-500' : 'text-green-500'"
          >
            <div v-if="!passwordConfirmed"><XCircleIcon class="h-5 w-5" /></div>
            <div v-else><CheckCircleIcon class="h-5 w-5" /></div>
            <div>Passwords Match</div>
          </div>
        </div>

      <div class="p-1 w-full">
        <button class="btn-primary btn-text" @click="onSubmit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>

import {CheckCircleIcon,XCircleIcon} from "@heroicons/vue/solid"

export default {
  components: { CheckCircleIcon,XCircleIcon },
  props: {
    msg: { type: String }, //add heading
  },
  emits: ["valid"],
  data: function () {
    return {
      password: "",
      confirmPassword: "",
      passwordConfirmed: false,
      error: [],
      validPassword: false,
      validated: false,
      errorMsg: "",
    };
  },
  methods: {
    validate: function () {
      // Password must have at least 8 characters with at least one Capital letter,
      //  at least one lower case letter and at least one number or special character.
      this.password.match(/(?=.*[A-Z])/)
        ? (this.error.upper = true)
        : (this.error.upper = false);

      this.password.match(/(?=.*[a-z])/)
        ? (this.error.lower = true)
        : (this.error.lower = false);

      this.password.match(/(?=.*[0-9])/) || this.password.match(/(?=.*\W)/)
        ? (this.error.numChar = true)
        : (this.error.numChar = false);

      this.password.match(/(?=.{8,}$)/)
        ? (this.error.length = true)
        : (this.error.length = false);

      this.password.match(
        /(?=^.{8,}$)((?!.*\s)(?=.*[A-Z])(?=.*[a-z]))((?=(.*\d){1,})|(?=(.*\W){1,}))^.*$/
      )
        ? (this.validPassword = true)
        : (this.validPassword = false);

      this.password == this.confirmPassword && this.password
        ? (this.passwordConfirmed = true)
        : (this.passwordConfirmed = false);

      this.validPassword && this.passwordConfirmed
        ? (this.validated = true)
        : (this.validated = false);
    },

    onSubmit: function () {
      this.errorMsg = "";
      this.validated
        ? this.$emit("valid", this.password)
        : (this.errorMsg = "Please enter and confirm valid password");
    },
  },
};
</script>

<style>
</style>