import axios from 'axios'
import global from '@/global'

const api = axios.create({
    baseURL:'/api/v1/',
    //headers:{
    //        authorization: "Bearer " + global.authenticated.value.token,
    //}
})

api.interceptors.request.use((config) => {
    const token = global.authenticated.value.token;
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
});


export default api