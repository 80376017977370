<template>
  <div
    class="fixed pin top-0 pt-24 w-screen h-full flex flex-col items-center"
    style="min-height: 568px"
  >
    <div
      class="panel w-3/4 flex justify-center items-center py-12 mx-auto rounded-md flex-col flex-nowrap space-y-3"
    >
      <div class="text-6xl">404</div>
      <div class="text-2xl">Page Not Found</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>