<template>
  <div class="w-full h-full flex flex-col justify-start space-y-4 p-12 items-center">
    <div class="w-full flex flex-row space-x-3 items-center justify-center error">
      <ExclamationCircleIcon class="h-8 w-8"  />
      <div class="font-bold text-xl">{{status || 500}}</div>
    </div>
    <div class="text-xl font-semibold">{{message || 'Something went wrong!'}}</div>
  </div>
</template>

<script>
import {ExclamationCircleIcon} from "@heroicons/vue/solid"

export default {
  components:{ExclamationCircleIcon},
  props: ['status', 'message']
}
</script>

<style>

</style>