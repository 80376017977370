<template>
  <div class="w-auto flex flex-row items-center space-x-0.5 flex-shrink-0">
<!-- Database -->
    <div class="flex flex-row items-center">
        <Popper hover arrow placement="bottom" :content="'database'">
            <div class="flex flex-row items-center space-x-1" title="database">
            <!--<div class="rounded-full w-2 h-2" :class="appStatus.dbConnection ? 'bg-green-500' : 'bg-red-700'"></div>-->
            <DatabaseIcon class="h-5 w-5" :class="appStatus?.dbConnection ? 'text-green-500' : 'text-red-700'" />
            </div>
        </Popper>
    </div>
<!-- Websockets -->
    <div class="flex flex-row items-center px-1">
        <Popper hover arrow placement="bottom" :content="'sockets'">
            <div class="flex flex-row items-center space-x-1">
            <!--<div class="rounded-full w-2 h-2" :class="appStatus.wsConnection ? 'bg-green-500' : 'bg-red-700'"></div>-->
            <StatusOnlineIcon class="h-4 w-4" :class="appStatus?.wsConnection ? 'text-green-500' : 'text-red-700'" />
            </div>
        </Popper>
    </div>
<!-- Scrollbars -->
    <div class="flex flex-row items-center">
        <Popper hover arrow placement="bottom" :content="scrollBars ? 'hide scrollbars' : 'show scrollbars'">
            <button class="bn-icon-only" @click="toggleScrollBars()">
            <SelectorIcon :class="{'opacity-40' : !scrollBars}" />
            </button>
        </Popper>
    </div>
<!-- Dark Mode Toggle -->
    <div class="flex flex-row items-center">
        <Popper hover arrow placement="bottom" :content="darkMode ? 'toggle light' : 'toggle dark'">
            <button class="bn-icon-only" @click="toggleTheme()">
            <MoonIcon v-if="darkMode" />
            <SunIcon v-else />
            </button>
        </Popper>
    </div>
  </div>
</template>

<script>
import {DatabaseIcon,StatusOnlineIcon} from '@heroicons/vue/outline'
import {SunIcon,MoonIcon,SelectorIcon} from "@heroicons/vue/solid"
import { inject, ref } from 'vue'
export default {
    components:{
        DatabaseIcon,
        StatusOnlineIcon,
        SunIcon,
        MoonIcon,
        SelectorIcon
    },
    setup() {
        const global = inject('global')
        const {
            appStatus,
            darkState,
            scrollState,
            setTheme,
            toggleTheme,
            setScrollBars,
            toggleScrollBars,
        } = global
        
        const darkMode = ref(darkState)
        const scrollBars = ref(scrollState)

        return {
            appStatus,
            darkState,
            scrollState,
            setTheme,
            toggleTheme,
            setScrollBars,
            toggleScrollBars,
            darkMode,
            scrollBars
        }

    }
}
</script>

<style>

</style>