<template>
  <div v-if="!showConfirm" class="text-xs">
    <Popper hover arrow placement="top" :content="popperContent">
      <button  class="bn-solid-red" :class="size === 'small' ? 'bn-icon-small' : 'bn-icon'"
      @click="showConfirm = true"
      :disabled="disableButton"
      >
        <component :is="iconComponent" />
      </button>
    </Popper>
  </div>
  <div v-else class="text-xs">
    <Popper hover arrow placement="top" :content="'confirm'">
      <button class="px-1 bn-solid"
      :disabled="disableButton"
      >
        <CheckIcon class="text-green-500 hover:text-green-900 cursor-pointer" @click="confirmDelete()" />
        <XIcon class="text-red-500 hover:text-red-900 cursor-pointer" @click="showConfirm = false" />
      </button>
    </Popper>
  </div>
</template>

<script>
import {TrashIcon,CheckIcon,XIcon} from "@heroicons/vue/outline"
import { ref } from 'vue'
export default {
    components:{TrashIcon,CheckIcon,XIcon},
    props:{
        size:{type:String},
        disableButton:{type:Boolean,default:false},
        popperContent:{type:String,default:''},
        iconComponent:{type:[Object, Function],default:()=>TrashIcon}
    },
    emits:["confirmedDelete"],
    setup(props, { emit }) {
        const showConfirm = ref(false)

        const confirmDelete = ()=>{
            emit('confirmedDelete')
            showConfirm.value = false
        }

        return {
            showConfirm,
            confirmDelete
        }
    }
}
</script>

<style>

</style>