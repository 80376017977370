<template>
<!-- Login Form if user is not authenticated -->
  <div v-if="!authenticated.loggedIn" class="w-full h-full">
    <div v-if="isLoading" class="absolute z-20 flex h-screen w-screen items-center justify-center bg-neutral-300">
      <LoadingData :size="'large'" />
    </div>
<!-- Background Container -->
    <div class="flex h-full w-full items-center justify-center bg-neutral-300 dark:bg-neutral-900" style="min-height: 568px">
<!-- Login dialog -->
      <div class=" w-11/12 sm:w-full rounded-lg shadow-lg dark:shadow-neutral-950 border-t-third border-t-8 max-w-lg pt-6 pb-2 px-3 bg-neutral-50 dark:bg-neutral-900 border">
        <div class="h-full w-full flex flex-col space-y-2">
    <!-- Company Logo -->
            <div class="sm:flex w-full items-center hidden">
              <img :src="require('../assets/logo.png')" class="mx-auto max-h-16 my-6 max-w-md" alt="Digital Labor Solutions" @load="imageLoaded()" />
            </div>
            <div class="flex sm:hidden w-full items-center">
              <img :src="require('../assets/favicon.png')" class="mx-auto max-h-16 my-6 max-w-md" alt="Digital Labor Solutions" @load="imageLoaded()" />
            </div>
    <!-- Login form component -->
            <div class="flex-grow w-full px-3 sm:px-8">
              <LoginDialog />
            </div>
    <!-- Authorized use disclaimer -->
            <div class="text-sm w-full py-2">For Authorized Use Only</div>
    <!-- Lower section with forgot link and favicon -->
            <div class="w-full flex flex-row space-x-2 items-center justify-between px-3 sm:px-6">
              <div class="flex justify-start">
                <router-link to="/forgotpassword" replace class="text-sm hover:underline float-left whitespace-nowrap">
                  Forgot Password
                </router-link>
              </div>
              <div class="flex justify-end">
                <img src="../assets/favicon.png" class="max-h-12" alt="DLS"/>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject,ref } from "vue";
import LoginDialog from "../components/LoginDialog.vue"

const isLoading =  ref(true)

export default {
  name: "Login",
  components: {
    LoginDialog
  },
  setup() {
    const global = inject("global");
    const { authenticated } = global;

    const imageLoaded = ()=>{
      isLoading.value = false
    } 

    return { authenticated,isLoading,imageLoaded };
  },
};
</script>
