<template>
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  class="icon icon-tabler icon-tabler-clear-formatting" 
  width="24" 
  height="24" 
  viewBox="0 0 24 24" 
  stroke-width="2" 
  stroke="currentColor" 
  fill="none" 
  stroke-linecap="round" 
  stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M17 15l4 4m0 -4l-4 4" />
  <path d="M7 6v-1h11v1" />
  <path d="M7 19l4 0" />
  <path d="M13 5l-4 14" />
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>