<template>
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  height="24" 
  viewBox="0 -960 960 960" 
  width="24"
  fill="currentColor"
  >
  <path d="M160-440v-80h640v80H160Z"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>