<template>
  <div class="w-full flex items-center">
    <div v-if="editAddress == false" class="px-1 flex flex-col w-full" :class="allowEdit ? 'hover:bg-sky-700 hover:dark:bg-sky-600 cursor-pointer' : null "
      @mouseenter="allowEdit ? showEditAddressOverlay = true : null"
      @mouseleave="allowEdit ? showEditAddressOverlay = false : null"
    >
      <div class="w-full flex flex-row flex-nowrap space-x-1 truncate">
        <div class="w-full flex flex-col space-y-0.5">
          <span v-if="addressHeading" class="flex flex-grow py-1 font-bold">{{ addressHeading }}:</span>
          <div class="w-full flex flex-col space-y-0.5 truncate">
            <div class="text-left whitespace-pre">{{`${currentAddress.street_number || '' } ${currentAddress.street || '' }`}}</div>
            <div v-if="currentAddress.addressTwo || currentAddress.address2" class="text-left whitespace-pre">{{`${currentAddress.addressTwo || currentAddress.address2 ||''}` }}</div>
            <div class="text-left whitespace-pre">{{`${currentAddress.city || '' } ${currentAddress.city? ',' : '' } ${currentAddress.state || '' } ${currentAddress.postalCode || '' }`}}</div>
          </div>
        </div>
        <div class="flex items-center text-right pr-1">
          <button v-if="allowEdit && showEditAddressOverlay && Object.keys(currentAddress).length !== 0 && canBeEmpty" 
          class="bn-solid-red bn-icon-small"
          @click="removeAddress()"
          >
            <TrashIcon />
          </button>
        </div>
        <div class="flex items-center text-right pr-1">
          <button v-if="allowEdit && showEditAddressOverlay" class="bn-icon-small"
           @click="allowEdit ? editAddress = true : null">
            <PencilAltIcon />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-row w-full items-center space-x-2">
      <div class="w-full flex-grow ">
        <address-lookup :placeholder_text="'Address'" @addressSelected="updateAddress($event)"/>
      </div>
      <div class="flex flex-row space-x-2 justify-end items-center">
        <button v-if="Object.keys(this.newAddress).length != 0" class="bn-icon-small bn-solid-green" @click="saveNewAddress">
          <SaveIcon />
        </button>
        <button class="bn-icon-small bn-solid-red" @click="closeEditAddressDialog">
          <XIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import addressLookup from "../ui/addressLookup.vue";
import {TrashIcon, PencilAltIcon, SaveIcon,XIcon} from "@heroicons/vue/outline"

export default {
  props: {
    allowEdit:{type:Boolean,default:false},
    addressHeading: { type: String, default: "" },
    currentAddress: {
      type: Object,
      default: () => {
        return {};
      },
    },
    canBeEmpty:{
      type: Boolean,
      default:false
    }
  },
  components: {
    addressLookup,
    TrashIcon,
    PencilAltIcon,
    SaveIcon,
    XIcon
  },
  name: "editableAddress",
  emits: ["addressChange", "removeAddress"],

  data: () => {
    return {
      showEditAddressOverlay: false,
      editAddress: false,
      newAddress: {},
    };
  },

  methods: {
    removeAddress: function () {
      this.$emit("removeAddress");
      this.closeEditAddressDialog();
    },
    updateAddress: function (data) {
      this.newAddress = { ...data };
    },
    saveNewAddress: function () {
      if (Object.keys(this.newAddress).length != 0) {
        this.closeEditAddressDialog();
        this.$emit("addressChange", this.newAddress);
      }
    },
    closeEditAddressDialog: function () {
      this.editAddress = false;
    },
  },
};
</script>

<style>
</style>