<template>
    <div class="w-full p-1">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
    </div>
    <div class="w-full flex flex-col">
        <div class="w-full flex flex-col space-y-1 p-1">
            <div v-for="(sow,index) in orderedSOW" :key="sow._id" class="w-full flex flex-col overflow-y-auto truncate p-1" 
            :class="[
            {'border-b': orderedSOW && orderedSOW.length > 1},
            {'border border-red-500': errorId === sow._id},
            !currentSOWItems.includes(sow._id) && editingNote._id !== sow._id ? allowEdit ? 'opacity-40': 'hidden' : null
            ]"
            >
                <div v-if="allowEdit" class="w-full p-1 flex justify-between rounded-md">
                    <input type="date" v-model="sow.startDate" class="w-28"
                    @click="prevVal = sow.startDate" @blur="updateSOW(sow._id,'startDate',dateInputToISO($event.target.value))"
                    :disabled="isWorking"
                    />
                    <input type="date" v-model="sow.endDate" class="w-28"
                    @click="prevVal = sow.endDate" @blur="updateSOW(sow._id,'endDate',dateInputToISO($event.target.value))"
                    :disabled="isWorking"
                    />
                </div>
                <div class="w-full flex flex-row space-x-2 items-start">
                    <div v-if="allowEdit" class="w-auto px-1 py-2 flex flex-col space-y-1">
                        <ChevronUpIcon v-if="index > 0" class="h-5 w-5 flex-shrink-0 cursor-pointer hover:text-sky-600" @click="moveSOW(sow._id,'down')" />
                        <ChevronUpIcon v-if="index < orderedSOW.length -1 " class="h-5 w-5 flex-shrink-0 cursor-pointer hover:text-sky-600 rotate-180" @click="index < orderedSOW.length ? moveSOW(sow._id,'up'): null" />
                    </div>
                    <div class="w-full flex flex-col flex-grow space-y-1 items-center truncate py-1 whitespace-normal min-h-max">
                        <TipTap v-if="editingNote.note && editingNote._id === sow._id" :currentContent="editingNote.note ? decodeHtml(editingNote.note) : null" :limitHeight="false" @content="setEditedNote($event)" 
                        @blur="!isWorking ? updateSOW(editingNote._id,'note',editedNote) : null"
                        />
                        <div v-else-if="sow.note" v-html="decodeHtml(sow.note)" class="w-full text-left px-2"></div>
                    </div>
                    <div v-if="allowEdit" class="w-auto px-1 py-2 flex flex-col space-y-2 items-end">
                        <button class="bn-icon-only" 
                        @click="editNoteId === sow._id ? editNoteId = null : editNoteId = sow._id"
                        :disabled="isWorking">
                            <XIcon v-if="editNoteId === sow._id" />
                            <PencilAltIcon v-else />
                        </button>
                        <!--<button v-if="editingNote.note && editingNote._id === sow._id" class="bn-icon-only text-green-500 hover:text-green-700"
                        :disabled="isWorking || (editingNote.note && editedNote === decodeHtml(editingNote.note)) || editedNote.replace(/<[^>]*>/g, '').trim().length === 0"
                        @click="updateSOW(editingNote._id,'note',editedNote)"
                        >
                            <SaveIcon />
                        </button>-->
                        <ButtonWithConfirm v-if="!editingNote.note && editingNote._id !== sow._id" @confirmed="deleteSOW(sow._id)" :disabled="isWorking" :iconComponent="TrashIcon" :popperContent="'Delete this item.'" :buttonClass="'bn-icon-only text-red-600 hover:text-red-800'" />
                    </div>
                </div>
                <div v-if="allowEdit" class="w-full flex flex-row items-center border-t flex-wrap truncate">
                    <div class="p-1">Last Edit:</div>
                    <div v-if="sow.lastEdit || sow.author" class="flex-grow text-left p-1">{{sow.lastEdit || sow.author}}</div>
                </div>
            </div>
            <div class="w-full flex flex-col p-1 text-red-500">
                <div class="w-full text-left whitespace-normal truncate p-1 font-semibold">
                    Please be sure to:
                </div>
                <li class="px-1 whitespace-normal">Update room status in DLSTracker.</li>
                <li class="px-1 whitespace-normal">Photograph and upload the photos for today.</li>
                <li class="px-1 whitespace-normal">Email an update to the project manager prior to leaving the site.</li>
            </div>
        </div>
    </div>
</template>

<script>
import {format,isValid,isSameDay,startOfDay,endOfDay,isWithinInterval, isAfter} from 'date-fns'
import api from "@/api"
import {decodeHtml,formatISODateforInput,dateInputToISO} from '@/shared'
import {ChevronUpIcon,PencilAltIcon,XIcon,TrashIcon} from '@heroicons/vue/solid'
import { computed, inject, ref, watchEffect } from 'vue'
import TipTap from '@/components/ui/TipTap.vue'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
export default {
    props:{
        projectId:{type:String,default:null},
        sow:{type:Array,default:()=>{return []}},
        allowEdit:{type:Boolean,default:false},
        date:{type:String,default:null}
    },
    emits:["triggerProjectUpdate"],
    components:{TipTap,ChevronUpIcon,PencilAltIcon,XIcon,ButtonWithConfirm},
    setup (props,{emit}) {

        const showModal = ref(null)
        const isWorking = ref(false)
        const responseError = ref(null)
        const errorId = ref(null)
        const editNoteId = ref(null)
        const editedNote = ref(null)

        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global

        const orderedSOW = computed(() => {
            let reverseSOW = [...props.sow].reverse();
            let updatedSOW = reverseSOW.map(sow => ({
                ...sow,
                startDate: formatISODateforInput(sow.startDate),
                endDate: formatISODateforInput(sow.endDate)
            }));
            return updatedSOW;
        });

        const editingNote = computed(()=>{
            return editNoteId.value && props.sow && Array.isArray(props.sow) ? props.sow.find(x=>x._id === editNoteId.value) : {}
        })

        watchEffect(()=>{
            const newValue = editingNote.value
            editedNote.value = newValue && newValue.note ? decodeHtml(newValue.note) : null
        })

        const currentSOWItems = computed(()=>{
            const currentItems = [];

            if (Array.isArray(props.sow) && props.date && isValid(dateInputToISO(props.date))) {
                const referenceDate = dateInputToISO(props.date)

                props.sow.forEach(d => {
                const startDate = isValid(new Date(d.startDate)) ? new Date(d.startDate) : null;
                const endDate = d.endDate && isValid(new Date(d.endDate)) ? new Date(d.endDate) : null;

                if (!startDate) {
                    return;
                }

                const dateStart = startOfDay(startDate);


                if (!endDate) {
                    if (isSameDay(referenceDate,dateStart) || isAfter(referenceDate,startDate)) {
                        currentItems.push(d._id);
                    }
                } else {
                    const dateEnd = endOfDay(endDate);

                    if (dateEnd >= dateStart && isWithinInterval(referenceDate, { start: dateStart, end: dateEnd })) {
                    currentItems.push(d._id);
                    }
                }
                });
            }

            return currentItems;
        })

        const updateSOW = async (id,key,value)=>{
            startApi()
            let body = {
                [key]:value
            }
            await api
            .put(`projects/projectSOW/${props.projectId}/${id}`,body)
            .then(()=>{
                triggerProjectUpdate()
                sendChangeEvent('sow','','',{projectId:props.projectId})
            })
            .catch((err)=>{
                errorId.value = id
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopApi()
            })
        }

        const deleteSOW = async (id)=>{
            startApi()
            await api
            .delete(`projects/projectSOW/${props.projectId}/${id}`)
            .then(()=>{
                triggerProjectUpdate()
                sendChangeEvent('sow','','',{projectId:props.projectId})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                errorId.value = id
            })
            .finally(()=>{
                stopApi()
            })
        }

        const moveSOW = async (id,direction) =>{
            startApi()
            await api
            .put(`projects/moveProjectSOW/${props.projectId}/${id}/${direction}`)
            .then(()=>{
                triggerProjectUpdate()
                sendChangeEvent('sow','','',{projectId:props.projectId})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                errorId.value = id
            })
            .finally(()=>{
                stopApi()
            })
        }

        const setEditedNote = (event) => {
            editedNote.value = event
        }

        const startApi = ()=>{
            isWorking.value = true
            responseError.value = null
            errorId.value = null
            setModalBlocked(true)
        }

        const stopApi = ()=>{
            isWorking.value = false
            setModalBlocked(false)
        }

        const triggerProjectUpdate = ()=>{
            emit("triggerProjectUpdate")
        }

        return {
            format,
            isValid,
            decodeHtml,
            orderedSOW,
            showModal,
            deleteSOW,
            updateSOW,
            dateInputToISO,
            moveSOW,
            isWorking,
            responseError,
            errorId,
            isSameDay,
            editNoteId,
            editedNote,
            setEditedNote,
            editingNote,
            TrashIcon,
            currentSOWItems
        }
    }

}
</script>

<style>

</style>