<template>
  <div class="flex items-center h-full w-screen top-0 p-12 justify-start fixed pin flex-col px-4" style="min-height: 568px">
    <div class="flex flex-row items-center justify-center pt-2 pb-6 space-x-2">
      <LockClosedIcon class="w-6 h-6" />
      <div class="text-2xl">Reset Password</div>
    </div>
    <div class="panel rounded-md w-full sm:w-1/2 max-w-screen-sm sm:min-w-screen-md p-4 flex flex-col space-y-4 shadow-lg">
      <div v-if="isLoading">
        <LoadingData />
      </div>
      <div v-if="successMessage" class="w-full sm:w-3/4 max-w-md min-w-max px-4 mx-auto mt-3 text-xl">
        {{ successMessage }}
      </div>
      <div v-if="responseError" class="error-response w-full sm:w-3/4 max-w-md min-w-max px-4 mx-auto">
        {{ responseError }}
      </div>
      <div class="flex flex-row justify-center w-full p-2">
          <password-form
            v-if="showForm"
            :msg="'Please choose a new password'"
            @valid="updatePassword"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "../router/index";
import axios from "axios";
import {LockClosedIcon} from "@heroicons/vue/outline"
import PasswordForm from "../components/PasswordForm";

let isLoading = ref(false);
let responseError = ref("");
const showForm = ref("");
const successMessage = ref("");

export default {
  name: "ResetPassword",
  components: {
    PasswordForm,
    LockClosedIcon
  },
  setup() {
    const route = useRoute();
    let resetToken = route.params.resetToken;

    onMounted(async () => {
      isLoading.value = true;
      await axios
        .put(`/api/v1/auth/resetpassword/${resetToken}`, {
          withCredentials: false,
        })
        .then((response) => {
          if (response.status == 200) {
            showForm.value = true;
          }
          isLoading.value = false;
        })
        .catch((err) => {
          isLoading.value = false;
          responseError.value = err.response.data.error || err.data.statusText;
        });
    });

    const updatePassword = async (pw) => {
      responseError.value = "";
      let body = { password: pw };
      await axios
        .put(`/api/v1/auth/resetpassword/${resetToken}`, body, {
          withCredentials: false,
        })
        .then((response) => {
          isLoading.value = false;
          showForm.value = false;
          successMessage.value = response.data.message || "Successful Response";
          setTimeout(() => {
            successMessage.value = "";
            sessionStorage.setItem("redirectTarget", "/");
            router.push({ name: "Login" });
          }, 3000);
        })
        .catch((err) => {
          isLoading.value = false;
          responseError.value = err.response.data.error || err.data.statusText;
        });
    };

    return {
      responseError,
      isLoading,
      showForm,
      updatePassword,
      successMessage,
    };
  },
};
</script>

<style>
</style>