// https://phosphoricons.com/

<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 640 512"
        fill="currentColor"
    >
        <path  
        opacity="1" 
        d="M64 104v88h96V96H72c-4.4 0-8 3.6-8 8zm482 88L465.1 96H384v96H546zm-226 0V96H224v96h96zM592 384H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V104C0 64.2 32.2 32 72 32H192 352 465.1c18.9 0 36.8 8.3 49 22.8L625 186.5c9.7 11.5 15 26.1 15 41.2V336c0 26.5-21.5 48-48 48zm-64 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM160 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
        stroke="#171717"
        />
    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>