<template>
    <div class="relative flex justify-center items-center w-14">
        <div class="text-center text-lg font-semibold">
            <slot name="icon" />
        </div>
        <span v-if="total" class="absolute right-0 bottom-0 transform translate-x-1/8 bg-red-700 text-white px-1  text-xs rounded-full truncate w-8 bg-opacity-80">
            <span class="max-w-xs truncate">{{total}}</span>
        </span>
    </div>
</template>

<script>
export default {
    props:{
        total:{type:Number,default:0},
    },
    setup () {
        return {}
    }

}
</script>

<style>

</style>