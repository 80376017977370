<template>
    <div v-if="!shipment.packingListId" class="error">No Packing List Available</div>
    <div v-else class="w-full flex flex-col flex-grow items-center p-1 overflow-auto">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
        <div v-if="!isWorking && loadingDocument" class="w-full">
            <LoadingData />
        </div>
        <div v-else-if="!isWorking && blobURL" class="w-full flex items-center justify-end px-2">
            <div class="px-1">
                <Popper hover arrow placement="top" :content="'Download'">
                <a :href="blobURL" :download="`${shipment.projectId?.companyProjectNumber || 'Packing list'}-${shipment.shipmentNumber || ''}.pdf`">
                    <button class="bn-icon-only">
                        <DownloadIcon />
                    </button>
                </a>
                </Popper>
            </div>
        </div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto items-center p-1">
            <div v-if="!isWorking && blobURL" class="w-full flex flex-col flex-grow items-center overflow-y-auto">
                    <iframe :src="blobURL" @load="loadingDocument = false" class="w-full h-full"/>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
import {DownloadIcon} from "@heroicons/vue/solid"
export default {
    props:{
        shipment:{type:Object,default:()=>{return {}}}
    },
    components:{DownloadIcon},
    setup (props) {
        let previousTitle
        const global = inject("global")
        const { setModalBlocked} = global;
        const isWorking = ref(false)
        const responseError = ref(null)
        const packingListBlob = ref(null)
        const blobURL = ref(null)
        const loadingDocument = ref(false)

        onMounted(()=>{
            previousTitle = document.title
            document.title = `Packing List: ${props.shipment?.projectId?.companyProjectNumber || ''} - ${props.shipment?.shipmentNumber || ''}`
            props.shipment.packingListId ? getPackingList() : null
        })

        onUnmounted(()=>{
            document.title = previousTitle
        })

        const getPackingList = async ()=>{
            responseError.value = null
            isWorking.value = true
            setModalBlocked(true)
            await api
            .get(`shipments/packingList/${props.shipment._id}`, { responseType: 'blob' })
            .then((res)=>{
                    let blob = new Blob([res.data],{type:'application/pdf'})
                    packingListBlob.value = blob
                    loadingDocument.value = true
                    blobURL.value = URL.createObjectURL(packingListBlob.value)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        return {
            isWorking,
            responseError,
            loadingDocument,
            blobURL
        }
    }
}
</script>

<style>

</style>