<template>
  <div class="h-full w-full flex flex-col space-y-2">
    <div v-if="photoFiles && photoFiles.length > 0" class="w-full flex-grow overflow-y-auto p-2 shadow-inner py-3" :style="'min-height:100px'">
      <div v-for="file in photoFiles" :key="file.id" class="max-w-3xl py-1">
        <photoCard :photo="file" :allowDelete="allowEdit" @viewFile="showImage($event)" @deleteFile="deleteImage($event)" />
      </div>
    </div>
    <div v-if="blobToView !== ''" :style="'min-height:400px'" class="flex p-3 flex-col border-t-2">
      <div class="w-full flex flex-row items-center text-xs max-w-xl">
        <div class="w-full flex flex-col space-y-1">
          <div class="text-xs px-1 truncate flex-grow text-left">{{fileToView.name}}</div>
          <div v-if="fileToView.room" class="text-xs px-1 truncate flex-grow w-full text-blue-600 text-left">{{fileToView.room}}</div>
        </div>
        <button class="bn-icon-small bn-solid">
          <XIcon class="h-4 w-4" @click="clearImage()" />
        </button>
      </div>
      <div class="flex flex-grow items-center justify-start overflow-auto p-2">
          <img :src="blobToView" alt="Image" class="object-contain max-h-full shadow-md border-2 p-1">
      </div>
    </div>
  </div>
</template>

<script>

import photoCard from "../../cards/photoCard.vue"
import {XIcon} from "@heroicons/vue/outline"

export default {
    props:{
        photoFiles:{type:Array,default:()=>{return []}},
        allowEdit:{type:Boolean,default:false}
    },
    components:{
      photoCard,
      XIcon
    },
    emits:["deleteFile"],
    data:()=>{
      return{
        blobToView:'',
        fileToView:''
      }
    },
    methods:{
      showImage: function(event) {
        this.fileToView = event
        this.blobToView = URL.createObjectURL(event.blob)
      },
      clearImage: function () {
        this.fileToView = ''
        this.blobToView = ''
      },
      deleteImage: function (id) {
        this.$emit("deleteFile",id)
      }
    }
}
</script>

<style>

</style>