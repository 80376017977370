<template>
    <div class="w-full z-20">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
    </div>
<!-- Packing List Files -->
    <div v-show="showPackingLists" class="w-full flex flex-grow flex-col items-center p-1">
        <div class="w-full flex flex-col flex-grow items-center max-w-2xl">
            <div class="w-full flex flex-row items-center">
                <div v-if="packingListsFiltered" class="w-full p-1 text-left whitespace-normal truncate">{{`${packingListsFiltered.length} files`}}</div>
                <div class="flex items-center justify-end p-1">
                    <Popper hover arrow placement="top" :content="'Back to Equipment Tracker'">
                        <button class="bn-icon-small bn-solid-sky" @click="setTrackingNumber()">
                            <ArrowSmLeftIcon />
                        </button>
                    </Popper>
                </div>
            </div>
            <div v-if="packingListsFiltered  && packingListsFiltered.length > 0" class="w-full flex flex-col flex-grow flex-auto h-0 items-center overflow-y-auto p-1">
                <div class="w-full flex flex-col flex-grow items-center overflow-y-auto">
                    <div v-for="file in packingListsFiltered " :key="file.id" class="w-full max-w-2xl p-1">
                        <div class="w-full flex flex-col p-1 border rounded-md">
                            <div class="w-full p-1 text-left whitespace-normal truncate">{{file.name}}</div>
                            <div class="w-full flex flex-row justify-between items-center truncate border-t">
                                <div v-if="file.createdTime && isValid(parseISO(file.createdTime))" class="w-auto truncate whitespace-normal px-1">
                                    {{format(parseISO(file.createdTime), 'MMM d, yyyy')}}
                                </div>
                                <div class="flex flex-row items-center justify-end px-1">
                                    <div v-if="file.mimeType && ['pdf','image'].some(x=>file.mimeType.includes(x))" class="p-1">
                                        <button class="bn-icon-only" @click="downloadPackingList(file.id,file.mimeType)">
                                            <EyeIcon />
                                        </button>
                                    </div>
                                    <div v-else class="p-1">
                                        <button class="bn-icon-only" @click="downloadUnviewableFile(file.id,file.mimeType,file.name)">
                                            <DownloadIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!showPackingLists" class="w-full  p-1 flex flex-row items-center z-10">
        <button class="bn-icon-only" @click="sortAscending = !sortAscending">
            <SortAscendingIcon v-if="sortAscending" />
            <SortDescendingIcon v-else />
        </button>
        <div class="p-1">
            <Popper hover arrow placement="top" :content="`Download ${equipmentFilter ? 'Filtered List' : 'All'} to Excel`">
                <button class="btn-icon-small bn-solid" @click="exportToExcel()" :disabled="isExporting"><DocumentDownloadIcon/></button>
            </Popper>
        </div>
        <div class="p-1">
            <Popper hover arrow placement="top" :content="'Packing Lists'">
                <button v-if="packingListsFiltered && packingListsFiltered.length > 0" class="bn-icon-small bn-solid" @click="setTrackingNumber()" :disabled="isWorking"><DocumentTextIcon /></button>
            </Popper>
        </div>
        <div class="w-full max-w-md p-1">
            <input v-model="equipmentFilter" type="search" class="w-full" placeholder="filter">
        </div>
    </div>
<!-- Equipment Tracker -->
    <div v-if="!showPackingLists" class="w-full flex flex-col flex-grow p-1 overflow-auto">
    <!-- Headings -->
            <div class="w-full flex flex-row sticky -top-1 rounded-none">
                <div class="tr-header-row w-10 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'boxNumber' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'boxNumber'">Box</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'manufacturer' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'manufacturer'">Manufacturer</div>
                <div class="tr-header-row w-20 justify-cente cursor-pointer hover:opacity-60" :class="sortBy === 'partNumber' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'partNumber'">Part #</div>
                <div class="tr-header-row w-8 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'quantity' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'quantity'">Qty.</div>
                <div class="tr-header-row w-48 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'description' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'description'">Description</div>
                <div class="tr-header-row w-24 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'createdAt' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'createdAt'">Date Rec'd</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'trackingNumber' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'trackingNumber'">Tracking #</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'shipDate' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'shipDate'">Shipped</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'serialNumber' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'serialNumber'">Serial #</div>
                <div class="tr-header-row w-48 justify-center  panel rounded-none">Add'l Serial #</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'macAddress' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'macAddress'">MAC</div>
                <div class="tr-header-row w-48 justify-center panel rounded-none">Add'l MACs</div>
                <div class="tr-header-row w-36 justify-center panel rounded-none">Rack Inst.</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'nameOne' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'nameOne'">Room Name 1</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'nameTwo' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'nameTwo'">Room Name 2</div>
                <div class="tr-header-row w-36 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'roomType' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'roomType'">Type</div>
                <div class="tr-header-row w-24 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'floor' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'floor'">Floor</div>
                <div class="tr-header-row w-12 justify-center cursor-pointer hover:opacity-60" :class="sortBy === 'phase' ? 'bg-sky-600' : 'panel rounded-none'" @click="sortBy = 'phase'">Phase</div>
                <div class="tr-header-row w-24 justify-center panel rounded-none">Color</div>
            </div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0" style="min-width:2361px">

    <!-- Body -->
            <div class="w-auto flex flex-col">
                <div v-for="(item,index) in groupedItems" :key="index" class="flex flex-row"
                :class="{'bg-red-500 bg-opacity-10' : hasShipped(item)}"
                >
                                <!--:class="hasShipped(item.shipmentId?.shipmentDate) || (item.rackId?.shipmentId?.shipmentDate && hasShipped(item.rackId?.shipmentId?.shipmentDate)) ? 'bg-red-500 bg-opacity-10' : null"-->

                    <div class="tr-body-cell w-10 justify-center"
                    :class="{'cursor-pointer hover:bg-sky-600 hover:bg-opacity-40' :!item.multiBox}"
                    @click="!item.multiBox ? showBoxId = item._id : null"
                    >
                        {{item.boxNumber}}
                    
                    </div>
                    <div class="tr-body-cell w-36 justify-left flex-wrap">
                        <div class="max-h-12 overflow-y-auto">{{item.alias?.manufacturer || item.manufacturer}}</div>
                    </div>
                    <div class="tr-body-cell w-20 justify-center">{{item.alias?.partNumber || item.partNumber}}</div>
                    <div class="tr-body-cell w-8 justify-center">{{item.quantity}}</div>
                    <div class="tr-body-cell w-48">
                        <div class="max-h-12 whitespace-normal overflow-y-auto">{{item.description}}</div>
                    </div>
                    <div class="tr-body-cell w-24 justify-center">{{item.createdAt && isValid(parseISO(item.createdAt)) ? format(parseISO(item.createdAt),'MM/dd/yyyy') : 'N/A'}}</div>
                    <div class="tr-body-cell w-36 justify-center flex-wrap">
                        <div class="max-h-12 text-center overflow-y-auto" 
                        @click="hasPackingList(item.trackingNumber) ? setTrackingNumber(item.trackingNumber) : null"
                        :class="{'text-sky-700 cursor-pointer underline hover:text-sky-600 hover:font-semibold':hasPackingList(item.trackingNumber)}"
                        >
                            {{item.trackingNumber}}
                        </div>
                    </div>
                    <div class="tr-body-cell w-36 justify-center">
                        {{
                            item.multiBox
                            ? null
                            : item.rackId?.shipmentId?.shipmentDate && isValid(parseISO(item.rackId?.shipmentId?.shipmentDate))
                                ? format(parseISO(item.rackId?.shipmentId?.shipmentDate), 'MM/dd/yyyy h:mm aaa')
                                : item.shipmentId?.shipmentDate && isValid(parseISO(item.shipmentId?.shipmentDate))
                                    ? format(parseISO(item.shipmentId?.shipmentDate), 'MM/dd/yyyy h:mm aaa')
                                    : null
                        }}
                    </div>
                    
                    <div class="tr-body-cell w-36 justify-left flex-wrap" :class="{'cursor-pointer hover:bg-sky-600 hover:bg-opacity-40' :!item.multiBox}"
                    @click="!item.multiBox ? showBoxId = item._id : null"
                    >
                        <div class="max-h-12 overflow-y-auto">{{!item.multiBox ? item.serialNumber : null}}</div>
                    </div>
                    <div class="tr-body-cell w-48 justify-left flex-wrap">
                        <div class="max-h-24 whitespace-pre-line overflow-y-auto">{{(!item.multiBox && item.serialNumbers && item.serialNumbers.length > 0) ? item.serialNumbers.map(s => `${s.name}: ${s.serialNumber}`).join('\n') : null}}</div>
                    </div>
                    <div class="tr-body-cell w-36 justify-left flex-wrap">
                        <div class="max-h-12 overflow-y-auto">{{(!item.multiBox && item.macAddress) ? item.macAddress : null}}</div>
                    </div>
                    <div class="tr-body-cell w-48 justify-left flex-wrap">
                        <div class="max-h-24 whitespace-pre-line overflow-y-auto">{{(!item.multiBox && item.macAddresses && item.macAddresses.length > 0) ? item.macAddresses.map(mac => `${mac.name}: ${mac.macAddress}`).join('\n') : null}}</div>
                    </div>
                    <div class="tr-body-cell w-36 justify-center whitespace-normal truncate" :class="!item.multiBox && item.rackId ? 'bg-sky-600' : null">{{(!item.multiBox && item.rackId) ? item.rackId?.name : null}}</div>
                    <div v-if="!item.multiBox && item.roomId" class="flex flex-row">
                        <div class="tr-body-cell w-36 justify-start">{{item.nameOne}}</div>
                        <div class="tr-body-cell w-36 justify-start">{{item.nameTwo}}</div>
                        <div class="tr-body-cell w-36 justify-start">{{item.roomType}}</div>
                        <div class="tr-body-cell w-24 justify-start">{{item.floor}}</div>
                        <div class="tr-body-cell w-12 justify-center">{{item.phase}}</div>
                        <div class="tr-body-cell w-24 justify-center">{{`${item.colorCodeOne?.abbreviation || ''} ${item.colorCodeTwo?.abbreviation || ''}`}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- Show File -->
    <Modal v-if="packingListBlob" :title="`Packing List: ${project.companyProjectNumber || ''}`"  @closeModal="closeFileModal()">
        <template v-slot:content>
            <div class="w-full flex flex-col flex-grow items-center p-1 overflow-auto">
                <WorkingAndError :isWorking="loadingDocument" :error="responseError" />
                <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto items-center p-1">
                    <div v-if="!loadingDocument && blobURL" class="w-full flex items-center justify-end px-2">
                        <div class="p-1">
                            <Popper hover arrow placement="top" :content="'Download'">
                                <a :href="blobURL" :download="`${fileName}.pdf`">
                                    <button class="bn-icon-only">
                                        <DownloadIcon />
                                    </button>
                                </a>
                            </Popper>
                        </div>
                    </div>
                    <div v-if="blobURL" class="w-full flex flex-col flex-grow items-center overflow-y-auto">
                        <iframe :src="blobURL" @load="loadingDocument = false" class="w-full h-full"/>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
    <Modal v-if="showBoxId" :title="'Serial & MAC'" @closeModal="showBoxId = null">
        <template v-slot:content>
            <SerialAndMacAddresses :equipmentId="showBoxId" @updateBox="updateBox($event)" :allowEdit="allowEdit" :external="external" :project="project" />
        </template>
    </Modal>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import api from "@/api"
import ExcelJs from 'exceljs'
import {parseISO,isValid,isPast,isToday,format} from 'date-fns'
import {ArrowSmLeftIcon,EyeIcon,DownloadIcon} from "@heroicons/vue/solid"
import {SortAscendingIcon,SortDescendingIcon,DocumentDownloadIcon,DocumentTextIcon} from "@heroicons/vue/outline"
import SerialAndMacAddresses from '@/components/equipment/SerialAndMacAddresses.vue'
export default {
    props:{
        project: {
            type:Object,
            default:()=>{return {}}
        },
        external:{type:Boolean,default:false}
    },
    components:{SerialAndMacAddresses,SortAscendingIcon,SortDescendingIcon,DocumentDownloadIcon,DocumentTextIcon,ArrowSmLeftIcon,EyeIcon,DownloadIcon},
    setup(props) {
        const global = inject("global")
        const {setModalBlocked,authenticated} = global
        const sortAscending = ref(true)
        const sortBy = ref('boxNumber')
        const isExporting = ref(false)
        const isWorking = ref(false)
        const loadingDocument = ref(false)
        const responseError = ref(null)
        const equipment = ref([])
        const equipmentFilter = ref(null)
        const packingListFiles = ref([])
        const showPackingLists = ref(false)
        const trackingNumber = ref(null)
        const packingListBlob = ref(null)
        const blobURL = ref(null)
        const fileName = ref('Packing List')
        const showBoxId = ref(null)
        const allowEdit = ref(authenticated.value.organization?.orgType && authenticated.value.organization.orgType.includes('primary'))

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            props.external ? document.title = `DLS - ${props.project?.clientProjectNumber || props.project?.clientProjectNumber || 'Equipment'}` : null
            getEquipment()
            getPackingLists()
        })

        onUnmounted(()=>{
            if(blobURL.value) {URL.revokeObjectURL(packingListBlob.value)}
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const packingListFilesArray = computed(()=>{
            let trackingNumberCounts = {};
            let filesWithTrackingNumber =  packingListFiles.value.filter(x=>x.properties?.trackingNumber !== null)
           
            filesWithTrackingNumber.forEach(file => {
                let trackingNumber = file.properties.trackingNumber;
                if (Object.prototype.hasOwnProperty.call(trackingNumberCounts, trackingNumber)) {
                    trackingNumberCounts[trackingNumber]++;
                } else {
                    trackingNumberCounts[trackingNumber] = 1;
                }
            });

            let trackingNumbersWithCounts = Object.keys(trackingNumberCounts).map(key => ({
                trackingNumber: key,
                count: trackingNumberCounts[key]
            }));

           return trackingNumbersWithCounts
        })

        const packingListsFiltered = computed(()=>{
            if(trackingNumber.value) {
                return packingListFiles.value.filter(x=>x.properties?.trackingNumber && x.properties.trackingNumber.toLowerCase() === trackingNumber.value.toLowerCase())
            } else {
                return packingListFiles.value
            }
        })

        const equipmentFiltered = computed(()=>{
            if(equipmentFilter.value) {
                const filter = equipmentFilter.value.toLowerCase()
                return equipment.value.filter(e=>
                    (e.manufacturer && e.manufacturer.toLowerCase().includes(filter)) ||
                    (e.partNumber && e.partNumber.toLowerCase().includes(filter)) ||
                    (e.description && e.description.toLowerCase().includes(filter) ) ||
                    (e.hasSerial && e.serialNumber && e.serialNumber.toLowerCase().includes(filter)) ||
                    (e.trackingNumber && e.trackingNumber.toLowerCase().includes(filter)) ||
                    (e.hasSerial && e.serialNumbers && e.serialNumbers.length > 0 && e.serialNumbers.some(s=>s['serialNumber'] && s['serialNumber'].includes(filter)))
                )
            } else {
                return equipment.value
            }
        })

        const hasPackingList = (trackingNo)=>{
            if(trackingNo) {
                return packingListFilesArray.value.some(item=>item.trackingNumber && item.trackingNumber.toLowerCase() === trackingNo.toLowerCase())
            } else {
                return false
            }
            
        }

        const getEquipment = async (type)=>{
            responseError.value = null
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }
            const route = props.external ? `external/equipment/${props.project?._id}/${props.project?.secureCode}` : `equipment/${props.project?._id}`
            await api
            .get(route)
            .then((res)=>{
                Array.isArray(res.data.data)
                ? equipment.value = res.data.data.sort((a, b) => a.boxNumber-b.boxNumber)
                : null
            })
            .catch((err)=>{
                type !== 'silent' 
                ? responseError.value = err.response?.data?.error || err.message
                : console.error(responseError.value = err.response?.data?.error || err.message)
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const updateBox = (event)=>{
            let boxIndex = equipment.value.findIndex(x=>x._id === event._id)
            if(boxIndex > -1) {
                equipment.value[boxIndex] = event
            }
        }

        const getPackingLists = async(type)=>{
            responseError.value = null
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }

            const route = props.external ? `external/equipment/packingLists/${props.project?._id}/${props.project?.secureCode}` : `equipment/packingLists/${props.project?._id}`


            await api
            .get(route)
            .then((res)=>{
                if(Array.isArray(res.data?.data?.files)) {
                    packingListFiles.value = res.data.data.files
                }
            })
            .catch((err)=>{
                type !== 'silent' 
                ? responseError.value = err.response?.data?.error || err.message
                : console.error(responseError.value = err.response?.data?.error || err.message)
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const downloadPackingList = async(fileId,mimeType,name)=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            const route = props.external ? `external/equipment/packingLists/download/${props.project?._id}/${props.project?.secureCode}/${fileId}` : `equipment/packingLists/download/${props.project._id}/${fileId}`
            await api
            .get(route,{ responseType: 'blob' })
            .then((res)=>{
                    fileName.value = name || 'Packing List'
                    let blob = new Blob([res.data],{type:mimeType})
                    packingListBlob.value = blob
                    loadingDocument.value = true
                    blobURL.value = URL.createObjectURL(packingListBlob.value)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const downloadUnviewableFile = async(fileId,mimeType,name)=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            const route = props.external ? `external/equipment/packingLists/download/${props.project?._id}/${props.project?.secureCode}/${fileId}` : `equipment/packingLists/download/${props.project._id}/${fileId}`
            await api
            .get(route,{ responseType: 'blob' })
            .then((res)=>{
                    let blob = new Blob([res.data],{type:mimeType})
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}`;
                    a.target = '_blank';
                    a.click();
                    window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const closeFileModal = ()=>{
            if(blobURL.value) {URL.revokeObjectURL(packingListBlob.value)}
            packingListBlob.value = null
            fileName.value = 'Packing List'
        }

        const setTrackingNumber = (trackingNo)=>{
            if(trackingNo) {
                let fileIndex = packingListFilesArray.value.findIndex(x=>x.trackingNumber.toLowerCase() === trackingNo.toLowerCase())
                if(fileIndex > -1) {
                    if(packingListFilesArray.value[fileIndex].count > 1) {
                        trackingNumber.value = trackingNo
                        showPackingLists.value = !showPackingLists.value
                    } else {
                        trackingNumber.value = trackingNo
                        let listIndex = packingListFiles.value.findIndex(x=>x.properties?.trackingNumber && x.properties?.trackingNumber.toLowerCase() === trackingNo.toLowerCase() )
                        if(listIndex > -1) {
                            if(['pdf','image'].some(x=>packingListFiles.value[listIndex].mimeType.includes(x))) {
                                downloadPackingList(packingListFiles.value[listIndex].id,packingListFiles.value[listIndex].mimeType,packingListFiles.value[listIndex].name)
                            } else {
                                downloadUnviewableFile(packingListFiles.value[listIndex].id,packingListFiles.value[listIndex].mimeType,packingListFiles.value[listIndex].name)
                            }
                        }
                    }
                }
            } else {
                trackingNumber.value = null
                showPackingLists.value = !showPackingLists.value
            }
        }
        
        const groupedItems = computed(() => {
            let multiBoxSummary = {};

            // Process all items in a single loop.
            const allItems = equipmentFiltered.value.map(equipment => {
                if (!equipment.multiBox) {
                    return {...equipment, quantity: 1, shipDate: equipment.shipmentId?.shipmentDate || null};
                } else {
                    const { boxNumber } = equipment;
                    if (!multiBoxSummary[boxNumber]) {
                        multiBoxSummary[boxNumber] = { 
                            quantity: 0, 
                            boxNumber: equipment.boxNumber,
                            manufacturer: equipment.manufacturer,
                            partNumber: equipment.partNumber,
                            description: equipment.description,
                            createdAt: equipment.createdAt,
                            trackingNumber: equipment.trackingNumber,
                            multiBox: true // Keep track for later differentiation.
                        };
                    }
                    multiBoxSummary[boxNumber].quantity += 1;
                    return null; // Placeholder for multi-box equipment, to be filtered out later.
                }
            }).filter(equipment => equipment !== null); // Filter out nulls from multi-boxes.

            const multiBoxSummaryArray = Object.values(multiBoxSummary);
            const combinedItems = [...allItems, ...multiBoxSummaryArray];

            // Apply modifications and sorting in a single step.
            return combinedItems.map(equipment => {
                if (!equipment.multiBox && equipment.roomId) {
                    const { _id: roomId, ...restOfRoomId } = equipment.roomId
                    return {
                        ...equipment,
                        roomId,
                        ...restOfRoomId
                        };
                } else {
                    return {
                        ...equipment,
                        nameOne: null,
                        nameTwo: null,
                        phase: null,
                        floor: null,
                        roomType: null,
                        colorCodeOne: null,
                        colorCodeTwo: null
                    };
                }
            })
            .sort((a, b) => {
                let aValue = a[sortBy.value];
                let bValue = b[sortBy.value];

                // Simplify the comparison, handling undefined or null values.
                if (aValue === undefined || aValue === null) return sortAscending.value ? 1 : -1;
                if (bValue === undefined || bValue === null) return sortAscending.value ? -1 : 1;


                // Actual comparison
                return sortAscending.value 
                ? (aValue < bValue ? -1 : aValue > bValue ? 1 : 0) 
                : (aValue > bValue ? -1 : aValue < bValue ? 1 : 0);
            });
        });

        const exportToExcel = async ()=>{
            isExporting.value = true
            const headers = ["Box","Manufacturer","Part #","Description","Qty","Date Received","Tracking #","Shipped","Serial #","Add'l Serial","MAC","Add'l MAC","Rack Inst.","Room Name 1","Room Name 2","Type","Floor","Phase","Color"]
            
            const workbook = new ExcelJs.Workbook()
            const worksheet = workbook.addWorksheet('Sheet1');

            const projectHeaderRow = worksheet.addRow()
            for (let i = 1; i <= 19; i++) {
                const cell = projectHeaderRow.getCell(i);
                cell.value = ""; // Set cell value to empty for all columns
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "000000" }, // Black background color
                };
                cell.font = {bold:true, color: { argb: "FFFFFF" } }; // White text color
                cell.alignment = { vertical: 'middle', horizontal: 'center' }
            }

            projectHeaderRow.getCell(1).value = "DLS"
            projectHeaderRow.getCell(2).value = "Client"
            projectHeaderRow.getCell(3).value = "Project Name"
            projectHeaderRow.getCell(4).value = "Project Manager"
            projectHeaderRow.getCell(6).value = "Project Address"
            projectHeaderRow.getCell(9).value = "Client ID"
            projectHeaderRow.getCell(11).value = "DLS ID"

            const projectInfoRow = worksheet.addRow()
            for (let i = 1; i <= 19; i++) {
                const cell = projectInfoRow.getCell(i);
                cell.value = ""; // Set cell value to empty for all columns
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "000000" }, // Black background color
                };
                cell.font = {bold:true, color: { argb: "FFFFFF" } }; // White text color
                cell.alignment = { vertical: 'middle', horizontal: 'center' }
            }

            const workSheetProjectAddress = `${props.project?.projectAddress?.street_number || ''} ${props.project?.projectAddress?.street || ''} ${props.project?.projectAddress?.city || ''} ${props.project?.projectAddress?.state || ''} ${props.project?.projectAddress?.postalCode || ''}`

            projectInfoRow.getCell(2).value = props.project?.projectClientId?.name || ''
            projectInfoRow.getCell(3).value = props.project?.name || ''
            projectInfoRow.getCell(4).value = props.project?.clientMgId?.name || ''
            projectInfoRow.getCell(6).value = workSheetProjectAddress || ''
            projectInfoRow.getCell(9).value = props.project?.clientProjectNumber || ''
            projectInfoRow.getCell(11).value = props.project?.companyProjectNumber || ''

            const headerRow = worksheet.addRow(headers);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true };
                cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center text vertically and horizontally
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'F3F4F6' }, // Light gray background color
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });

            for (let i = headers.length - 6; i < headers.length; i++) {
                headerRow.getCell(i + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'B4C6E7' }, // Light blue background color
                };
            }

            // Adjust column widths to auto
            worksheet.columns = headers.map(() => ({ width: 15 }));

            const data = groupedItems.value.map((item)=>[
                    item.boxNumber,
                    item.alias?.manufacturer || item.manufacturer,
                    item.alias?.partNumber || item.partNumber,
                    item.description,
                    item.quantity,
                    item.createdAt && isValid(parseISO(item.createdAt)) ? format(parseISO(item.createdAt),'MM/dd/yyyy'): 'N/A',
                    item.trackingNumber,
                    item.multiBox
                    ? null
                    : item.rackId?.shipmentId?.shipmentDate && isValid(parseISO(item.rackId?.shipmentId?.shipmentDate))
                        ? format(parseISO(item.rackId?.shipmentId?.shipmentDate), 'MM/dd/yyyy h:mm aaa')
                        : item.shipmentId?.shipmentDate && isValid(parseISO(item.shipmentId?.shipmentDate))
                            ? format(parseISO(item.shipmentId?.shipmentDate), 'MM/dd/yyyy h:mm aaa')
                            : null,
                    (!item.multiBox && item.hasSerial && item.serialNumber) ? item.serialNumber : null,
                    (!item.multiBox && item.hasSerial && item.serialNumbers && item.serialNumbers.length > 0) ? item.serialNumbers.map(item => `${item.name}: ${item.serialNumber}`).join('\n\n') : null,
                    (!item.multiBox && item.hasMacAddress && item.macAddress) ? item.macAddress : null,
                    (!item.multiBox && item.hasMacAddress && item.macAddresses && item.macAddresses.length > 0) ? item.macAddresses.map(item => `${item.name}: ${item.macAddress}`).join('\n\n') : null,
                    (!item.multiBox && item.rackId) ? item.rackId?.name : null,
                    (!item.multiBox && item.roomId ) ? item.nameOne : null,
                    (!item.multiBox && item.roomId ) ? item.nameTwo : null,
                    (!item.multiBox && item.roomId ) ? item.roomType : null,
                    (!item.multiBox && item.roomId ) ? item.floor : null,
                    (!item.multiBox && item.roomId ) ? item.phase : null,
                    `${item.colorCodeOne?.abbreviation || ''} ${item.colorCodeTwo?.abbreviation || ''}`,
                    hasShipped(item)
            ])

            data.forEach((rowData) => {
                let colorRed = rowData[19]
                rowData.pop()
                const row = worksheet.addRow(rowData)

                // Set column widths for all columns to 15
                worksheet.columns.forEach((column) => {
                    column.width = 15;
                });

                // Enable text wrapping for all cells in the row
                row.eachCell((cell) => {
                    if (colorRed) {cell.font = { color: { argb: 'FFFF0000' } }}
                    cell.alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
                });
            });

            worksheet.columns[0].width = 8;
            worksheet.columns[4].width = 8;
            worksheet.columns[3].width = 30
            worksheet.mergeCells('A1:A2')
            worksheet.mergeCells('F1:H1')
            worksheet.mergeCells('F2:H2')
            worksheet.views = [
            {
                state: 'frozen',
                xSplit: 4, // Number of columns to freeze (0 means none)
                ySplit: 3, // Number of rows to freeze (1 means the first row)
            },
            ];

            const fileName = `${props.project?.name || 'equipment'} - DLS # ${props.external ? (props.project?.clientProjectNumber || props.project?.companyProjectNumber)  : props.project?.companyProjectNumber || 'N/A'}.xlsx`

            const buffer = await workbook.xlsx.writeBuffer();

            // Create a Blob and open it in a new tab
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}`;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);

            isExporting.value = false
        }

        const checkShipmentDate = (shipDate) => {
            return shipDate && isValid(parseISO(shipDate)) && (isToday(parseISO(shipDate)) || isPast(parseISO(shipDate)));
        };

        const hasShipped = (item)=>{

            if(item.multiBox) {
                let allItems = equipment.value.filter(x=>x.boxNumber === item.boxNumber)
                return allItems.every(x=>{
                    let shipDate = x.rackId?.shipmentId?.shipmentDate || x.shipmentId?.shipmentDate;
                    return checkShipmentDate(shipDate);
                })
            } else if(item.rackId?.shipmentId?.shipmentDate) {
                let shipDate = item.rackId?.shipmentId?.shipmentDate
                return checkShipmentDate(shipDate)
            } else if (item.shipmentId?.shipmentDate) {
                let shipDate = item.shipmentId?.shipmentDate
                return checkShipmentDate(shipDate)
            } else {
                return false
            }
            //return shipDate && isValid(parseISO(shipDate)) && (isToday(parseISO(shipDate)) || isPast(parseISO(shipDate)))
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'equipment':
                    case 'new_equipment':
                        if(e.detail?.data?.projectId === props.project._id) { 
                            getEquipment('silent')
                        }
                        break;
                    case 'receiving_list':
                        if(e.detail?.data?.projectId === props.project._id) { 
                            getPackingLists('silent')
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            groupedItems,
            sortBy,
            sortAscending,
            isExporting,
            exportToExcel,
            isWorking,
            responseError,
            parseISO,
            isValid,
            isPast,
            isToday,
            format,
            hasShipped,
            packingListsFiltered ,
            hasPackingList,
            showPackingLists,
            setTrackingNumber,
            downloadPackingList,
            loadingDocument,
            packingListBlob,
            blobURL,
            closeFileModal,
            downloadUnviewableFile,
            fileName,
            equipmentFilter,
            showBoxId,
            allowEdit,
            updateBox
        };
    },

}
</script>

<style>

</style>