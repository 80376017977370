<template>
  <div class="w-full flex flex-col flex-grow items-center max-w-4xl panel">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- New Item -->
    <div v-if="allowEdit" class="w-full flex flex-col space-y-1 p-1">
        <div class="w-full flex p-1">
            <button class="px-2 py-0.5 bn-solid-green" @click="toggleNewItemDialog()" :disabled="isWorking || !allowEdit">
                <XIcon v-if="newItemDialog " />
                <PlusIcon v-else />
                <div>Site Contact</div>
            </button>
        </div>
        <div v-if="newItemDialog" class="w-full flex flex-col space-y-1 p-2 items-center">
            <div class="w-full flex p-1 max-w-3xl flex-wrap">
                <div class="w-full p-1 pb-2 flex border-b flex-row truncate">
                    <div class="w-full text-left truncate">New Site Contact</div>
                    <button class="bn-icon-small bn-solid-green" :disabled="isWorking || !newItem.name" @click="saveNewContact()">
                        <SaveIcon />
                    </button>
                </div>
                <div class="w-56 flex flex-row space-x-1 items-center p-1 flex-shrink-0">
                    <IdentificationIcon class="h-4 w-4 flex-shrink-0" />
                    <input v-model.trim="newItem.name" type="text" placeholder="name" maxlength="50" class="w-full">
                </div>
                <div class="w-56 flex-shrink-0 p-1">
                    <EmailInput :placeholder="'email*'" @emailInput="newItem.email=$event.userEmail" />
                </div>
                <div class="w-56 flex-shrink-0  p-1 flex flex-row space-x-1 items-center">
                    <PhoneIcon class="h-4 w-4 flex-shrink-0" />
                    <input v-model.trim="newItem.phone" type="text" placeholder="phone" class="w-full"
                    @input="handlePhoneInput($event)"
                    >
                </div>
                <div class="w-full p-1 flex flex-row space-x-1 items-center">
                    <DocumentIcon class="h-4 w-4 flex-shrink-0" />
                    <input v-model.trim="newItem.note" type="text" placeholder="note" maxlength="50" class="w-full">
                </div>
            </div>
        </div>
    </div>
<!-- Show Items -->
    <div v-if="localProject.siteContacts || localProject.siteContacts.length > 1" class="w-full text-left py-1 truncate px-4">{{`${localProject.siteContacts.length} contacts`}}</div>
    <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-2">
        <div class="w-full flex flex-col space-y-2 flex-grow overflow-y-auto">
            <div v-if="!isWorking && (!localProject.siteContacts || localProject.siteContacts.length === 0)">
                <div class="w-full text-center truncate">No Site Contacts</div>
            </div>
            <div v-else class="w-full p-1 flex flex-row flex-wrap justify-start">
                <div v-for="item in localProject.siteContacts" :key="item._id" class="w-96 flex-shrink-0 truncate p-1">
                    <div class="w-full flex-shrink-0 flex flex-col space-y-1 p-2 border rounded-md shadow-sm">
                        <div class="w-full flex flex-row space-x-2 items-center">
                            <input v-model.trim="item.name" type="text"  maxlength="50" placeholder="name" class="w-full" :disabled="isWorking || !allowEdit"
                            @focus="prevValue = item.name" @blur="item.name !== prevValue ? updateContact(item._id,'name',item.name || null,prevValue) : null"
                            >
                            <DeleteConfirm v-if="allowEdit" :size="'small'" @confirmedDelete="deleteContact(item._id)" />
                        </div>
                        <input v-model.trim="item.email" type="text"  maxlength="100" placeholder="email" :disabled="isWorking || !allowEdit"
                        @focus="prevValue = item.email" @blur="item.email !== prevValue ? updateContact(item._id,'email',item.email || null,prevValue) : null"
                        >
                        <input v-model.trim="item.phone" type="text" placeholder="phone" :disabled="isWorking || !allowEdit"
                        @focus="prevValue = item.phone" @blur="item.phone !== prevValue ? updateContact(item._id,'phone',item.phone|| null,prevValue) : null"
                        @input="handlePhoneInput($event)"
                        >
                        <textarea v-model.trim="item.note" type="text" class="whitespace-normal resize-none overflow-y-auto"  maxlength="50" placeholder="note" :disabled="isWorking || !allowEdit"
                        @focus="prevValue = item.note" @blur="item.note !== prevValue ? updateContact(item._id,'note',item.note|| null,prevValue) : null"
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
    import { ref,inject,watchEffect } from 'vue'
    import api from "@/api"
    import {formatUSPhoneNumber} from "@/shared"
    import {PlusIcon,XIcon,PhoneIcon,IdentificationIcon,DocumentIcon,SaveIcon} from "@heroicons/vue/solid"
    import EmailInput from "@/components/ui/EmailInput.vue";
    export default {
        components: {EmailInput,PlusIcon,XIcon,PhoneIcon,IdentificationIcon,DocumentIcon,SaveIcon},
        props:{
            project:{type:Object,default:()=>{return {}}},
            allowEdit:{type:Boolean,default:false}
        },
        emits:["triggerProjectUpdate"],
        setup (props,{emit}) {
            const isWorking = ref(false)
            const responseError = ref(null)
            const global = inject('global')
            const {setModalBlocked,sendChangeEvent} = global

            const newItemDialog = ref(false)
            const newItemInitial = {
                name:null,
                note:null,
                phone:null,
                email:null
            }
            const newItem = ref({...newItemInitial})
            const localProject = ref(props.project)

            watchEffect(() => {
                localProject.value = props.project;
            });

            const toggleNewItemDialog = ()=>{
                newItemDialog.value = !newItemDialog.value
                newItem.value = {...newItemInitial}
            }

            const handlePhoneInput = (event)=>{
                event.target?.value ? event.target.value = formatUSPhoneNumber(event.target.value) : null
            }

            const saveNewContact = async ()=>{
                startAPI()
                responseError.value = null
                await api
                .post("projects/siteContacts/" + props.project._id,newItem.value)
                .then(()=>{
                    emit("triggerProjectUpdate")
                    sendChangeEvent('siteContact',props.project._id,'',{projectId:props.project._id})
                    toggleNewItemDialog()
                })
                .catch((err)=>{
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            }

            const deleteContact = async (id)=>{
                startAPI()
                await api
                .delete(`projects/siteContacts/${props.project._id}/${id}`)
                .then(()=>{
                    emit("triggerProjectUpdate")
                    sendChangeEvent('siteContact',props.project._id,'',{projectId:props.project._id})
                })
                .catch((err)=>{
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            }

            const updateContact = async (id,key,value,prevValue)=>{
                startAPI()
                let body = {[key]:value}
                await api
                .put(`projects/siteContacts/${props.project._id}/${id}`,body)
                .then(()=>{
                    emit("triggerProjectUpdate")
                    sendChangeEvent('siteContact',props.project._id,'',{projectId:props.project._id})
                })
                .catch((err)=>{
                    let itemIndex = localProject.value.siteContacts.findIndex(x=>x._id === id)
                    itemIndex >=0 ? localProject.value.siteContacts[itemIndex][key] = prevValue : null
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            }


            const startAPI = ()=>{
                setModalBlocked(true)
                isWorking.value = true
                responseError.value = null
            }

            const stopAPI = ()=>{
                setModalBlocked(false)
                isWorking.value = false
            }

            return  {
                isWorking,
                responseError,
                newItemDialog,
                toggleNewItemDialog,
                newItem,
                saveNewContact,
                localProject,
                deleteContact,
                updateContact,
                handlePhoneInput
            }
        }
    }
</script>
