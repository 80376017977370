<template>
  <button class="px-2 py-1 dark:bn-solid">
    <div class="px-1">
        <CheckIcon class="flex-shrink-0 text-green-700  cursor-pointer hover:opacity-40" @click="sendResponse(true)"/>
    </div>
    <div class="px-1">
        <XIcon class="flex-shrink-0 text-red-500 cursor-pointer hover:opacity-40" @click="sendResponse(false)" />
    </div>
  </button>
</template>

<script>
import {CheckIcon,XIcon} from "@heroicons/vue/outline"
export default {
    components:{CheckIcon,XIcon},
    emits:["response"],
    setup (_,{emit}){

        const sendResponse = (val)=>{
            emit("response",val)
        }

        return {sendResponse}
    }
}
</script>

<style>

</style>