<template>
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  height="24" 
  viewBox="0 -960 960 960" 
  width="24"
  fill="currentColor"
  >
  <path d="M120-120v-80h80v-560q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v560h80v80H120Zm560-80v-560H280v560h400ZM560-440q17 0 28.5-11.5T600-480q0-17-11.5-28.5T560-520q-17 0-28.5 11.5T520-480q0 17 11.5 28.5T560-440ZM280-760v560-560Z"/>
  </svg>
</template>

<script>
export default {

}
</script>
