<template>
  <div class="w-full flex flex-col flex-grow items-center">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- New Report -->
    <div v-if="allowEdit" class="w-full flex flex-col border-b max-w-5xl">
        <div class="w-full font-semibold px-1">
            <div class="text-left whitespace-normal truncate">
                New Report:
            </div>
        </div>
        <div class="w-full flex flex-row items-center truncate p-1">
            <div class="w-auto flex flex-row items-center flex-wrap">
                <div class="w-full md:w-44 p-1">
                    <input v-model="reportDate" type="date" class="w-full" />
                </div>
                <div class="w-full md:w-44 p-1">
                    <select v-model.trim="reportType " class="w-full">
                        <option value="daily">Daily</option>
                        <option value="rack">Rack</option>
                        <option value="service">Service</option>
                    </select>
                </div>
            </div>
            <div class="flex items-center py-1 px-2">
                <button class="bn-icon-small bn-solid-green" @click="createNewReport()">
                    <SaveIcon />
                </button>
            </div>
        </div>
    </div>
<!-- Reports -->
    <div class="w-full flex flex-col flex-grow flex-auto h-0 p-1 overflow-y-auto items-center">
        <div class="w-full flex flex-col space-y-1 flex-grow items-center overflow-y-auto max-w-3xl">
            <div v-for="report in reports" :key="report._id" class="w-full flex flex-col" :class="{'p-1 border-b':allowEdit || report.isSent}">
                <div v-if="allowEdit || report.isSent" class="w-full flex flex-row items-center space-x-1 flex-nowrap truncate">
                    <div class="w-auto flex flex-row flex-grow items-center truncate p-1 flex-wrap">
                        <div class="w-28 text-left p-1">{{isValid(parseISO(report.reportDate)) ? format(parseISO(report.reportDate), 'MMM d, yyyy') : 'Invalid Date'}}</div>
                        <div class="w-auto flex-grow font-semibold text-left p-1">{{capitalizeFirstLetter(report.reportType) + ` Report`}}</div>
                        <div class="w-auto text-left whitespace-normal p-1">{{report.lastEdit || report.author}}</div>
                    </div>
                    <div class="w-auto flex flex-row items-center">
                        <div v-if="report.isComplete" class="p-1 flex items-center">
                            <CheckCircleIcon class="h-4 w-4 text-green-700" />
                        </div>
                        <div v-if="!report.isSent && allowEdit" class="p-1 flex items-center">
                            <Popper hover arrow placement="top" :content="'Edit Report'">
                                <button class="bn-icon-only" @click="activeReportId = report._id" :disabled="reportsSending.includes(report._id)">
                                    <PencilAltIcon />
                                </button>
                            </Popper>
                        </div>
                        <div v-if="report.isComplete" class="p-1 flex items-center">
                            <Popper hover arrow placement="top" :content="report.isSent ?'Report Sent' : 'Send Report'">
                                <button class="bn-icon-only" :disabled="report.isSent || reportsSending.includes(report._id) || !allowEdit" :class="{'text-green-500':report.isSent}"
                                @click="sendReport(report._id)"
                                >
                                    <MailIconSolid v-if="report.isSent" />
                                    <RefreshIcon v-else-if="reportsSending.includes(report._id)" class="animate-spin-slow" />
                                    <MailIcon v-else />
                                </button>
                            </Popper>
                        </div>
                        <div v-if="report.googleId && report.isSent" class="p-1 flex items-center">
                            <button class="bn-icon-only" @click="downloadReport(report._id,report)" :disabled="reportsDownloading.includes(report._id)">
                                <RefreshIcon v-if="reportsDownloading.includes(report._id)" class="animate-spin-slow" />
                                <DownloadIcon v-else />
                            </button>
                        </div>
                        <div v-if="report.googleId && report.isSent" class="p-1 flex items-center">
                            <button class="bn-icon-only" @click="openReport(report._id)" :disabled="reportsOpening.includes(report._id)">
                                <RefreshIcon v-if="reportsOpening.includes(report._id)" class="animate-spin-slow" />
                                <ExternalLinkIcon v-else />
                            </button>
                        </div>
                        <div v-if="!report.isSent && allowEdit" class="p-1 flex items-center">
                            <ButtonWithConfirm :iconComponent="TrashIcon" :popperContent="'Delete Report'" @confirmed="deleteReport(report._id)" :disabled="isWorking || reportsSending.includes(report._id)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
<!-- Report -->
  <Modal v-if="activeReportId && activeReport" :title="capitalizeFirstLetter(activeReport.reportType) + ` Report ` + ' - ' + project.companyProjectNumber " @closeModal="activeReportId = null">
    <template v-slot:content>
        <Report :project="project" :report="activeReport" @reportUpdate="updateReport($event)" />
    </template>
  </Modal>
</template>

<script>
import {computed, inject, onMounted, onUnmounted, ref } from 'vue'
import {SaveIcon} from '@heroicons/vue/outline'
import api from '@/api'
import {formatISODateforInput,dateInputToISO,capitalizeFirstLetter} from '@/shared'
import {format,isValid,parseISO} from 'date-fns'
import {PencilAltIcon, MailIcon,ExternalLinkIcon,TrashIcon,RefreshIcon,DownloadIcon} from '@heroicons/vue/outline'
import {CheckCircleIcon,MailIcon as MailIconSolid} from '@heroicons/vue/solid'
import Report from '@/components/projects/reports/Report.vue'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false},
    },
    components:{Report,ButtonWithConfirm,SaveIcon,PencilAltIcon,MailIcon,ExternalLinkIcon,CheckCircleIcon,MailIconSolid,RefreshIcon,DownloadIcon},
    setup (props) {
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const reports = ref([])
        const noReportsReturned = ref(false)
        const reportDate = ref(formatISODateforInput(new Date().toISOString()))
        const reportType = ref('daily')
        const activeReportId = ref(null)
        const reportsSending =ref([])
        const reportsOpening =ref([])
        const reportsDownloading =ref([])

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getReports()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const activeReport = computed(()=>{
            if(activeReportId.value) {
                let reportToShow = reports.value.find(report=>report._id === activeReportId.value)
                return reportToShow ? reportToShow : null
            } else {
                return null
            }
        })

        const proccessing = computed(()=>{
            return [reportsSending.value,reportsDownloading.value,reportsOpening.value].every(arg=>Array.isArray(arg) && arg.length === 0)
        })

        const getReports = async (type)=>{
            startAPI(type)
            await api
            .get("reports/projectReports/" + props.project._id)
            .then((res)=>{
                if(Array.isArray(res.data.data)) {
                    reports.value = res.data.data
                    noReportsReturned.value = reports.value.length === 0 ?  true : false
                }
            })
            .catch((err)=>{
                err.response?.data?.error && err.response?.data?.error?.includes("DOCTYPE") 
                ? responseError.value.reports = 'Could not retrieve reports from Google.'
                : responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const getReportById = async (id)=>{
            await api
            .get(`reports/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let index = reports.value.findIndex(x=>x._id === id)
                    if(index > -1) {
                        reports.value[index] = res.data.data
                    } else {
                        reports.value.push(res.data.data)
                        sortReports()
                    }
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const createNewReport = async ()=>{
            startAPI()
            let body = {
                reportDate:dateInputToISO(reportDate.value),
                reportType: reportType.value
            }
            await api
            .post(`reports/create/${props.project?._id}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    reports.value.push(res.data.data)
                    sortReports()
                }
                sendChangeEvent('new_report','','',{projectId:props.project?._id,reportDate:res.data?.data?.reportDate,reportId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateReport = (event)=>{
            let reportIndex = reports.value.findIndex(report=>report._id === event._id)
            reportIndex > -1
            ? reports.value[reportIndex] = {...event}
            : null
            
            sendChangeEvent('report','','',{projectId:props.project?._id,reportDate:event?.reportDate,reportId:event?._id})
        }

        const deleteReport = async (id)=>{
            startAPI()
            await api
            .delete(`reports/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let index = reports.value.findIndex(x=>x._id === res.data.data._id)
                    if(index > -1) {
                        reports.value.splice(index,1)
                    }
                    sendChangeEvent('delete_report','','',{projectId:props.project?._id,reportDate:res.data?.data?.reportDate,reportId:res.data?.data?._id})
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const sendReport = async (id)=>{
            setModalBlocked(true)
            responseError.value = null
            reportsSending.value.push(id)
            await api
            .get(`reports/send/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    updateReport(res.data.data)
                } else {
                    responseError.value = 'Bad report data returned.'
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                let index = reportsSending.value.findIndex(x=>x===id)
                if(index > -1) {
                    reportsSending.value.splice(index,1)
                }
                proccessing.value ? setModalBlocked(false): null
            })
        }

        const openReport = async (id)=>{
            setModalBlocked(true)
            reportsOpening.value.push(id)
            await api
            .get(`reports/download/${id}`,{
                responseType:'blob'
            })
            .then((response)=>{
                let blob = new Blob([response.data],{type:'application/pdf'})
                let blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');

                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl);
                }, 100);
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
                responseError.value = 'Failed to open report.'
            })
            .finally(()=>{
                let index = reportsOpening.value.findIndex(x=>x===id)
                if(index > -1) {
                    reportsOpening.value.splice(index,1)
                }
                proccessing.value ? setModalBlocked(false): null
            })
        }

        const downloadReport = async (id)=>{
            setModalBlocked(true)
            reportsDownloading.value.push(id)
            await api
            .get(`reports/download/${id}`,{
                responseType:'blob'
            })
            .then((response)=>{
                let blob = new Blob([response.data],{type:'application/pdf'})
                let link = document.createElement('a')
                link.href = URL.createObjectURL(blob)

                const contentDisposition = response.headers['content-disposition'];
                let filename = 'unknown';
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (filenameMatch.length === 2) {
                        filename = filenameMatch[1];
                    }
                }
                link.download = filename

                document.body.appendChild(link)
                link.click()
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                    link.parentNode.removeChild(link);
                }, 0);
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
                responseError.value = 'Failed to open report.'
            })
            .finally(()=>{
                let index = reportsDownloading.value.findIndex(x=>x===id)
                if(index > -1) {
                    reportsDownloading.value.splice(index,1)
                }
                proccessing.value ? setModalBlocked(false): null
            })
        }

        const sortReports = ()=> {
            return reports.value.sort((a, b) => {
                const dateA = a.reportDate ? new Date(a.reportDate) : new Date(Number.MAX_SAFE_INTEGER);
                const dateB = b.reportDate ? new Date(b.reportDate) : new Date(Number.MAX_SAFE_INTEGER);

                // Check if dates are valid
                const isValidDateA = !isNaN(dateA.getTime());
                const isValidDateB = !isNaN(dateB.getTime());

                // First, compare by reportDate
                if (isValidDateA && isValidDateB) {
                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                } else if (isValidDateA) {
                return -1;
                } else if (isValidDateB) {
                return 1;
                }

                // If reportDates are equal or invalid, compare by reportType
                const typeA = a.reportType || '';
                const typeB = b.reportType || '';

                if (typeA < typeB) return -1;
                if (typeA > typeB) return 1;

                // If both reportDate and reportType are equal
                return 0;
            });
        }

        const startAPI = (type)=>{
            type !== 'silent' ? isWorking.value = true : null
            setModalBlocked(true)
            responseError.value = null

        }

        const stopAPI = ()=>{
            isWorking.value = false
            setModalBlocked(false)

        }

        const handleSocketChange = (e)=>{
           if(e.detail?.type) {
            switch(e.detail.type) {
                case 'new_report':
                case 'report':
                    if(e.detail?.data?.projectId === props.project._id && e.detail?.data?.reportId ) {
                        getReportById(e.detail?.data?.reportId)
                    }
                    break;
                case 'delete_report':
                    if(e.detail?.data?.projectId === props.project._id && e.detail?.data?.reportId ) {
                        let index = reports.value.findIndex(x=>x._id === e.detail?.data?.reportId)
                        if(index >-1) {
                            reports.value.splice(index,1)
                        }
                    }
                    break;
                default:
                    break
            }
           }
        }

        return {
            isWorking,
            responseError,
            TrashIcon,
            reports,
            reportDate,
            reportType,
            createNewReport,
            format,
            isValid,
            parseISO,
            capitalizeFirstLetter,
            activeReportId,
            activeReport,
            updateReport,
            deleteReport,
            sendReport,
            reportsSending,
            openReport,
            reportsOpening,
            reportsDownloading,
            downloadReport
        }
    }

}
</script>

<style>

</style>