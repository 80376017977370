<template>
  <div class="w-full flex flex-col flex-grow items-center max-w-5xl">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex border-b">
        <div class="w-auto flex flex-row space-x-1 items-center">
            <div class="p-1">
                <button class="bn-icon-only" @click="moveDate('back')">
                    <ChevronLeftIcon />
                </button>
            </div>
            <div class="p-1">
                <input v-model="viewDate" type="date" class="w-28" @keydown.prevent="null" @change="validateDate($event?.target?.value)">
            </div>
            <div class="p-1">
                <button class="bn-icon-only" @click="moveDate()">
                    <ChevronRightIcon />
                </button>
            </div>
        </div>
    </div>
    <div v-if="isValid(parseISO(viewDate))" class="w-full p-1 flex items-center justify-center border-b">
        <div class="whitespace-normal text-base">{{format(dateInputToISO(viewDate),'EEEE MMM d, yyyy')}}</div>
    </div>
    <div v-if="allowEdit" class="w-full flex flex-row items-center p-1 border-b truncate">
        <div class="flex items-center flex-grow">
            <Popper hover arrow placement="top" :content="'New SOW item'">
                <button class="bn-small px-1 bn-solid-green" @click="allowEdit ? toggleNewDialog() : null">
                    <DocumentTextIcon />
                    <PlusIcon />
                </button>
            </Popper>
        </div>
        <div class="flex items-center">
            <Popper hover arrow placement="top" :content="'Notify Techs of Changes'">
                <button class="bn-small px-1 bn-solid-sky" @click="allowEdit ? changeNotification() : null"
                :disabled="isWorking"
                >
                    <UserGroupIcon />
                    <MailIcon />
                </button>
            </Popper>
        </div>
    </div>
    <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-1">
        <div class="w-full flex flex-col flex-grow items-center overflow-y-auto">
             <ShowSOW :sow="project.sow || []" :allowEdit="allowEdit" :projectId="project?._id" @triggerProjectUpdate="triggerProjectUpdate()" :date="viewDate" />
        </div>
    </div>
  </div>
  <Modal v-if="showModal" @closeModal="toggleNewDialog()" :title="`New SOW: ${project?.companyProjectNumber} - ${project?.projectClientId?.name} - ${project?.name}`">
    <template v-slot:content>
        <div class="w-full flex flex-col space-y-1 items-center flex-grow p-1 max-w-4xl">
            <WorkingAndError :isWorking="isWorking" :error="responseError" />
            <div v-if="canSaveNew" class="w-full text-center bg-red-500 bg-opacity-30 p-1 rounded-md">
                Please Save before closing Modal
            </div>
            <div class="w-full flex items-center justify-start p-1">
                <button class="px-1 bn-solid-green" :disabled="isWorking || !canSaveNew"
                @click="saveNewSOW()"
                >
                    <div>Save</div>
                    <SaveIcon />
                </button>
            </div>
            <div class="w-full flex flex-row items-center justify-between py-1 px-2">
                <div class="flex flex-col space-y-0.5 w-28">
                    <input v-model="startDate" type="date" :disabled="isWorking">
                    <div class="w-full truncate text-left px-1 opacity-60">from</div>
                </div>
               <div class="flex flex-col space-y-0.5 w-28">
                    <input v-model="endDate" type="date" :disabled="isWorking">
                    <div class="w-full truncate text-right px-1 opacity-60">until</div>
                </div>
            </div>
            <div class="w-full px-2 flex flex-col flex-grow overflow-y-auto">
                <TipTap :currentContent="note" @content="note = $event" :limitHeight="true" />
            </div>
        </div>
    </template>
  </Modal>
</template>

<script>
import {ChevronLeftIcon,ChevronRightIcon,DocumentTextIcon,PlusIcon,SaveIcon} from '@heroicons/vue/outline'
import {MailIcon,UserGroupIcon} from '@heroicons/vue/solid'
import {dateInputToISO, formatISODateforInput} from '@/shared'
import {addBusinessDays, isValid,format,parseISO} from 'date-fns'
import ShowSOW from '@/components/projects/sow/ShowSOW.vue'
import TipTap from '@/components/ui/TipTap.vue'
import { computed, inject, ref } from 'vue'
import api from '@/api'
export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false},
        providedDate:{type:String,default:null},
    },
    components:{ShowSOW,TipTap,ChevronLeftIcon,ChevronRightIcon,DocumentTextIcon,PlusIcon,SaveIcon,MailIcon,UserGroupIcon},
    emits:["triggerProjectUpdate"],
    setup (props,{emit}) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const viewDate = ref(
            props.providedDate && isValid(new Date(props.providedDate))
            ? formatISODateforInput(new Date(props.providedDate).toISOString())
            : formatISODateforInput(props.allowEdit ? addBusinessDays(new Date(),1).toISOString() : new Date().toISOString())
        )
        const showModal = ref(false)

        const startDate = ref(null)
        const endDate = ref(null)
        const note = ref(null)

        const canSaveNew = computed(()=>{
            return isValid(new Date(startDate.value)) && note.value ? note.value.replace(/<[^>]*>/g, '').trim().length > 0 : false
        })
        
        const validateDate = (date)=>{
            if(!isValid(dateInputToISO(date))) {
                viewDate.value = formatISODateforInput(addBusinessDays(new Date(),1).toISOString())
            }
        }

        const moveDate = (direction="forward")=>{
            const date = addBusinessDays(dateInputToISO(viewDate.value),direction === 'forward' ? 1 : -1).toISOString()
            viewDate.value = formatISODateforInput(date)
        }

        const toggleNewDialog = ()=>{
            startDate.value = viewDate.value
            showModal.value = !showModal.value
        }

        const saveNewSOW = async ()=>{
            isWorking.value = true
            responseError.value = null
            setModalBlocked(true)
            let body = {
                startDate: dateInputToISO(startDate.value),
                endDate: dateInputToISO(endDate.value),
                note:note.value
            }
            await api
            .post('projects/projectSOW/'+ props.project._id,body)
            .then(()=>{
                triggerProjectUpdate()
                sendChangeEvent('sow','','',{projectId:props.project?._id})
                note.value = null
                endDate.value = null
                showModal.value = false
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const changeNotification = async ()=>{
            isWorking.value = true
            responseError.value = null
            setModalBlocked(true)
            await api
            .post('projects/sowNotification/'+ props.project._id,{})
            .then((res)=>{
                console.log(res.data?.data)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const triggerProjectUpdate = ()=>{
            emit("triggerProjectUpdate")
        }

        return {
            isWorking,
            responseError,
            viewDate,
            validateDate,
            moveDate,
            format,
            isValid,
            parseISO,
            dateInputToISO,
            triggerProjectUpdate,
            showModal,
            startDate,
            endDate,
            note,
            canSaveNew,
            toggleNewDialog,
            saveNewSOW,
            changeNotification
        }
    }

}
</script>

<style>

</style>