<template>
    <QueueDisplay :total="count">
        <template v-slot:icon>
            <BsBookshelfIcon class="h-5 w-5" />
        </template>
    </QueueDisplay>
</template>

<script>
import QueueDisplay from "@/components/ui/QueueDisplay.vue"
import BsBookshelfIcon from '@/components/customIcons/BsBookshelfIcon.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'

export default {
    components:{QueueDisplay,BsBookshelfIcon},
    setup() {
        const count = ref(0)

        onMounted(()=>{
            getInitialCount()
            window.addEventListener('socket_connected', handleReconnect);
            window.addEventListener("rack_queue", handleWebSocketData);
        })

        onUnmounted(()=>{
            window.removeEventListener("socket_connected", handleReconnect);
            window.removeEventListener("rack_queue",handleWebSocketData)
        })

        const handleWebSocketData = (e)=>{
            Number(e.detail?.data)
            ? count.value = e.detail.data
            : null
        }

        const joinRoom = ()=>{
            let eventJoin = new CustomEvent("requestRoom", {detail: { route: "rack", room: "rack_queue" }});
            dispatchEvent(eventJoin);
        }

        const handleReconnect = ()=>{
            joinRoom()
        }

        const getInitialCount = async ()=>{
            await api
            .get("racks/openCount")
            .then((res)=>{
                res.data?.data && Number(res.data.data) ? count.value = Number(res.data.data) : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        return {
            count
        }
    }
}
</script>
