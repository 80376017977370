<template>
    <div class="w-full flex flex-col border rounded-md p-2 truncate space-y-1">
        <div class="w-full flex flex-row items-center truncate space-y-1 flex-nowrap">
            <div class="w-full flex flex-col space-y-0.5 px-2 truncate">
                <div class="w-full flex flex-row items-center justify-between flex-nowrap">
                    <div class="w-full text-left whitespace-normal truncate font-semibold">{{room.roomType}}</div>
                    <div class="px-1" :class="room.avReadinessPercentage === 100 ? 'text-green-700' : room.avReadinessPercentage > 60 ? 'text-yellow-700' : 'text-red-500' ">
                        <ClipboardCheckIcon class="w-4 h-4" />
                    </div>
                    <div class="px-1" :class="room.roomReadinessPercentage === 100 ? 'text-green-700' : room.roomReadinessPercentage > 60 ? 'text-yellow-700' : 'text-red-500' ">
                        <MdDoorFrontIcon class="w-4 h-4" />
                    </div>
                </div>
                <div class="w-full flex flex-row items-center justify-between flex-wrap sm:flex-nowrap">
                    <div class="w-full text-left whitespace-normal truncate">{{room.nameOne}}</div>
                    <div class="w-full text-left whitespace-normal truncate">{{room.nameTwo}}</div>
                </div>
            </div>
            <button v-if="showLink" class="bn-icon-only" @click="emitShow({roomId:room._id,rackId:null})">
                <ExternalLinkIcon />
            </button>
        </div>
        <div v-if="room.roomEquipment && room.roomEquipment.length" class="w-full flex flex-row items-center flex-wrap truncate px-1">
            <div v-if="numberOfBoxes > 0" class="flex flex-row items-center space-x-1 p-1">
                <BoxIcon class="w-4 h-4 flex-shrink-0" />
                <div>{{numberOfBoxes}}</div>
            </div>
            <div v-if="numberOfKits > 0" class="flex flex-row items-center space-x-1 p-1">
                <div class="font-semibold">K:</div>
                <div>{{numberOfKits}}</div>
            </div>
            <div v-if="numberOfAccesories > 0" class="flex flex-row items-center space-x-1 p-1">
                <CableIcon class="w-4 h-3 flex-shrink-0 rotate-90" />
                <div>{{numberOfAccesories}}</div>
            </div>
        </div>
        <div v-if="room.racks && room.racks.length" class="w-full flex flex-col flex-grow px-2 overflow-y-auto" >
            <div class="w-full flex flex-row items-center flex-nowrap space-x-2 py-1 border-b">
                <BsBookshelfIcon class="h-3 w-3 flex-shrink-0" />
                <div class="w-auto whitespace-normal truncate">Racks</div>
                <div class="w-auto px-1">{{room.racks.length}}</div>
            </div>
            <div class="w-full flex flex-col space-y-1 p-1 max-h-48 overflow-y-auto">
                <div v-for="rack in room.racks" :key="rack._id" class="w-full flex flex-col space-y-0.5 p-1 border rounded-md max-w-xl">
                    <div class="w-full flex flex-row items-center truncate">
                        <div class="w-full text-left px-1 whitespace-normal truncate">{{rack.name}}</div>
                        <div v-if="rack.rackState > 2" class="p-1 icon-only text-green-600">
                            <CheckCircleIcon />
                        </div>
                        <div v-else-if="rack.rackState > 1" class="p-1 icon-only text-green-600">
                            <WrenchSolidIcon />
                        </div>
                        <button v-if="showLink" class="bn-icon-only" @click="emitShow({roomId:room._id,rackId:rack._id})">
                            <ExternalLinkIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ClipboardCheckIcon,ExternalLinkIcon,CheckCircleIcon } from "@heroicons/vue/solid"
import MdDoorFrontIcon from "@/components/customIcons/MdDoorFrontIcon.vue"
import BsBookshelfIcon from '@/components/customIcons/BsBookshelfIcon.vue'
import BoxIcon from '@/components/customIcons/BoxIcon.vue'
import CableIcon from '@/components/customIcons/CableIcon.vue'
import WrenchSolidIcon from '@/components/customIcons/WrenchSolidIcon.vue'
import { computed } from 'vue'
export default {
    props:{
        room:{type:Object,default:()=>{return{}}},
        showLink:{type:Boolean,default:false}
    },
    emits:["showContents"],
    components:{ClipboardCheckIcon,MdDoorFrontIcon,BsBookshelfIcon,BoxIcon,CableIcon,ExternalLinkIcon,CheckCircleIcon,WrenchSolidIcon},
    setup(props,{emit}) {

        const numberOfBoxes = computed(()=>{
            return props.room?.roomEquipment && props.room.roomEquipment.length > 0
            ? props.room.roomEquipment.filter(x=>!x.multiBox && !x.kitId).length
            : 0
        })

        const numberOfKits = computed(()=>{
            return props.room?.roomEquipment && props.room.roomEquipment.length > 0
            ? props.room.roomEquipment.filter(x=>x.isKit).length
            : 0
        })

        const numberOfAccesories = computed(()=>{
            return props.room?.roomEquipment && props.room.roomEquipment.length > 0
            ? props.room.roomEquipment.filter(x=>!x.kitId && x.multiBox).length
            : 0
        })

        const emitShow = (val)=>{
            emit("showContents",val)
        }

        return {
            numberOfBoxes,
            numberOfKits,
            numberOfAccesories,
            emitShow
        }
    }

}
</script>

<style>

</style>