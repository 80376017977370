import { reactive, readonly, computed } from 'vue'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { socketConnect } from './components/socketConnection'
import {format} from 'date-fns'

// State
const state = reactive({
  auth: {
    loggedIn: false,
    name: '',
    email: '',
    role: {},
    token: '',
    organization: '',
    timesheet:false,
    onSchedule:false,
    fourDayWeek:false
  },
  systemStatus: {
    dbConnection: false,
    wsConnection: false
  },
  logoutError: false,
  resetPassword: false,
  sideBarOpen: false,
  logOutFlag: false,
  modalBlock:false,
  darkModeState:true,
  scrollBarState:false,
  projectListSettings:{
    projectNumberFilter:'',
    sortBy:null,
    sortDirection:false,
    userIsAssigned:false,
    showRackOnly:false,
    scrollTop:0
  }
  
})

// Getters
const authenticated = computed(() => state.auth)
const appStatus = computed(() => state.systemStatus)
const darkState = computed(()=>state.darkModeState)
const scrollState = computed(()=>state.scrollBarState)
const isLogoutError = computed(() => state.logoutError)
const resetPasswordFlag = computed(() => state.resetPassword)
const sideBar = computed(() => state.sideBarOpen)
const initials = computed(() => {
  if (state.auth.name.indexOf(" ") > 0) {
    let nameArray = state.auth.name.split(" ")
    let nameArraylength = nameArray.length
    return nameArray[0].charAt(0) + nameArray[nameArraylength - 1].charAt(0)
  } else return state.auth.name.charAt(0)
})
const loggingOut = computed(() => state.logOutFlag)
const expiration = computed(() => {
  if (state.auth.token) {
    const decodedToken = jwt_decode(state.auth.token)
    return decodedToken.exp
  } else return ''
})
const isModalBlocked = computed(()=>state.modalBlock)
const projectViewSettings = computed(()=>state.projectListSettings)


// Mutations

const setTheme = (value) =>{
  value === 'dark'
  ? state.darkModeState = true
  : state.darkModeState = false
}

const toggleTheme = ()=>{
  state.darkModeState = !state.darkModeState
  localStorage.setItem('theme', state.darkModeState ? 'dark' : 'light')
}

const setScrollBars = (value)=>{
  value === 'true'
  ? state.scrollBarState = true
  : state.scrollBarState = false
}

const toggleScrollBars = ()=>{
  state.scrollBarState = !state.scrollBarState
  localStorage.setItem('scrollBars', state.scrollBarState ? 'true' : 'false')
}

const login = function (email,role,name,token,organization,timesheet,onSchedule,_id,fourDayWeek) {

  // Emit event to close existing socket, needed if relogin before token expiration.
  // Listener is in ./components/socketConnection.
  let event = new Event('closeExistingSocket')
  dispatchEvent(event)

  // Set global state for login
  state.auth.loggedIn = true
  sessionStorage.setItem("loggedIn", true)
  state.auth.name = name
  state.auth.email = email
  state.auth.role = role
  state.auth.token = token
  state.auth.organization = organization
  state.auth.timesheet = timesheet,
  state.auth.onSchedule = onSchedule,
  state.auth._id = _id
  state.auth.fourDayWeek = fourDayWeek || false

  //if login triggered, db connection is true.
  state.systemStatus.dbConnection = true

  // connect websocket, function in ./components/socketConnection.
  console.log('Connecting WebSocket')
  socketConnect(state.auth.token)
}

const loginAnon = (secureCode)=>{
  let event = new Event('closeExistingSocket')
  dispatchEvent(event)
  state.systemStatus.dbConnection = true
  console.log('Attempting Anonymous WebSocket Connection.')
  socketConnect(null,secureCode)
}

const logout = async () => {
  state.logOutFlag = true
  localStorage.setItem("other-tab-logout", "");
  //localStorage.clear();
  window.location.pathname = '/'
  state.auth.loggedIn = false
  sessionStorage.removeItem("loggedIn")
  state.auth.name = ''
  state.auth.email = ''
  state.auth.role = {}
  state.auth.token = ''
  state.auth.organization = {}
  state.auth.timesheet = false
  state.auth.onSchedule = false
  state.auth.fourDayWeek = false
}

// reestablished login if the browser is refreshed
const authOnRefresh = async () => {

  if (window.performance) {

    if (performance.getEntriesByType("navigation")[0].type === "reload" &&
      document.cookie) {
      let decodedCookie = decodeURIComponent(document.cookie);
      const cookieValue = decodedCookie
        .split(";")
        .find((row) => row.startsWith("refresh="))
        .split("=")[1];
      if (cookieValue) {
        await axios.get('/api/v1/auth/me', {
          headers: {
            authorization: "Bearer " + cookieValue,
          },
        })
          .then((response) => {
            if (response.data.data.email && response.data.data.role && response.data.data.name && response.data.data.level && response.data.data.organization && response.data.data.timesheet && response.data.onSchedule && response.data.data._id) {
              login(response.data.data.email, response.data.data.role, response.data.data.name, response.data.data.level, cookieValue, response.data.data.organization,response.data.data.timesheet,response.data.onSchedule, response.data.data._id)
            }
          })
          .catch((err) => {
            console.error(err)
          })
        document.cookie = `refresh=;max-age=-1;expires=${Date.now() - 10};SameSite=Lax`;
        if (localStorage.getItem("refresh")) { localStorage.removeItem("refresh") }

      }
    } else {
      document.cookie = `refresh=;max-age=-1;expires=${Date.now() - 10};SameSite=Lax`;
    }
  } else {
    console.info("window performance api not available");
  }
}

// Sets value of server connection to DB and Browser Websocket connection to server.
// Displayed as red/green in App.vue
const setAppStatus = (type, status) => {
  if (type == 'db') { state.systemStatus.dbConnection = status }
  if (type == 'ws') { state.systemStatus.wsConnection = status }
}

const setResetPasswordFlag = () => {
  state.resetPassword = true
}

const toggleSideBar = () => {
  state.sideBarOpen = !state.sideBarOpen
}

const closeSideBar = () => {
  state.sideBarOpen = false
}

const updateRole = (role)=>{
  state.auth.role = role
}

const convertDate = async (val) =>{
  let selectedDate = new Date(val)
  let convertedDate =  new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() *60000)
  return convertedDate
}

const distanceBetweenPoints = (lon1,lat1,lon2,lat2,unit)=> {
  if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}

const formatDate = (date)=> {
  return format(new Date(date), 'ccc MMM dd, yyyy')
}

const sendChangeEvent = (type,projectId,shipmentId,data)=>{
    let changeEvent = new CustomEvent("local_data_change",{detail:{type,projectId,shipmentId,data}} );
    dispatchEvent(changeEvent);
}

const openDetails = (target) =>{
  if(target.nextElementSibling) {
    target.nextElementSibling.classList.toggle('hidden')
  }
}

const setModalBlocked = (val)=>{
  state.modalBlock = val
}

const testJSON = (data)=> {
    if (typeof data !== "string" || data === '') {
    return false;
    }
    try {
        JSON.parse(data);
        return true;
    } catch (error) {
        return false;
    }
}

const setProjectListSettings = (key,value)=>{
  state.projectListSettings[key] = value
}


export default {
  state: readonly(state),
  expiration,
  authenticated,
  appStatus,
  initials,
  isLogoutError,
  resetPasswordFlag,
  sideBar,
  loggingOut,
  login,
  loginAnon,
  logout,
  authOnRefresh,
  setAppStatus,
  setResetPasswordFlag,
  toggleSideBar,
  closeSideBar,
  convertDate,
  distanceBetweenPoints,
  formatDate,
  sendChangeEvent,
  updateRole,
  testJSON,
  openDetails,
  isModalBlocked,
  setModalBlocked,
  darkState,
  scrollState,
  setTheme,
  toggleTheme,
  setScrollBars,
  toggleScrollBars,
  projectViewSettings,
  setProjectListSettings
}

