<template>
  <div v-if="showExpireDialog" class="w-screen h-screen absolute z-100 top-0 left-0" style="min-height: 568px">
    <div class="h-full w-full flex items-center justify-center">
      <div class=" flex flex-col space-y-2 py-10 rounded-lg w-10/12 sm:w-5/6 min-w-md max-w-lg shadow-md border-third border-t-8 px-4 sm:px-10">
        <div class="flex flex-row items-center space-x-2 justify-center">
          <ExclamationCircleIcon class="w-5 h-5 text-red-700" />
          <div class="font-semibold">Your Credentials are About to Expire.</div>
        </div>
        <div class="w-full flex items-center justify-center text-red-700">{{ timetoExpire }}</div>
        <login-dialog />
      </div>
    </div>
  </div>
</template>

<script>
import global from "../global";
import { onMounted, onBeforeUnmount, ref } from "vue";
import LoginDialog from "./LoginDialog.vue";
import {ExclamationCircleIcon} from "@heroicons/vue/outline"

const timetoExpire = ref();
const showExpireDialog = ref(false);

export default {
  components: { LoginDialog,ExclamationCircleIcon},
  setup() {
    const { expiration, logout, authenticated } = global;

    onMounted(() => {
      const check_token_interval = setInterval(() => {
        if (authenticated.value.loggedIn && expiration.value) {
          let secondsToExpire = Math.round(
            expiration.value - Date.now() / 1000
          );
          let minutes = Math.floor(secondsToExpire / 60);
          let seconds = secondsToExpire % 60;
          timetoExpire.value = minutes + ":" + ("0" + seconds).slice(-2);
          if (minutes <= 0 && seconds <= 0) {
            if (check_token_interval) {
              logout();
            }
          } else {
            if (minutes <= process.env.VUE_APP_TOKEN_TIMEOUT_MINUTES) {
              showExpireDialog.value = true;
            } else {
              showExpireDialog.value = false;
            }
          }
        }
      }, 1000);
    });

    onBeforeUnmount((check_token_interval) => {
      clearInterval(check_token_interval);
    });

    return {
      timetoExpire,
      showExpireDialog,
      authenticated,
    };
  },
};
</script>

<style>
</style>