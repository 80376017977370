<template>
  <div class="flex fixed items-center top-0 left-0 overflow-auto w-full h-full z-100 px-24" style="min-height: 100px">
      <div class="rounded-md w-full max-w-screen-sm shadow-lg px-8 py-4 mx-auto border-red-500 border-2" style="min-width:320px">
          <div class="text-red-700 w-full mb-5 pb-1 border-b-2 font-bold text-2xl font-sans">Alert !</div> 
          <div class="font-sans w-full">Unable to contact server.</div>
          <div class="font-bold font-sans w-full">Please close this tab to logout.</div>
       </div>
  </div>
</template>

<script>
export default {


}
</script>

<style>

</style>