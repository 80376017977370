<template>
  <!-- Email Addresss Input -->
  <div class="flex flex-row w-full items-center space-x-1">
    <AtSymbolIcon class="h-5 w-5" 
      :class="[
        !emailInput.emailValidated && emailInput.userEmail.length
          ? 'text-red-500'
          : emailInput.emailValidated && emailInput.userEmail.length
          ? 'text-green-700'
          : null,
        ]
      "
    />
    <input v-model="emailInput.userEmail" type="email" class="w-full" name="email" :placeholder="placeholder || 'email'"
      :class="[
        !emailInput.emailValidated && emailInput.userEmail.length
          ? 'text-red-500'
          : emailInput.emailValidated && emailInput.userEmail.length
          ? 'text-green-700'
          : null,
        ]
      "
      @keyup="validateEmail"
    />
  </div>
</template>

<script>
import {AtSymbolIcon} from "@heroicons/vue/outline"
export default {
  name: "EmailInput",
  components:{AtSymbolIcon},
  props: {
    placeholder: { type: String },
    size:{type:String,default:null}
  },
  emits: ["emailInput"],
  data: () => {
    return {
      emailInput: {
        userEmail: "",
        emailValidated: false,
      },
    };
  },
  methods: {
    validateEmail: function () {
      this.emailInput.userEmail.match(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      )
        ? (this.emailInput.emailValidated = true)
        : (this.emailInput.emailValidated = false);

      this.$emit("emailInput", this.emailInput);
    },
  },
};
</script>

<style>
</style>