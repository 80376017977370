<template>
    <div class="w-full max-w-3xl py-1 px-2 text-xs flex flex-row shadow-md items-center rounded-md">
        <div class="flex flex-col truncate flex-grow space-y-1 border rounded-md">
            <div class="w-full flex flex-row space-x-2 items-center p-2">
                <div class="whitespace-nowrap w-28 flex-shrink-0 text-left font-semibold truncate">
                    {{
                        photo.properties?.PhotoDate && isValid(parseISO(photo.properties?.PhotoDate))
                        ? format(parseISO(photo.properties?.PhotoDate),'MMM d, yyyy')
                        : photo.createdTime && isValid(parseISO(photo.createdTime))
                            ? format(parseISO(photo.createdTime),'MMM d, yyyy')
                            : null
                    }}
                </div>
                <div class="truncate flex-grow text-left whitespace-normal">{{photo.name}}</div>
                <div class="flex flex-row items-center space-x-2">
                    <button v-if="['image'].some(x=>photo.mimeType.includes(x))" class="bn-icon-small"
                    @click="viewFile(photo.id,photo.mimeType,photo.name,photo.properties.RoomName)"
                    :disabled="idsInDownloadToView.includes(photo.id) || idsInDelete.includes(photo.id)"
                    >
                        <RefreshIcon v-if="idsInDownloadToView.includes(photo.id)" class="animate-spin" />
                        <EyeIcon v-else />
                    </button>
                    <button class="bn-icon-small" @click="downloadImage(photo.id,photo.mimeType,photo.name)"
                    :disabled="idsInDownload.includes(photo.id) || idsInDelete.includes(photo.id)"
                    >
                        <RefreshIcon v-if="idsInDownload.includes(photo.id)" class="animate-spin" />
                        <DownloadIcon v-else />
                    </button>
                    <DeleteConfirm :size="'small'" :disableButton="idsInDelete.includes(photo.id) || !allowDelete" @confirmedDelete="deleteImage(photo.id)" />
                </div>
            </div>
            <div v-if="photo.properties?.RoomName.trim() !== ''" class="truncate w-full px-2 text-left text-sky-600">{{photo.properties.RoomName}}</div>
            <div v-if="photo.description || photo.properties?.Notes" class="w-full flex flex-col space-y-1 px-1">
                <div class="w-full text-left px-1 whitespace-normal">{{photo.description}}</div>
                <div class="w-full text-left px-1 whitespace-normal">{{photo.properties?.Notes}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import {format,parseISO,isValid} from "date-fns"
import {EyeIcon,DownloadIcon,RefreshIcon} from '@heroicons/vue/outline'
export default {
    props:{
        photo:{type:Object,default:()=>{return{}}},
        allowDelete:{type:Boolean,default:false}
    },
    data:()=>{
        return {
            idsInDownload:[],
            idsInDownloadToView:[],
            idsInDelete:[],
            format,
            parseISO,
            isValid
        }
    },
    emits:["viewFile","deleteFile"],
    components:{EyeIcon,DownloadIcon,RefreshIcon},
    methods:{
        viewFile: async function (id,mimeType,name,room) {
            this.idsInDownloadToView.push(id)
            await api
            .get("projects/downloadProjectPhoto/" + id,{
                responseType:'blob'
            })
            .then((response)=>{
                let blob = new Blob([response.data], { type: mimeType })
                this.$emit("viewFile",{blob:blob,name:name,mimeType:mimeType,room:room})
                blob =''
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
            .finally(()=>{
                let fileIdIndex = this.idsInDownloadToView.findIndex((x)=>x===id)
                if(fileIdIndex >= 0) {
                    this.idsInDownloadToView.splice(fileIdIndex,1)
                }
            })
        },
        downloadImage: async function(id,mimeType,name) {
            this.idsInDownload.push(id)
            await api
            .get("projects/downloadProjectPhoto/" + id,{
                responseType:'blob'
            })
            .then((response)=>{
                let blob = new Blob([response.data], { type: mimeType })
                let link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = name

                document.body.appendChild(link)
                link.click()

                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                    link.parentNode.removeChild(link);
                }, 0);
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
            .finally(()=>{
                let fileIdIndex = this.idsInDownload.findIndex((x)=>x===id)
                if(fileIdIndex >= 0) {
                    this.idsInDownload.splice(fileIdIndex,1)
                }
            })
        },
        deleteImage: async function(id) {
            this.idsInDelete.push(id)
            await api
            .delete("projects/projectPhotos/" + id)
            .then(()=>{
                this.$emit("deleteFile",id)
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
            .finally(()=>{
                let fileIdIndex = this.idsInDelete.findIndex((x)=>x===id)
                if(fileIdIndex >=0) {
                    this.idsInDelete.splice(fileIdIndex,1)
                }
            })
        }
    }
}
</script>

<style>

</style>