<template>
    <div v-if="Object.keys(selectedItem).length > 0" class="w-full flex flex-row space-x-2 items-center">
        <div class="w-full truncate text-left px-1">{{selectedItem.displayName}}</div>
        <button class="bn-icon-only" @click="clearSelection()">
            <XIcon />
        </button>
    </div>
  <div v-else class="w-full">
    <input type="search" class="w-full text-xs" :placeholder="placeholder" v-model.trim="searchTerm" @keydown="handleKeyDown" @input="search()" @paste.prevent="convertPastedText" :disabled="disabled">
    <div class="w-full relative max-w-xl">
        <div v-if="responseError" class="p-1 w-full absolute top-0 left-0 z-40 overflow-y-auto rounded-b-md shadow-md panel">
            <ErrorDisplay :error="responseError" />
        </div>
        <div v-if="results.length || isWorking || noResultsReturned" class="w-full absolute top-0 left-0 z-40 overflow-y-auto border rounded-b-md">
            <div class="w-full p-2 flex flex-col overflow-y-auto panel border-0 ">
                <div v-if="isWorking" class="w-full">
                    <LoadingData :message="'Searching..'" />
                </div>
                <div v-if="noResultsReturned" class="flex flex-row space-x-2 justify-center items-center text-xs">
                    <div>No Results Returned.</div>
                </div>
                <div class="w-full flex flex-col space-y-1 p-2 text-xs overflow-y-auto" >
                    <div v-for="(item,index) in results" :key="item._id" class="w-full cursor-pointer px-1 rounded-sm"
                    :class="activeIndex === index ? 'bg-neutral-300 dark:text-neutral-900 dark:bg-sky-600' : null "
                    @mouseover="activeIndex = index"
                    @mouseout="activeIndex = -1"
                    >
                        <!--<div class="text-left truncate text-sm" @click="selectItem(item)" >{{item.displayName}}</div>-->
                        <div class="w-full text-left truncate flex flex-col" @click="selectItem(item)">
                            <div class="w-full truncate" :class="item.companyProjectNumber ? 'font-semibold' : null">{{item.displayName}}</div>
                            <div v-if="item.companyProjectNumber" class="w-full truncate">{{
                                authenticated.organization?.orgType.includes('integrator') 
                                ? item.clientProjectNumber || item.companyProjectNumber
                                : item.companyProjectNumber
                            }}</div>
                            <div v-else-if="item.subTitle" class="w-full text-left whitespace-normal truncate">
                                {{item.subTitle}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import api from '@/api.js'
//import axios from 'axios';
import global from "../../global"
import {XIcon} from "@heroicons/vue/outline"


export default {
  props: {
    apiEndpoint: {
      type: String,
      required: true
    },
    placeholder:{
        type:String,
        default:'Search'
    },
    displayResult:{
        type:Boolean,
        default:true
    },
    disabled:{type:Boolean,default:false}
  },
  components:{XIcon},
  emits: ['resultSelected'],
  setup(props, { emit }) {
    const searchTerm = ref('');
    const results = ref([]);
    const inProgress = ref(false)
    const isRunningApi = ref(false)
    const noResultsReturned = ref(false)
    const activeIndex = ref(-1);
    const isWorking = ref(false);
    const responseError = ref('')
    const selectedItem = ref({})

    const {authenticated} = global

    const search = ()=>{
        responseError.value = ''
        if(searchTerm.value.replace(/[^a-zA-Z0-9\s-]/g, '').length > 2) {
            apiThrottle()
        } else {
            results.value = []
            noResultsReturned.value = false
        }
    }


    const apiThrottle = ()=> {
        if(!inProgress.value) {
            inProgress.value = true
            setTimeout(()=>{
                getSearchResults()
                inProgress.value = false
            },1000)
        }
    }

    const getSearchResults = async()=>{
        isRunningApi.value = true
        if(searchTerm.value.replace(/[^a-zA-Z0-9\s-]/g, '').length > 2 && typeof searchTerm.value === 'string') {
            noResultsReturned.value = false
            isWorking.value = true
            await api
            .get(props.apiEndpoint ,{
                params:{
                    search:`${searchTerm.value.replace(/[^a-zA-Z0-9\s-]/g, '')}`
                }
            })
            .then((res)=>{
                results.value = res.data.data
                res.data.data.length === 0 ? noResultsReturned.value = true : null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
            })
        }
    }

    const selectItem = (item)=>{
        emit('resultSelected',item)
        results.value = []
        activeIndex.value = -1;
        searchTerm.value = item.name
        if(props.displayResult) {
            selectedItem.value = item
        } else {
            selectedItem.value = {}
            searchTerm.value = ''
        }
    }

    const clearSelection = ()=>{
        emit('resultSelected',{})
        selectedItem.value = {}
        searchTerm.value = ''
    }

    const handleKeyDown = (event)=> {
      switch (event.keyCode) {
        case 38: // up arrow
          event.preventDefault();
          activeIndex.value = Math.max(activeIndex.value - 1, -1);
          break;
        case 40: // down arrow
          event.preventDefault();
          activeIndex.value = Math.min(activeIndex.value + 1, results.value.length - 1);
          break;
        case 13: // enter
          if (activeIndex.value >= 0 && activeIndex.value < results.value.length) {
            selectItem(results.value[activeIndex.value]);
          }
          break;
      }
    }

    const convertPastedText =  (event)=> {
      let intendedText = event.originalEvent || event.clipboardData.getData("text/plain");
      searchTerm.value = intendedText.toString();
      search()
    }

    return {
        authenticated,
        selectedItem,
        selectItem,
        searchTerm,
        search, 
        results, 
        activeIndex, 
        isWorking, 
        handleKeyDown,
        convertPastedText,
        noResultsReturned,
        responseError,
        clearSelection
        };
  }
};
</script>

<style>
li.active {
  background-color: #ccc;
}
</style>
