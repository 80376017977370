<template>
  <div class="w-full flex flex-col space-y-0.5 border rounded-md p-0.5 sm:p-1 truncate">
    <div class="w-full flex flex-row items-center truncate flex-nowrap">
        <div class="w-full flex-grow flex flex-row items-center truncate justify-between flex-wrap p-1">
            <div class="w-auto px-1">
                <div class="w-auto whitespace-normal truncate">{{rack.name}}</div>
            </div>
            <div class="w-auto px-1">
                <div class="w-auto whitespace-normal truncate">{{rack.rackType}}</div>
            </div>
        </div>
        <div v-if="rack.rackState > 0" class="p-1 icon-only text-green-600">
            <WrenchSolidIcon v-if="rack.rackState > 2"/>
            <CheckCircleIcon v-else />
        </div>
    </div>
    <slot name=actions class="w-full flex flex-col p-1 truncate" />
    <div v-if="rack.roomId && (typeof rack.roomId === 'object')" class="p-1 w-full text-left whitespace-normal truncate border-t">
        <div class="w-full truncate whitespace-normal flex px-1">{{rack.roomId?.nameOne || rack.roomId?.nameTwo || `Unnamed ${rack.roomId?.roomType}`}}</div>
    </div>
  </div>
</template>

<script>
    import WrenchSolidIcon from '../customIcons/WrenchSolidIcon.vue'
    import {CheckCircleIcon} from '@heroicons/vue/solid'
    export default {
        props:{
            rack:{type:Object,default:()=>{return}}
        },
        components:{WrenchSolidIcon,CheckCircleIcon},
        setup() {
            return {

            }
        }
    }
</script>

<style>

</style>