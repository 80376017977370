<template>
    <div class="w-full flex flex-col flex-grow items-center max-w-4xl">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
        <div v-if="allowEdit" class="w-full flex flex-col space-y-1 p-1 items-center panel">
            <div class="w-full p-2 max-w-xl">
                <searchApi :apiEndpoint="'search/users'" :placeholder="'Add users'" :displayResult="false"  @resultSelected="addFollower($event)" />
            </div>
        </div>
        <div v-if="project.projectAssigned && project.projectAssigned.length > 0" class="w-full text-left py-1 truncate px-4">{{`${project.projectAssigned.length} contacts`}}</div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-2 panel">
            <div class="w-full flex flex-col space-y-2 flex-grow overflow-y-auto">
                <div v-if="!isWorking && (!project.projectAssigned || project.projectAssigned.length === 0)">
                    <div class="w-full text-center truncate">No Site Contacts</div>
                </div>
                <div v-else class="w-full p-1 flex flex-row flex-wrap justify-start">
                    <div v-for="item in project.projectAssigned" :key="item._id" class="w-96 flex-shrink-0 truncate p-1">
                        <div class="w-full flex-shrink-0 flex flex-col space-y-1 p-2 border rounded-md shadow-sm">
                            <div class="w-full flex flex-row space-x-2 items-center truncate">
                                <div class="w-full whitespace-normal px-1 truncate text-left">{{item.name}}</div>
                                <DeleteConfirm v-if="allowEdit" :size="'small'" @confirmedDelete="deleteFollower(item._id)" />
                            </div>
                            <div v-if="item.organization?.name" class="w-full text-left truncate whitespace-normal px-1">{{item.organization.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject,ref} from 'vue'
import api from "@/api"
import searchApi from '@/components/ui/searchApi.vue'
export default {
    components:{searchApi},
    props:{
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false}
    },
    emits:["triggerProjectUpdate"],
    setup (props,{emit}) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global

        const addFollower = async (e)=>{
            startAPI()
            if(!props.project.projectAssigned.map((x)=>x._id).includes(e._id)) {
                let body = {projectAssigned:{_id:e._id}}
                await api.put('projects/addFollower/'+ props.project._id,body)
                .then(()=>{
                    emit("triggerProjectUpdate")
                    sendChangeEvent('following',props.project._id,'',{projectId:props.project._id})
                })
                .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    stopAPI()
                })
            } else {
                stopAPI()
                responseError.value = 'Already Following.'
            }
        }

        const deleteFollower = async (id)=>{
            startAPI()
            let body = {projectAssigned:{_id:id}}
            await api.put('projects/removeFollower/'+props.project._id,body)
            .then(()=>{
                emit("triggerProjectUpdate")
                sendChangeEvent('following',props.project._id,'',{projectId:props.project._id})
            })
            .catch((err)=>{
            responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }
        const startAPI = ()=>{
                setModalBlocked(true)
                isWorking.value = true
                responseError.value = null
        }

        const stopAPI = ()=>{
                setModalBlocked(false)
                isWorking.value = false
        }

        return {
            isWorking,
            responseError,
            addFollower,
            deleteFollower
        }
    }

}
</script>

<style>

</style>