<template>
    <div class="flex space-x-1 items-center">
        <div :class="buttonState ?'text-gray-500' : 'text-black dark:text-sky-400 font-semibold'">{{offValue}}</div>
        <div class="rounded-full w-9 h-5 flex flex-shrink-0 border-2 items-center cursor-pointer" :class="buttonState ? 'justify-end' : 'justify-start'"
        @click="toggleButton()"
        >
            <div class="h-4 w-4 flex flex-shrink-0 rounded-full"
            :class="buttonState ? 'bg-sky-700 dark:bg-sky-400' : 'bg-neutral-400' "
            ></div>
        </div>
        <div :class="buttonState ?'text-black dark:text-sky-400 font-semibold' : 'text-neutral-400'">{{onValue}}</div>
    </div>
</template>

<script>
export default {
    props:{
        offValue:{type:String,default:'Off'},
        onValue:{type:String,default:'On'},
        initialButtonState:{type:Boolean,default:false}
    }, 
    emits:["toggleState"],
    data:()=>{
        return {
            buttonState:false
        }
    },
    mounted:function() {
        this.buttonState = this.initialButtonState
    },
    methods:{
        toggleButton: function(){
            this.buttonState = !this.buttonState
            this.$emit("toggleState",this.buttonState)
        },
        resetButton:function(){
            this.buttonState = !this.buttonState
        }
    }
}
</script>

<style>

</style>