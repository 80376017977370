import { createApp } from 'vue'
import App from './App.vue'
import Maska from 'maska'
import "./main.css"
import router from './router'
import WorkingAndError from './components/ui/WorkingAndError.vue'
import LoginDialog from './components/LoginDialog.vue'
import LoadingData from './components/ui/LoadingData.vue'
import Modal from "./components/ui/Modal.vue"
import SidePanel from "./components/ui/SidePanel.vue"
import ModalFull from "./components/ui/ModalFull.vue"
import Popper from "vue3-popper"
import NoAccess from "./components/utils/NoAccess.vue"
import ErrorDisplay from "./components/utils/ErrorDisplay.vue"
import DeleteConfirm from "./components/ui/DeleteConfirm.vue"
import ApplicationModal from "@/components/ui/ApplicationModal.vue"
import ConfirmButton from "@/components/ui/ConfirmButton.vue"


const app = createApp(App).use(router).use(Maska)

app.component("LoginDialog",LoginDialog)
app.component("Popper",Popper)
app.component("WorkingAndError",WorkingAndError)
app.component("LoadingData", LoadingData)
app.component("Modal", Modal)
app.component("SidePanel", SidePanel)
app.component("ModalFull", ModalFull)
app.component("NoAccess",NoAccess)
app.component("ErrorDisplay",ErrorDisplay)
app.component("DeleteConfirm",DeleteConfirm)
app.component("ApplicationModal",ApplicationModal)
app.component("ConfirmButton",ConfirmButton)


app.mount('#app')

