<template>
  <div class="p-1">
    <Popper hover arrow placement="top" :content="responseError ? 'Tool Error' : 'Tools Out'">
        <button class="px-2" :class="{'text-green-700':Array.isArray(toolsReturnsFiltered) && toolsReturnsFiltered.length > 0}" :disabled="isWorking || (Array.isArray(toolsReturnsFiltered) && toolsReturnsFiltered.length === 0) "
            @click="showToolsToReturn = !showToolsToReturn"
        >
            <ToolboxFillIcon />
            <XIcon v-if="showToolsToReturn" />
            <ExclamationCircleIcon v-else-if="responseError" />
            <CogIcon v-else-if="isWorking" />
            <div v-else-if="Array.isArray(toolsReturnsFiltered)" class="max-w-6 truncate">
                {{toolsReturnsFiltered.length}}
            </div>
        </button>
    </Popper>
  </div>
  <SidePanel v-if="showToolsToReturn" @closePanel="showToolsToReturn = false">
    <template v-slot:content>
        <div v-for="project in toolPickupsSorted" :key="project.projectId" class="w-full flex flex-col p-1 space-y-1">
            <div class="w-full flex flex-row items-center truncate py-1 px-2 panel" :class="{'has-details' : !projectId}" @click="!projectId ? openDetails($event.target) : null">
                <div class="w-full text-left whitespace-normal truncate line-clamp-2">{{project.projectName}}</div>
                <div class="px-1">{{project.toolCount}}</div>
            </div>
            <div class="w-full p-1" :class="{'hidden' : !projectId}">
                <div v-if="Array.isArray(project.addresses) && project.addresses.length > 0" class="w-full flex flex-col p-1 border rounded-md">
                    <div v-for="address in project.addresses" :key="address.googlePlacesId" class="w-full">
                        <div class="w-full border-b flex flex-col">
                            <div class="flex-grow p-1 border-b">
                                <DisplayAddress :address="address.shipmentAddress" :showAddressTwo="false" />
                            </div>
                            <NewToolPickup :projectId="project.projectId" :tools="address.tools || []" :address="address.shipmentAddress" @update="update($event)" />
                        </div>
                        <div class="w-full flex items-center justify-end p-1">
                            <div class="">
                                <button class="px-1" :disabled="!Array.isArray(address.tools) || address.tools.length === 0"
                                @click="selectAll(address.tools)"
                                >
                                    <PlusIcon class="w-3 h-3 flex-shrink-0" />
                                    <div>All</div>
                                </button>
                            </div>
                        </div>
                        <div v-if="Array.isArray(address.tools) && address.tools.length > 0" class="w-full flex flex-col space-y-1">
                            <div v-for="tool in address.tools" :key="tool._id" class="w-full">
                                <div class="w-full border rounded-md flex flex-col px-1">
                                    <div class="w-full flex items-center truncate border-b">
                                        <div class="flex-grow text-left whitespace-normal truncate line-clamp-2 p-1" :class="{'text-green-700':tool.selected}">{{tool.name}}</div>
                                        <div class="p-1">
                                            <button class="bn-icon-only" @click="tool.selected = !tool.selected">
                                                <CheckIcon v-if="tool.selected" />
                                                <XIcon v-else />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="w-full flex flex-row items-center truncate flex-wrap">
                                        <div class="flex-grow text-left whitespace-normal truncate line-clamp-2 p-1">
                                            {{tool.toolCatalogId?.category}}
                                        </div>
                                        <div v-if="tool.returnDate && isValid(parseISO(tool.returnDate))" class="w-auto whitespace-nowrap truncate line-clamp-2 p-1 text-sky-700 dark:text-sky-600">
                                            {{format(parseISO(tool.returnDate),'MMM dd, yyyy')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
  </SidePanel>
</template>

<script>
import ToolboxFillIcon from '@/components/customIcons/ToolboxFillIcon.vue'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
import { isValid, parseISO, compareAsc,format} from 'date-fns'
import {openDetails} from '@/shared'
import {CogIcon,ExclamationCircleIcon,XIcon,CheckIcon,PlusIcon} from '@heroicons/vue/solid'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import NewToolPickup from './NewToolPickup.vue'
export default {
    props:
    {
          projectId:{
            type:String,
            default:null
        },
    },
    components:{DisplayAddress,NewToolPickup,ToolboxFillIcon,CogIcon,ExclamationCircleIcon,XIcon,CheckIcon,PlusIcon},
    emits:["update"],
    setup (props,{emit}) {
        const global = inject('global')
        const {sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const toolReturns = ref([])
        const showToolsToReturn = ref(false)

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getToolReturns()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const getToolReturns = async (type)=>{
            type !== 'silent' ? isWorking.value = true : null
            responseError.value = null
            let body = {projectId:props.projectId}
            await api
            .post(`shipments/toolReturns`,body)
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? toolReturns.value = res.data.data
                :null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
                console.error(err.response?.data?.error || err.message)
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const toolsReturnsFiltered = computed(()=>{
            return toolReturns.value.filter(x=>!x.returnShipmentId && x.returnDate && x.shipmentId)
        })

        const toolPickupsSorted = computed(()=>{
            const sorted = toolsReturnsFiltered.value.slice().sort((a, b) => {
                // Parse the dates
                const dateA = parseISO(a.returnDate);
                const dateB = parseISO(b.returnDate);

                // Validate the dates
                const isDateAValid = isValid(dateA);
                const isDateBValid = isValid(dateB);

                // Handle invalid or missing dates
                if (!isDateAValid && !isDateBValid) {
                    return 0; // Both dates are invalid or missing, consider them equal
                } else if (!isDateAValid) {
                    return 1; // a's date is invalid, sort a after b
                } else if (!isDateBValid) {
                    return -1; // b's date is invalid, sort b after a
                }

                // Compare the valid dates
                return compareAsc(dateA, dateB);
            });

            const groupedArray = []

            sorted.forEach((tool) => {
                const projectId = tool.shipmentId?.projectId?._id;
                const projectName = tool.shipmentId?.projectId?.name;
                const googlePlacesId = tool.shipmentId?.shipmentAddress?.googlePlacesId;
                const shipmentAddress = tool.shipmentId?.shipmentAddress || {};

                if (!projectId || !googlePlacesId || !projectName) {
                    return;
                }

                // Find or create the project group in the array
                let projectGroup = groupedArray.find(group => group.projectId === projectId);

                if (!projectGroup) {
                    projectGroup = {
                        projectId,
                        projectName,
                        addresses: [],
                        toolCount:0
                    };
                    groupedArray.push(projectGroup);
                }

                projectGroup.toolCount +=1

                // Find or create the address subgroup within the project group
                let addressGroup = projectGroup.addresses.find(address => address.googlePlacesId === googlePlacesId);

                if (!addressGroup) {
                    addressGroup = {
                        googlePlacesId,
                        shipmentAddress,
                        tools: []
                    };
                    projectGroup.addresses.push(addressGroup);
                }

                // Add the tool to the appropriate address group

                addressGroup.tools.push(tool);
            });

            return groupedArray;

        })

        const selectAll = (tools)=>{
            if(Array.isArray(tools)) {
                tools.forEach(tool=>tool.selected = true)
            }
        }

        const update = (event)=>{
            if(Array.isArray(event.tools) && event.tools.length > 0) {
                event.tools.forEach(tool=>{
                    let index = toolReturns.value.findIndex(x=>x._id === tool._id)
                    if(index > -1) {
                        toolReturns.value[index] = tool
                    }
                    sendChangeEvent('tool','','',{toolId:tool._id,shipmentId:tool.shipmentId?._id || tool.shipmentId})
                })
            }
            emit("update",event)
        }

        const toolSocketUpdate = async (id)=>{
            await api
            .get(`tools/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    if(res.data?.data?.returnDate) {
                        if(!props.projectId || (props.projectId === res.data?.data?.shipmentId?.projectId?._id)) {
                            let toolIndex = toolReturns.value.findIndex(x=>x._id === res.data?.data?._id)
                            if(toolIndex > -1) {
                                toolReturns.value[toolIndex] = res.data.data
                            } else {
                                toolReturns.value.push(res.data.data)
                            }
                        }
                    } else {
                        let toolIndex = toolReturns.value.findIndex(x=>x._id === res.data?.data?._id)
                        if(toolIndex > -1) {
                            toolReturns.value.splice(toolIndex,1)
                        }
                    }
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type && e.detail?.type === 'tool') {
                if(e.detail?.data?.toolId) {
                    toolSocketUpdate(e.detail.data.toolId)
                }
            }
        }

        return {
            isWorking,
            responseError,
            toolReturns,
            showToolsToReturn,
            toolPickupsSorted,
            isValid,
            parseISO,
            format,
            openDetails,
            selectAll,
            update,
            toolSocketUpdate,
            toolsReturnsFiltered
        }
    }

}
</script>

<style>

</style>