<template>
    <div class="w-full flex flex-col flex-grow items-center">
        <WorkingAndError :isWorking="gettingReportOptions || updatingReport" :error="responseError" />
        <div class="w-full flex flex-row space-x-2 items-center justify-end p-1 max-w-5xl">
            <div v-if="!lockReport"  class="flex items-center p-1">
                <button class="px-1 py-0.5" @click="createReport(false,report.reportType !== 'daily')">
                    <div>Preview PDF</div>
                </button>
            </div>
            <div v-if="lockReport && !report.isSent" class="flex items-center p-1">
                <Popper hover arrow placement="top" :content="'Delete pdf and unlock.'">
                    <button class="px-2 py-0.5" @click="unlockReport()" :disabled="updatingReport">
                        <LockOpenIcon />
                        <div>Edit</div>
                    </button>
                </Popper>
            </div>
            <div v-if="lockReport && !report.isSent && report.googleId" class="flex items-center p-1">
                <Popper hover arrow placement="top" :content="'View Report.'">
                    <button class="px-2 py-0.5" @click="viewReport()" :disabled="updatingReport || creatingDocument">
                        <div>View</div>
                        <FileTypePdf />
                    </button>
                </Popper>
            </div>
            <div v-if="report.reportType === 'daily' && !lockReport" class="flex items-center p-1">
                <Popper hover arrow placement="top" :content="'Add daily photos and finalize'">
                    <button class="px-1 py-0.5" @click="createReport(true,true)">
                        <div>+ Photos & Finalize</div>
                    </button>
                </Popper>
            </div>
        </div>
        <div class="w-full flex flex-grow flex-auto h-0 overflow-y-auto p-3 justify-start lg:justify-center">
            <div class="bg-white shadow-sm py-3 px-6 w-full flex flex-grow text-black" :style="'min-width:850px;min-height:1100px;max-width:850px'">
            <!-- Project Info -->
                <div class="flex flex-col space-y-1 w-full" id="report-element">
                    <div class="w-full p-2" id="header">
                        <div v-if="project.projectClientId?.logoId  && project.projectClientId?.logoId != ''" class="flex items-center justify-items-start">
                            <img :src="`https://drive.google.com/thumbnail?authuser=0&size=h100&id=${project.projectClientId.logoId}`" alt="DLS" onerror="this.style.display='none';this.nextElementSibling.style.display='block';" class="object-contain align-middle border-none w-32 h-16" id="logo-image" />
                            <div class="w-full text-left whitespace-normal truncate px-1 bg-transparent text-lg font-semibold" style="display:none;">{{project.projectClientId?.name}}</div>
                        </div>
                        <div v-else class="w-full font-semibold text-left text-xl">
                            {{project.projectClientId?.name}}
                        </div>
                    </div>
                    <div v-if="report.reportType" class="w-full p-2 border-b border-t font-semibold">{{reportTitle}}</div>
                    <div v-if="report.reportDate && isValid(parseISO(report.reportDate))" class="w-full flex p-2 items-center justify-start font-semibold">{{format(parseISO(report.reportDate),'MMM d, yyyy')}}</div>
                    <div class="flex flex-col flex-grow space-y-2 w-full text-sm">
                        <div class="flex flex-row items-start w-full space-x-2">
                            <div class="w-full flex flex-col space-y-1">
                                <div class="w-full bg-neutral-500 text-white p-1 flex">Project Info</div>
                                <div class="w-full text-left font-semibold whitespace-normal px-1">{{project?.name}}</div>
                                <div class="w-full text-left whitespace-normal px-1">{{project.clientProjectNumber}}</div>
                                <div class="w-full text-left whitespace-normal px-1">{{project.clientMgrId?.name || 'No PM Assigned.'}}</div>
                            </div>
                            <div v-if="project.projectAddress" class="w-full flex flex-col space-y-2">
                                <div class="w-full bg-neutral-500 text-white p-1 flex">Project Address</div>
                                <div class="px-1">
                                    <DisplayAddress :address="project.projectAddress" :showAddressTwo="true" />
                                </div>
                            </div>
                        </div>
            <!-- Tasks -->
                        <div class="w-full flex-auto h-0 overflow-y-auto flex flex-col space-y-1">
                            <div v-if="report.reportType !== 'service'" class="w-full" >
                                <div class="w-full bg-neutral-500 text-white p-1 flex flex-row space-x-1 items-center truncate">
                                    <div class="w-full px-1 text-left whitespace-normal">Tasks Completed</div>
                                    <div v-if="gettingReportOptions" class="w-full"><LoadingData /></div>
                                    <div v-else class="w-full">
                                        <div v-if="Object.keys(reportTaskOptionsFiltered).length > 0" class="w-full">
                                            <select class="w-full max-w-sm " v-model="taskSelect" @change="addItem('completedTasks',taskSelect)" :disabled="updatingReport || lockReport" >
                                                <option hidden :value="{}">Add Task</option>
                                                <option v-for="option in reportTaskOptionsFiltered" :key="option._id" :value="{name:option.taskName,text:''}">{{option.taskName}}</option>
                                                <option :value="{name:'',text:''}">custom</option>
                                            </select>
                                        </div>
                                        <div v-else class="flex w-full justify-end">
                                            <button class="bn-icon-small bn-solid-green" @click="addItem('completedTasks',{name:'',text:''})" :disabled="lockReport">
                                                <PlusIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="Array.isArray(report?.completedTasks) && report.completedTasks.length > 0" class="w-full p-1 flex flex-col space-y-2">
                                    <div v-for="(task,index) in report.completedTasks" :key="index" class="w-full flex flex-row flex-wrap space-y-1 sm:flex-nowrap sm:space-y-0 sm:space-x-2 py-1 items-start border-b">
                                        <input v-model="task.name" type="text" class="w-full sm:w-52 bg-neutral-100 border border-neutral-200 text-neutral-700 truncate px-1 text-left h-6 focus:ring-yellow-200" maxlength="25" :disabled="reportTaskOptions.map((x)=>x.taskName).includes(task.name) || updatingReport || lockReport" 
                                        @focus="prevValue = task.name"
                                        @blur="prevValue !== task.name ? updateItem('completedTasks',task._id,{name:task.name}) : null"
                                        >
                                        <textarea v-model="task.text" v-auto-resize class="w-full h-max text-left flex-grow bg-neutral-100 border border-neutral-200 text-neutral-700 focus:ring-yellow-200" spellcheck="true" 
                                        @input="autoResize($event)" @paste="autoResize($event)" maxlength="1000" 
                                        @focus="prevValue = task.text" @blur="prevValue !== task.text ? updateItem('completedTasks',task._id,{text:task.text}) : null" :disabled="updatingReport || lockReport" />
                                        <div v-if="!lockReport">
                                            <Popper hover arrow placement="top" :content="'delete task'">
                                                <button class="bn-icon-only text-red-600 hover:text-red-400" @click="deleteItem('completedTasks',task._id)" :disabled="updatingReport">
                                                    <TrashIcon />
                                                </button>
                                            </Popper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="w-full flex flex-col space-y-1">
                                <div class="w-full bg-neutral-500 text-white p-1 flex flex-row">
                                    <div class="w-full px-1 text-left">Issue Reported</div>
                                    <button class="bn-icon-small bn-green" @click="addItem('serviceIssue',{text:''})" :disabled="lockReport">
                                        <PlusIcon />
                                    </button>
                                </div>
                                <div v-if="Object.keys(report?.serviceIssue).length > 0" class="w-full flex flex-col space-y-1 p-1">
                                    <div v-for="serviceIssue in report.serviceIssue" :key="serviceIssue._id" class="w-full flex flex-row items-center space-x-2 border-b p-1">
                                        <textarea v-model="serviceIssue.text" v-auto-resize class="w-full text-left bg-neutral-100 border border-neutral-200 text-neutral-700 focus:ring-yellow-200" maxlength="1000" spellcheck="true" 
                                        @focus="prevValue = serviceIssue.text " @input="autoResize($event)" @paste="autoResize($event)" 
                                        @blur="prevValue !== serviceIssue.text ? updateItem('serviceIssue',serviceIssue._id,{text:serviceIssue.text}) : null" :disabled="updatingReport || report.isComplete" />
                                        <div v-if="!lockReport">
                                            <Popper hover arrow placement="top" :content="'delete issue'">
                                                <button class="bn-icon-only text-red-600 hover:text-red-400" @click="deleteItem('serviceIssue',serviceIssue._id)" :disabled="updatingReport">
                                                    <TrashIcon />
                                                </button>
                                            </Popper>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <!-- Issues -->
                            <div v-if="report?.reportType !== 'service'" class="w-full flex flex-col space-y-1">
                                <div  class="w-full bg-red-700 text-white p-1 flex flex-row items-center">
                                    <div class="w-full px-1 text-left">Items That Need Attention</div>
                                    <button class="bn-icon-small bn-green" @click="addItem('issues',{text:''})" :disabled="lockReport">
                                        <PlusIcon />
                                    </button>
                                </div>
                                <div v-if="Object.keys(report?.issues).length > 0" class="w-full p-1 flex flex-col space-y-2">
                                    <div v-for="(issue,index) in report?.issues" :key="index" class="w-full flex flex-row flex-wrap space-y-1 sm:flex-nowrap sm:space-y-0 sm:space-x-2 py-1 items-start border-b">
                                        <textarea v-model="issue.text" v-auto-resize class="w-full text-left flex-grow bg-neutral-100 border border-neutral-200 text-neutral-700 focus:ring-yellow-200" maxlength="1000" spellcheck="true" 
                                        @focus="preValue = issue.text" @input="autoResize($event)" @paste="autoResize($event)" 
                                        @blur="prevValue !== issue.text ? updateItem('issues',issue._id,{text:issue.text}) : null" :disabled="report.isComplete"></textarea>
                                        <div v-if="!lockReport">
                                            <Popper hover arrow placement="top" :content="'delete issue'">
                                                <button class="bn-icon-only text-red-600 hover:text-red-400" @click="deleteItem('issues',issue._id)">
                                                    <TrashIcon />
                                                </button>
                                            </Popper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="w-full flex flex-col space-y-1">
                                <div class="w-full bg-neutral-500 text-white p-1 flex flex-row">
                                    <div class="w-full px-1 text-left">Service Performed</div>
                                    <button class="bn-icon-small bn-green" @click="addItem('servicePerformed',{text:''})" :disabled="lockReport">
                                        <PlusIcon />
                                    </button>
                                </div>
                                <div v-if="Object.keys(report?.servicePerformed).length > 0" class="w-full flex flex-col space-y-1 p-1">
                                    <div v-for="service in report.servicePerformed" :key="service._id" class="w-full flex flex-row items-center space-x-2 border-b p-1">
                                        <textarea v-model="service.text" v-auto-resize class="w-full left bg-neutral-100 border border-neutral-200 text-neutral-700 focus:ring-yellow-200" maxlength="1000" spellcheck="true" 
                                        @input="autoResize($event)" @paste="autoResize($event)" 
                                        @focus="prevValue = service.text " @blur="prevValue !== service.text ? updateItem('servicePerformed',service._id,{text:service.text}) : null" :disabled="updatingReport" />
                                        <div v-if="!lockReport">
                                            <Popper hover arrow placement="top" :content="'add service performed'">
                                                <button class="bn-icon-only text-red-600 hover:text-red-400" @click="deleteItem('servicePerformed',service._id)" :disabled="updatingReport">
                                                    <TrashIcon />
                                                </button>
                                            </Popper>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <!-- Technicians -->
                            <div v-if="report.reportType === 'service' || (Array.isArray(reportTechnicians) && reportTechnicians.length > 0) " class="w-full flex flex-col space-y-2">
                                <div class="w-full bg-neutral-500 text-white p-1 flex flex-row items-center">
                                    <div class="w-full px-1 text-left">Technicians</div>
                                    <button v-if="report.reportType !== 'daily'" class="bn-icon-small bn-green" @click="addItem('technicians',{name:''})" :disabled="updatingReport || lockReport">
                                        <PlusIcon />
                                    </button>
                                </div>
                                <div v-if="report.reportType === 'daily'" class="w-full grid gap-2 p-2" :class="(Array.isArray(reportTechnicians) && reportTechnicians.length < 3) ?'grid-cols-2' :'grid-cols-3' ">
                                    <div v-for="appt in reportTechnicians" :key="appt" class="text-center truncate">
                                        {{appt}}
                                    </div>
                                </div>
                                <div v-else class="w-full flex flex-col p-1">
                                    <div v-if="Object.keys(report?.technicians).length !== 0" class="w-full flex flex-col space-y-1">
                                        <div v-for="tech in report.technicians" :key="tech._id" class="w-full flex items-center flex-row space-x-1">
                                            <div class="w-full flex-grow flex items-start">
                                                <input v-model="tech.name" type="text" class="w-full max-w-lg bg-neutral-100 border border-neutral-200 text-neutral-700"  maxlength="25" 
                                                @focus="prevValue = tech.name" @blur="prevValue !== tech.name ? updateItem('technicians',tech._id,{name:tech.name}) : null" :disabled="updatingReport || lockReport" />
                                            </div>
                                            <div v-if="!lockReport" class="px-1">
                                                <Popper hover arrow placement="top" :content="'delete tech'">
                                                    <button class="bn-icon-only text-red-600 hover:text-red-400" @click="deleteItem('technicians',tech._id)">
                                                        <TrashIcon />
                                                    </button>
                                                </Popper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="report.reportType === 'daily'" class="w-full flex whitespace-normal p-1">
                            {{securePhotoLink}}
                        </div>
                        <div class="w-full border-t p-1 flex flex-row items-center">
                            <div class="w-full text-left text-sm whitespace-normal"><span class="text-sm font-semibold pr-1">project #:</span>{{project.companyProjectNumber}}</div>
                            <div class="w-full text-center whitespace-normal"><span class="text-sm font-semibold pr-1">prepared by:</span> {{preparedBy}}</div>
                            <div class="w-full flex flex-row items-center justify-end px-1 whitespace-normal"><img src="../../../assets/favicon.png" alt="" class="h-12" id="footer-logo"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="creatingDocument || blobURL" @closeModal="closeDocument()">
        <template v-slot:content>
            <WorkingAndError :isWorking="creatingDocument" :error="documentError" :message="documentProcessState" />
            <div v-if="downloadFileErrors > 0" class="w-full text-center error whitespace-normal p-1" :class="{'pt-10':creatingDocument}">
                {{`Unable to download ${downloadFileErrors} photos.`}}
            </div>
            <div v-if="blobURL" class="w-full flex flex-row items-center justify-end truncate p-2">
                <button v-if="report.isComplete && !report.isSent" class="px-1 py-0.5 bn-solid-green"
                @click="sendReport()"
                :disabled="creatingDocument"
                >
                    <div>Send</div>
                    <MailIcon />
                </button>
                <button v-else-if="blobURL && canComplete && !report.isSent" class="py-0.5 px-2" @click="completeReport()" :disabled="creatingDocument">
                    <div>Complete & Upload</div>
                </button>
            </div>
            <div v-if="blobURL" class="w-full flex flex-col flex-grow">
                <iframe :src="blobURL" class="w-full h-full"/>
            </div>
        </template>
    </Modal>
</template>

<script>
import {isValid,format,parseISO, endOfDay, startOfDay} from 'date-fns'
import {capitalizeFirstLetter} from '@/shared'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import {PlusIcon,TrashIcon,MailIcon,LockOpenIcon} from '@heroicons/vue/outline'
import FileTypePdf from '@/components/customIcons/FileTypePdf.vue'
import { computed, inject, onMounted, ref } from 'vue'
import {Buffer} from 'buffer'
import api from '@/api'
import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios'
import autoResize from '@/components/ui/autoResize'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    directives:{
        autoResize
    },
    props:{
        project:{type:Object,default:()=>{return {}}},
        report:{type:Object,default:()=>{return {}}}
    },
    components:{DisplayAddress,PlusIcon,TrashIcon,MailIcon,LockOpenIcon,FileTypePdf},
    emits:["reportUpdate"],
    setup (props,{emit}) {
        const global = inject('global')
        const {authenticated,setModalBlocked} = global
        const preparedBy = ref(authenticated.value.name)
        const responseError = ref(null)
        const documentError = ref(null)
        const gettingReportOptions = ref(false)
        const reportTaskOptions = ref([])
        const updatingReport = ref(false)
        const taskSelect = ref({})
        const reportTechnicians = ref([])
        const projectPhotos = ref([])
        const rooms = ref([])

        const creatingDocument = ref(false)
        const customerImageURL = ref('')
        const footerImage64 = ref('')
        const reportBlob = ref(null)
        const blobURL = ref(null)
        const documentProcessState = ref(null)
        const downloadCount = ref(0)
        const downloadFileErrors = ref(0)
        const canComplete = ref(false)

        const reportTitle = `${capitalizeFirstLetter(props.report?.reportType)} ${props.report?.reportType === 'daily' ? 'Site' : ''} Report`
        const fileName = `${props.project?.companyProjectNumber ? props.project.companyProjectNumber + '-' : null}${reportTitle}-${isValid(parseISO(props.report?.reportDate)) ? format(parseISO(props.report?.reportDate), 'MMM d, yyyy') : null}${props.project?.name ? '-' + props.project.name : null }${props.project?.clientProjectNumber  ? '-' + props.project?.clientProjectNumber : null}`
        
        onMounted(()=>{
            getReportTaskOptions()
            if(props.report.reportType === 'daily') {
                getReportTechnicians()
                getLocationOptions()
            }
        })

        const securePhotoLink = computed(()=>{
            return props.project?.secureCode ? `${window.location.protocol}//${window.location.host}/external/photos/${props.project?._id}/${props.project?.secureCode}` : 'Not Available'
        })

        const lockReport = computed(()=>{
            return props.report?.isComplete && props.report?.googleId
        })

        const reportTaskOptionsFiltered = computed(()=>{
            return reportTaskOptions.value.filter(x=>x.associatedReports.includes(props.report.reportType))
        })

        const locationOptions = computed(()=>{
            const locations = rooms.value.map(room => {
                const roomOptions = [
                { id: room._id, name: room.nameOne || room.nameTwo || `No Name - ${room.roomType}`}
                ];

                if (room.racks && room.racks.length > 0) {
                const rackOptions = room.racks.map(rack => ({ id: rack._id, name: ` - Rack: ${rack.name}`}));
                roomOptions.push(...rackOptions);
                }

                return roomOptions;
            });

            return locations.flat();
        })

        const getReportTaskOptions = async (type)=>{
            gettingReportOptions.value = type !== 'silent' ? true : false
            await api.get('reportTasks')
            .then((res)=>{
                Array.isArray(res.data.data)
                ? reportTaskOptions.value = res.data.data
                : null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.err || err.message || 'Unable to load Report Tasks'
            })
            .finally(()=>{
                gettingReportOptions.value = false
            })
        }

        const getReportTechnicians = async ()=>{
            let body = {
                start:startOfDay(parseISO(props.report.reportDate)),
                end:endOfDay(parseISO(props.report.reportDate))
            }
            await api.post(`reports/technicians/${props.report?._id}`,body)
            .then((res)=>{
                    if(Array.isArray(res.data.data)) {
                        reportTechnicians.value = res.data.data
                    }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.err || err.message || 'Unable to load Report Tasks'
            })
            .finally(()=>{
                gettingReportOptions.value = false
            })
        }

        const getLocationOptions = async ()=>{
            await api
            .get("projects/assignedLocationOptions/" + props.project._id)
            .then((res)=>{
                if(Array.isArray(res.data?.data)) {
                    rooms.value = res.data.data
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
            })
        }

        const addItem = async (array,data)=>{
            updatingReport.value = true
            await api
            .post(`reports/editReport/${props.report?._id}/${array}`,data)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data ) {
                    emit("reportUpdate",res.data.data)
                    taskSelect.value = {}
                }
            })
            .catch((err)=>{
                console.error(err)
            })
            .finally(()=>{
                updatingReport.value = false
            })
        }

        const updateItem = async (array,id,body)=>{
            updatingReport.value = true
            await api
            .put(`reports/editReport/${props.report?._id}/${array}/${id}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data ) {
                    emit("reportUpdate",res.data.data)
                    //global.sendChangeEvent('report',this.project._id,'',{})
                }
            })
            .catch((err)=>{
                console.error(err)
            })
            .finally(()=>{
                updatingReport.value = false
            })
        }

        const deleteItem = async (array,id)=>{
            updatingReport.value = true
            await api
            .delete(`reports/editReport/${props.report?._id}/${array}/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data ) {
                emit("reportUpdate",res.data.data)
                //global.sendChangeEvent('report',this.project._id,'',{projectId:this.project._id})
                }
            })
            .catch((err)=>{
                console.error(err)
            })
            .finally(()=>{
                updatingReport.value = false
            })
        }

        const formatDate =  (date)=> {
            return format(date, 'ccc MMM dd, yyyy')
        }

        const loadImageAsBase64 = async (url)=> {
            try {
                const response = await axios.get(url, { responseType: 'arraybuffer' });
                const base64 = Buffer.from(response.data, 'binary').toString('base64');
                return `data:image/png;base64,${base64}`;
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }

        const splitArrayIntoGroups =  async (array) =>{
            let result = [];
            if(Object.keys(array).length > 0){
                for (let i = 0; i < array.length; i += 3) {
                    const group = array.slice(i, i + 3);
                    result.push(group);
                }
                if(Object.keys(array).length !== 0) {
                    if(result[result.length - 1].length === 1) {
                        result[result.length - 1].push("","")
                    } else if(result[result.length - 1].length === 2) {
                        result[result.length - 1].push("")
                    }
                }
            }
            return result.length > 0 ? result : ['','','']
        }

        const closeDocument = ()=>{
            creatingDocument.value = false
            canComplete.value = false
            if(blobURL.value) {
                URL.revokeObjectURL(blobURL.value)
                blobURL.value = null
            }
            downloadCount.value = 0
            downloadFileErrors.value = 0
        }

        const createReport = async (addPhotos=false,canFinalize=false)=> {
            try {
                canComplete.value = canFinalize
                downloadCount.value = 0
                downloadFileErrors.value = 0
                creatingDocument.value = true
                setModalBlocked(true)
                documentProcessState.value = null
                let companyImageURL = ''

            try {
                documentProcessState.value = 'Adding Company Logo'
                companyImageURL = require('@assets/favicon.png')
                footerImage64.value = await loadImageAsBase64(companyImageURL)
            } catch (error) {
                console.error('Error loading image:', error);
            } finally {
                documentProcessState.value = null
            }

            documentProcessState.value = 'Adding Customer Logo'
            if(props.project?.projectClientId?._id) {
                await api.get(`reports/images/logo/${props.project?.projectClientId?._id}`,
                {
                    responseType:'arraybuffer'
                })
                .then((res)=>{
                    customerImageURL.value = Buffer.from(res.data)
                })
                .catch((err)=>{
                    console.error('Unable to download logo',err)
                })
                .finally(()=>{
                     documentProcessState.value = null
                })
            }

            documentProcessState.value = 'Adding Report Content'

            let tasks = []
            let addTasks
            for await (const completedTask of props.report.completedTasks) {
                tasks.push([{text:completedTask.name,bold:true},{text:completedTask.text}])
            }
            addTasks = Array.isArray(tasks) && tasks.length > 0 ? true : false

            let issues = []
            let addIssues = false

            for await (const issue of props.report.issues) {
                issues.push([{text:issue.text}])
            }
            addIssues = Array.isArray(issues) && issues.length > 0 ? true : false

            let issuesService = []
            let addIssuesService = false
            for await (const item of props.report.serviceIssue) {
                issuesService.push([{text:item.text}])
            }
            addIssuesService = Array.isArray(issuesService) && issuesService.length > 0 ? true : false

            let performed = []
            let addPerformed = false
            for await (const item of props.report.servicePerformed) {
                performed.push([{text:item.text}])
            }
            addPerformed = Array.isArray(performed) && performed.length > 0 ? true : false

            documentProcessState.value = 'Adding Technicians'

            let technicians = []
            let techNames
            let addTechs = false

            if(props.report.reportType === 'daily') {
                //technicians = reportTechnicians.value
                for await (const tech of reportTechnicians.value) {
                    technicians.push([{text:tech,alignment:'center'}])
                }
            } else {
                techNames = props.report.technicians.map((x)=>x.name)
                for await (const tech of techNames) {
                    if (!technicians.includes(tech)) {
                        technicians.push(tech);
                    }
                }
            }

            addTechs = Array.isArray(technicians) && technicians.length > 0 ? true : false

            const techReportRows = await splitArrayIntoGroups(technicians)


            let photosToAdd = false
            let reportPhotos = []
            if(addPhotos && props.report?.reportType === 'daily') {
                documentProcessState.value = 'Getting Project Photos.'
                await api
                .get(`reports/images/photos/${props.report?._id}`)
                .then((res)=>{
                    if(Array.isArray(res.data.data)) {
                        projectPhotos.value = res.data.data
                    }
                })
                .catch((err)=>{
                    throw err
                })
                .finally(()=>{
                    documentProcessState.value = null
                })

                documentProcessState.value = 'Adding Project Photos.'
                for await (let photo of projectPhotos.value) {
                    await api.get(`reports/images/download/${props.project?._id}/${photo.id}`,{
                        responseType:'arraybuffer'
                    })
                    .then(async (res)=>{
                        reportPhotos.push([{image:Buffer.from(res.data),fit:[175,150],margin:[5,5],alignment:'center'},{text:getLocationName(photo.properties?.location) || '',alignment:'center'}])
                        downloadCount.value++
                        documentProcessState.value = `${downloadCount.value} of ${projectPhotos.value.length} photos added`
                    })
                    .catch(()=>{
                        console.error('Couldnt download file')
                        downloadFileErrors.value++
                    })
                }
                photosToAdd = Array.isArray(reportPhotos) && reportPhotos.length > 0 ? true : false
                reportPhotos = await splitArrayIntoGroups(reportPhotos)
                documentProcessState.value = null
            }

            pdfMake.tableLayouts = {
                topHeader: {
                    hLineWidth: function () {
                    return 1
                    },
                    vLineWidth: function () {
                        return 0
                    },
                    hLineColor: function () {
                    return  'gray' 
                    },
                    vLineColor: function () {
                    return '';
                    },
                    paddingTop: function () {
                    return 6;
                    },
                    paddingBottom: function () {
                    return 6;
                    }
                },
                standardRow: {
                    hLineWidth:()=>{return 0},
                    vLineWidth:()=>{return 0},
                    paddingTop: function () {
                    return 6;
                    },
                    paddingBottom: function () {
                    return 6;
                    }
                },
                condensed: {
                    hLineWidth:()=>{return 0},
                    vLineWidth:()=>{return 0},
                    paddingTop: function () {
                    return 3;
                    },
                    paddingBottom: function () {
                    return 3;
                    }
                },
                items: {
                    hLineWidth:(i,node)=>{
                        return i !== 0 && i !== node.table.body.length ? .3 : 0
                    },
                    hLineColor: function() {
                        return 'lightgray'
                    },
                    vLineWidth:()=>{return 0},
                    paddingTop: function () {
                    return 5;
                    },
                    paddingBottom: function () {
                    return 5;
                    }
                },
                topBorderOnly:{
                    hLineWidth: function(i) {
                        return (i === 0) ? 1: 0
                    },
                    vLineWidth: function() {
                        return 0; // No vertical borders
                    },
                    hLineColor: function() {
                        return 'gray'; // Color of the top border
                    }
                }
            };

            let docDefinition = {
                pageSize:'LETTER',
                pageOrientation: 'portrait',
                pageMargins: [12,60,12,75],
                font:'Helvetica',
                info:{
                    title:fileName,
                    author:preparedBy.value
                },
                header:  [
                    customerImageURL.value !== '' 
                    ?
                        {
                            image: customerImageURL.value,
                            fit: [400,55],
                            margin: [5, 0, 5, 0]
                        }
                    :
                        {
                            text: props.project?.projectClientId?.name || 'Digital Labor Solutions',
                            alignment:'left',
                            fontSize: 14,
                            bold:true,
                            margin: [10, (55 - 14) / 2, 10, (55 - 14) / 2],
                            width:400
                        }
                ],
                footer: {
                    stack: [
                        {
                            text: '*Reports are produced on site and may contain spelling or grammatical errors.',
                            bold: true,
                            margin: [5, 0, 0, 5]
                        },
                        {
                            table: {
                                widths: [550, 50],
                                body: [
                                    [
                                        {
                                            stack: [
                                                {
                                                    table: {
                                                        widths: [200, 300],
                                                        body: [
                                                            [
                                                                { text: `${props.project?.clientProjectNumber  || props.project?.companyProjectNumber || ''}`, noWrap: false, bold: true }, 
                                                                { text: `Prepared by: ${preparedBy.value}`, noWrap: false, bold: true }
                                                            ]
                                                        ]
                                                    },
                                                    layout: 'noBorders',
                                                    margin: [5, 12, 5, 5]
                                                }
                                            ],
                                            margin: [0, 0, 0, 0]
                                        },
                                        {
                                            image: footerImage64.value,
                                            fit: [50, 50], // Ensure the image fits within the cell
                                            alignment: 'center',
                                            margin: [0, 0, 0, 0]
                                        }
                                    ]
                                ],
                            },
                            layout: 'topBorderOnly',
                            margin: [0, 0, 0, 0]
                        }
                    ],
                    margin: [0, 0, 0, 0]
                },
                content:[
                //Project Info
                    {
                        layout: 'topHeader', // optional
                        table: {
                            headerRows: 1,
                            widths: [ 580 ],
                            body: [
                            [ {text:reportTitle,style:'heading'} ],
                            ]
                        }
                    },
                    {
                        layout: 'standardRow', // optional
                        table: {
                            headerRows: 1,
                            widths: [ 580 ],
                            body: [
                            [ {text:formatDate(new Date(props.report?.reportDate)),style:'date',bold:true} ],
                            ]
                        }
                    },
                    {
                        columns:[
                            {
                                width:294,
                                layout: 'condensed', // optional
                                margin:[2,0,2,0],
                                table: {
                                    // headers are automatically repeated if the table spans over multiple pages
                                    // you can declare how many rows should be treated as headers
                                    headerRows: 1,
                                    widths:[282,282,282,282],
                                    body: [
                                    [{text:'Project Info',fillColor:'gray',color:'white',style:'tableHeading'} ],
                                    [{text:props.project?.name,bold:true}],
                                    [{text:props.project?.clientProjectNumber,fontSize:11}],
                                    [{text:`PM: ${props.project?.clientMgrId?.name || 'No PM Assigned.'}`, fontSize:11}]
                                    ]
                                }
                            },
                            {
                                width:294,
                                layout: 'condensed', // optional
                                margin:[2,0,2,0],
                                table: {
                                    widths:[282,282,282,282],
                                    body: (function() {
                                        const body = [
                                            [{ text: 'Project Address', fillColor: 'gray', color: 'white', style: 'tableHeading' }],
                                            [{ text: `${props.project?.projectAddress?.street_number} ${props.project?.projectAddress?.street}` }]
                                        ];
                                        if (props.project?.projectAddress?.addressTwo) {
                                            body.push([{ text: props.project?.projectAddress?.addressTwo }]);
                                        }
                                        body.push([{ text: `${props.project?.projectAddress?.city}, ${props.project?.projectAddress?.state} ${props.project?.projectAddress?.postalCode}` }]);
                                        return body;
                                    })()
                                }
                            },
                        ]
                    },
                //Tasks && Service Issue
                    {
                        layout:'condensed',
                        margin:[0,5,0,5],
                        table:{
                            headerRows:1,
                            widths:[580],
                            body: [
                                [ {text:props.report?.reportType === 'service' ? 'Issue Reported' : 'Tasks',fillColor:'gray',color:'white',style:'tableHeading'} ],
                            ]
                        }
                    },
                    (props.report?.reportType !== 'service' && addTasks)
                    ?
                        (
                            {
                                layout: 'items',
                                margin:[0,0,0,5],
                                table:{
                                    widths: [ 150,430],
                                    body:tasks
                                }
                            }
                        ) 
                    :
                    null,
                    (props.report?.reportType === 'service' && addIssuesService)
                    ?
                        (
                            {
                                layout: 'items',
                                margin:[0,0,0,5],
                                table:{
                                    widths: ['*'],
                                    body:issuesService
                                }
                            }
                        )
                    :
                    null,
                //Issues & Service Performed
                    {
                        layout: 'condensed', // optional
                        margin:[0,0,0,5],
                        table: 
                            {
                                // headers are automatically repeated if the table spans over multiple pages
                                // you can declare how many rows should be treated as headers
                                headerRows: 1,
                                widths: [ '*' ],
                                body: [
                                [ {text: props.report?.reportType === 'service' ? 'Service Performed':'Items That Need Client Attention.',fillColor: props.report?.reportType === 'service' ? 'gray':'#ce162b',color:'white',style:'tableHeading'} ],
                                ]
                            }
                    },
                    (props.report?.reportType !== 'service' && addIssues)
                    ?
                        (
                            {
                                layout: 'items',
                                margin:[0,0,0,5],
                                table:{
                                    widths: ['*'],
                                    body:issues
                                }
                            }
                        )
                    :
                    null,
                    (props.report?.reportType === 'service' && addPerformed)
                    ?
                        (
                            {
                                layout: 'items',
                                margin:[0,0,0,5],
                                table:{
                                    widths: ['*'],
                                    body:performed
                                }
                            }
                        ) 
                    :
                    null,
                    addTechs 
                    ?
                        (
                            {
                                layout: 'condensed', // optional
                                margin:[0,0,0,5],
                                table: {
                                    // headers are automatically repeated if the table spans over multiple pages
                                    // you can declare how many rows should be treated as headers
                                    headerRows: 1,
                                    widths: [ '*' ],
                                    body: [
                                    [ {text:'Technicians',fillColor:'gray',color:'white',style:'tableHeading'} ],
                                    ]
                                }
                            }
                        )
                    :
                    null,
                    addTechs 
                    ?
                        (
                            {
                                layout: 'items',
                                margin:[0,0,0,5],
                                table:{
                                    headerRows:0,
                                    widths: [192,192,192],
                                    body: 
                                        techReportRows
                                }
                            }
                        )
                    : 
                    null,
                    (addPhotos && props.report?.reportType === 'daily' && photosToAdd )
                    ?
                        (
                            {
                                layout: 'condensed', // optional
                                headlineLevel:2,
                                margin:[0,0,0,5],
                                table: {
                                    // headers are automatically repeated if the table spans over multiple pages
                                    // you can declare how many rows should be treated as headers
                                    headerRows: 1,
                                    widths: [ '*' ],
                                    body: [
                                    [ {text:'Site Photos',fillColor:'gray',style:'tableHeading'} ],
                                    ]
                                }
                            }
                        )
                    :
                        null,
                    (addPhotos && props.report?.reportType === 'daily' && photosToAdd )
                    ?
                        (
                            {
                                //columns: [
                                //    addPhotos ?  this.reportPhotos : null
                                //]

                                layout: 'items',
                                margin:[0,0,0,5],
                                headerRows: 0,
                                table:{
                                    widths: [192,192,192],
                                    body:reportPhotos
                                }

                            }
                        )
                    :
                        null,
                    (addPhotos && props.report?.reportType === 'daily' && securePhotoLink.value )
                    ?
                    {
                        text: 'Click to view more project photos.',
                        link:securePhotoLink.value,
                        color:'#4A90E2',
                        bold:true,
                        decoration:'underline',
                        margin: [10, 10, 10, 10]
                    }
                    : 
                    null
                ],
                pageBreakBefore:function(currentNode){
                        return currentNode.headlineLevel === 2
                },
                styles:{
                    heading:{
                        fontSize:14,
                        bold:true,
                        alignment: 'center'
                    },
                    date:{
                        fontSize:16,
                        bold:true,
                        alignment:'justify'
                    },
                    tableHeading:{
                        color:'#fff',
                    }
                },
                defaultStyle: {
                    columnGap: 10
                }
            }
            try {
                documentProcessState.value = 'Generating Report.'
                const pdfDocGenerator = pdfMake.createPdf(docDefinition)
                await new Promise((resolve,reject)=>{
                    pdfDocGenerator.getBlob((blob)=>{
                        try {
                            reportBlob.value = blob
                            blobURL.value = URL.createObjectURL(blob)
                            resolve(blob)
                        }  catch (error) {
                            reject(error);
                        }
                    })
                })
            } catch (error) {
                responseError.value = error.response?.data?.error || error.message || 'Error creating document.'
                console.error("Error creating PDF:", error);
            } finally {
                creatingDocument.value = false
                documentProcessState.value = null
                setModalBlocked(false)
            }
            } catch (err) {
                responseError.value = err.response?.data?.error || err.message || 'Failed to create document.'
                closeDocument()
            }
        }

        const completeReport = async ()=>{
            setModalBlocked(true)
            creatingDocument.value = true
            let documentName = `${props.project?.companyProjectNumber ? props.project.companyProjectNumber + '-' : null}${reportTitle}-${isValid(parseISO(props.report?.reportDate)) ? format(parseISO(props.report?.reportDate), 'MMM d, yyyy') : null}${props.project?.name ? '-' + props.project.name : null }${props.project?.clientProjectNumber  ? '-' + props.project?.clientProjectNumber : null}`
            let filename = `${documentName}.pdf`
            const formData = new FormData()
            const file = new Blob([reportBlob.value],{type: 'application/pdf'})
            formData.append('dlsFile',file,filename)
            formData.append('documentName',filename || null)
            formData.append('docPurpose','Report')
            formData.append('clientId',props.report?.projectId?.projectClientId?._id || null)
            formData.append('companyProjectNumber',props.report?.projectId?.companyProjectNumber || null)
            formData.append('projectId',props.report?.projectId?._id || null)

            await api
            .post(`reports/upload/${props.report._id}`,formData,{
                headers:{
                'Content-Type': 'multipart/form-data',
                }
            })
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("reportUpdate",res.data.data)
                } else {
                    responseError.value = 'Bad report data returned.'
                    closeDocument()
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message || 'Failed to upload document.'
                closeDocument()
            })
            .finally(()=>{
                creatingDocument.value = false
                setModalBlocked(false)
            })

        }

        const viewReport = async ()=>{
            blobURL.value = null
            responseError.value = null
            creatingDocument.value = true
            setModalBlocked(true)
            await api
            .get(`reports/download/${props.report?._id}`,{
                responseType:'blob'
            })
            .then((response)=>{
                 let blob = new Blob([response.data],{type:'application/pdf'})
                blobURL.value = URL.createObjectURL(blob)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message || 'Failed to get document.'
            })
            .finally(()=>{
                creatingDocument.value = false
                setModalBlocked(false)
            })
        }

        const unlockReport = async ()=>{
            setModalBlocked(true)
            updatingReport.value = true
            await api
            .put(`reports/unlock/${props.report._id}`,{})
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("reportUpdate",res.data.data)
                } else {
                    responseError.value = 'Bad report data returned.'
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message || 'Failed to upload document.'
            })
            .finally(()=>{
                updatingReport.value = false
                setModalBlocked(false)
            })
        }

        const sendReport = async()=>{
            creatingDocument.value = true
            documentError.value = null
            await api
            .get(`reports/send/${props.report?._id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("reportUpdate",res.data.data)
                } else {
                    responseError.value = 'Bad report data returned.'
                }
            })
            .catch((err)=>{
                documentError.value = err.response?.data?.error || err.message || 'Failed to upload document.'
            })
            .finally(()=>{
                creatingDocument.value = false
                setModalBlocked(false)
            })
        }

        const getLocationName = (id)=>{
            if(id && Array.isArray(locationOptions.value)) {
                let location = locationOptions.value.find(x=>x.id === id)
                if(location) {return location.name}
            }
        }

        const autoResize = (event) => {
            if (event.target) {
                event.target.style.height = 'auto';
                event.target.style.height = event.target.scrollHeight + 'px';
            }
        };

        return {
            responseError,
            documentError,
            isValid,
            format,
            parseISO,
            capitalizeFirstLetter,
            gettingReportOptions,
            reportTaskOptionsFiltered,
            updatingReport,
            taskSelect,
            addItem,
            reportTaskOptions,
            updateItem,
            deleteItem,
            preparedBy,
            reportTechnicians,
            createReport,
            creatingDocument,
            blobURL,
            closeDocument,
            reportTitle,
            documentProcessState,
            projectPhotos,
            downloadCount,
            downloadFileErrors,
            canComplete,
            completeReport,
            lockReport,
            autoResize,
            unlockReport,
            viewReport,
            sendReport,
            securePhotoLink
        }
    }

}
</script>

<style>

</style>