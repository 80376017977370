<template>
    <div class="w-full flex items-center flex-col text-xs">
        <div v-if="error" class="w-full error" :class="Array.isArray(error) && Object.keys(error).length > 1 ? 'overflow-y-auto h-12 py-1 px-2 shadow-inner rounded-md' : null">
            <div v-if="Array.isArray(error)" class="w-full flex flex-col space-y-1 justify-center truncate">
                <div v-if="Object.keys(error).length > 1" class="w-full text-left truncate border-b pointer-events-none">Multiple Errors</div>
                <div v-for="(item,index) in error" :key="index" class="w-full flex flex-row items-center space-x-2 pointer-events-none justify-center">
                    <ExclamationIcon class="w-4 h-4 flex flex-grow-0 flex-shrink-0" />
                    <div class="truncate text-left pointer-events-none">{{item}}</div>
                </div>
            </div>
            <div v-else class="w-full flex flex-row space-x-2 truncate px-2 items-center justify-center pointer-events-none">
                <ExclamationIcon class="w-4 h-4 flex flex-grow-0 flex-shrink-0" />
                <div class="truncate pointer-events-none">
                    {{
                        typeof error === 'string' 
                        ? ["500"].some(value => error.includes(value)) ? 'Could not connect to server.' : error 
                        : 'Error Occurred.'}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ExclamationIcon} from "@heroicons/vue/outline"

export default {
    components:{ExclamationIcon},
    props:{
        error:[String,Array]
    },
    data:()=>{
        return {
        }
    }
}
</script>

<style>

</style>