<template>
  <div class="w-full flex flex-row items-center justify-center space-x-3 py-1">
      <div class="flex flex-row items-baseline">
        <div class="animate-spin-slow">
            <CogIcon :class="size === 'large' ? 'h-10 w-10' : 'h-6 w-6'" />
        </div>
        <div class="animate-reverse-spin-slow" :class="size === 'large' ? '-m-2' : '-m-1'" >
            <CogSolid :class="size === 'large' ? 'h-7 w-7' : 'h-4 w-4'" />
        </div>
      </div>
      <div class="animate-pulse pt-1 text-sm font-semibold whitespace-normal truncate">
          <div>{{message}}</div>
      </div>
      
  </div>
</template>

<script>
import {CogIcon} from "@heroicons/vue/outline"
import {CogIcon as CogSolid} from "@heroicons/vue/solid"

export default {
    props:{
        message:{type:String,default:()=>{'Loading...'}},
        size:{type:String,default:''}
    },
    components:{
        CogIcon,
        CogSolid
    }

}
</script>

<style>

</style>