<template>
  <div class="w-full flex flex-col items-center space-y-1 flex-grow p-1 max-w-4xl">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-row items-center p-1 justify-between">
        <button class="p-1 bn-solid-green" @click="toggleNewNote()" :disabled="!allowEdit">
          <PlusIcon :class="showNewNote ? 'rotate-45' : null" />
          <DocumentIcon />
        </button>
        <button v-if="showNewNote" class="p-1 bn-solid-green" :disabled="!canSaveNote" @click="saveNote()" >
          <SaveIcon />
        </button>
    </div>
    <div class="w-full flex flex-col space-y-1 items-center overflow-y-auto border-b">
      <div v-if="showNewNote" class="w-full flex flex-col h-48">
        <TipTap @content="setNote($event)" />
      </div>
    </div>
    <div class="w-full p-1 flex flex-col flex-auto h-0 overflow-y-auto">
      <div class="w-full flex flex-col space-y-1 flex-grow overflow-y-auto">
        <div v-for="note in notes" :key="note._id" class="w-full p-1 border-b">
          <div class="w-full flex flex-row items-start space-x-1">
            <div class="w-full flex flex-col space-y-1 truncate">
              <div class="w-full flex flex-row items-center justify-between truncate font-semibold p-1">
                <div v-if="note.noteDate && isValid(new Date(note.noteDate))" class="w-full text-left truncate">{{format(new Date(note.noteDate),'MMM dd, yyyy')}}</div>
                <div v-if="note.author" class="w-full text-right truncate">{{note.author}}</div>
              </div>
              <TipTap v-if="editingNote.note && editingNote._id === note._id" :currentContent="editingNote.note ? decodeHtml(editingNote.note) : null" 
              @content="setEditedNote($event)"
              />
              <div v-else v-html="decodeHtml(note.note)" class="w-full whitespace-normal text-left px-3"></div>
              <div v-if="note.lastEdit" class="w-full flex truncate opacity-70 px-3">{{`last edit: ${note.lastEdit}`}}</div>
            </div>
            <div v-if="allowEdit" class="flex flex-col space-y-2 py-1">
              <button class="bn-icon-small bn-solid" 
              @click="editNoteId === note._id ? editNoteId = null : editNoteId = note._id" :disabled="isWorking">
                <XIcon v-if="editNoteId === note._id" />
                <PencilAltIcon v-else />
              </button>
              <DeleteConfirm v-if="editNoteId !== note._id" :size="'small'" @confirmedDelete="deleteNote(note._id)" :disableButton="isWorking" />
              <button v-else class="bn-icon-small bn-solid-green" :disabled="isWorking || (editingNote.note && editedNote === decodeHtml(editingNote.note)) || editedNote.replace(/<[^>]*>/g, '').trim().length === 0"
              @click="updateNote(note._id)"
              >
                <SaveIcon />
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PlusIcon, DocumentIcon,SaveIcon, PencilAltIcon,XIcon} from "@heroicons/vue/solid"
import TipTap from "@/components/ui/TipTap.vue"
import { ref, computed, inject, watchEffect} from 'vue'
import api from '@/api'
import {decodeHtml} from '@/shared'
import {format,isValid} from 'date-fns'
export default {
  props:{
    projectId:{type:String,default:null},
    notes:{type:Array,default:()=>{return []}},
    allowEdit:{type:Boolean,default:false}
  },
  emits:["updateInvoice","triggerProjectUpdate"],
  components:{PlusIcon,DocumentIcon,TipTap,SaveIcon,PencilAltIcon,XIcon},
  setup (props,{emit}) {

    const global = inject('global')
    const {setModalBlocked,sendChangeEvent} = global

    const isWorking = ref(false)
    const responseError = ref(null)
    const showNewNote = ref(false)
    const newNote = ref(null)
    const editNoteId = ref(null)
    const editedNote = ref(null)

    const toggleNewNote = ()=>{
      showNewNote.value = !showNewNote.value
      newNote.value = null
    }

    const canSaveNote = computed(()=>{
      return newNote.value ? newNote.value.replace(/<[^>]*>/g, '').trim().length > 0 : false
    })

    const editingNote = computed(()=>{
      return editNoteId.value && props.notes && Array.isArray(props.notes) ? props.notes.find(x=>x._id === editNoteId.value) : {}
    })

    watchEffect(() => {
      const newValue = editingNote.value;
        editedNote.value = newValue && newValue.note ? decodeHtml(newValue.note) : null
    })

    const setNote = (event)=>{
      newNote.value = event
    }

    const setEditedNote = (event) => {
      editedNote.value = event
    }

    const saveNote = async ()=>{
      isWorking.value = true
      setModalBlocked(true)
      responseError.value = null
      let body = {
        noteDate:new Date().toISOString(),
        note:newNote.value,
      }
      await api
      .post("projects/projectNotes/" + props.projectId,body)
      .then(()=>{
        emit("triggerProjectUpdate")
        sendChangeEvent('note','','',{projectId:props.projectId})
        toggleNewNote()
      })
      .catch((err)=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        isWorking.value = false
        setModalBlocked(false)
      })
    }

    const updateNote = async (id)=>{
      isWorking.value = true
      setModalBlocked(true)
      responseError.value = null
      let body = {
        note:editedNote.value
      }
      await api
      .put(`projects/projectNotes/${props.projectId}/${id}`,body)
      .then(()=>{
        emit("triggerProjectUpdate")
        sendChangeEvent('note','','',{projectId:props.projectId})
        editNoteId.value = null
      })
      .catch((err)=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        isWorking.value = false
        setModalBlocked(false)
      })
    }

    const deleteNote = async (id)=>{
      isWorking.value = true
      setModalBlocked(true)
      responseError.value = null
      await api
      .delete(`projects/projectNotes/${props.projectId}/${id}`)
      .then(()=>{
        emit("triggerProjectUpdate")
        sendChangeEvent('note','','',{projectId:props.projectId})
      })
      .catch((err)=>{
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(()=>{
        isWorking.value = false
        setModalBlocked(false)
      })
    }


    return {
      isWorking,
      responseError,
      showNewNote,
      toggleNewNote,
      newNote,
      canSaveNote,
      setNote,
      saveNote,
      decodeHtml,
      format,
      isValid,
      editNoteId,
      editingNote,
      editedNote,
      deleteNote,
      setEditedNote,
      updateNote
    }
  }

}
</script>

<style>

</style>