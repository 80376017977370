<template>
    <WorkingAndError v-if="isWorking || responseError" :isWorking="isWorking" :error="responseError" />
    <div v-else-if="roomResponseError" class="w-full error">{{roomResponseError}}</div>
    <div v-if="!isWorking" class="w-full flex flex-col space-y-1 flex-grow max-w-4xl">
        <div v-if="allowEdit" class="w-full flex items-center p-1">
            <Popper hover arrow placement="bottom" :content="showUpload ? 'Show Photos' : 'Upload Photos'">
                <button class="bn-icon-small" :class="showUpload ? 'bn-solid-sky' : null"
                @click="showUpload = !showUpload"
                >
                    <PhotographIcon v-if="showUpload" />
                    <UploadIcon v-else />
                </button>
            </Popper>
        </div>
        <div class="w-full flex flex-col flex-grow items-center flex-auto h-0 overflow-y-auto">
            <UploadProjectPhotos v-show="showUpload" :uploadToId="project._id" :projectRooms="rooms" @fileUpdate="getPhotos('silent')" />
            <DisplayProjectPhotos v-show="!showUpload" :photoFiles="photos && Array.isArray(photos) ? photos : []"  @deleteFile="removePhoto($event)" :allowEdit="allowEdit" />
        </div>
    </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import api from "@/api"
import {PhotographIcon,UploadIcon} from "@heroicons/vue/solid"
import UploadProjectPhotos from '@/components/projects/fileManagement/UploadProjectPhotos.vue'
import DisplayProjectPhotos from '@/components/projects/fileManagement/DisplayProjectPhotos.vue'
export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false}
    },
    components:{
        UploadProjectPhotos,
        DisplayProjectPhotos,
        PhotographIcon,
        UploadIcon
    },
    setup (props) {

        const isWorking = ref(false)
        const responseError = ref(null)
        const roomResponseError = ref(null)
        const showUpload = ref(false)
        const photos = ref([])
        const rooms = ref([])

        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getPhotos()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const getPhotos = async(type)=>{
            responseError.value = null
            if(type !== 'silent') { 
                isWorking.value = true
                setModalBlocked(true)
            }
            await api
            .get("projects/projectPhotos/" + props.project._id)
            .then((res)=>{
                res.data?.data ? photos.value = res.data.data : null
            })
            .then(async ()=>{
                type !== 'silent' ? await getRooms() : null
            })
            .catch((err)=>{
                err.response?.data?.includes("DOCTYPE") 
                ? responseError.value = 'Could not retrieve photos from Google.'
                : responseError.value =  err.response?.data?.error || err.message  || "Error Getting Photos."
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const getRooms = async()=>{
            roomResponseError.value = null
            await api
            .get("rooms/roomsByProject/" + props.project._id)
            .then((res)=>{
                if(res.data?.data) {
                rooms.value = res.data.data.map((obj => ({
                    ...obj,
                    avReadiness: obj.avReadiness.slice().sort((a, b) => a.displayOrder - b.displayOrder)
                    })))
                    .sort((a, b) => (a.roomType > b.roomType) ? 1 : -1)
                }
            })
            .catch((err)=>{
                roomResponseError.value = err.response?.data?.error || err.message || "Error Getting Rooms.";
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const removePhoto = async (id)=>{
            let fileIndex = photos.value.findIndex(photo=>photo.id === id)
            fileIndex >=0 ? photos.value.splice(fileIndex,1) : null
            sendChangeEvent('photos',props.project._id,'',{projectId:props.project._id})
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'photos':
                        e.detail?.projectId === props.project._id || e.projectId === props.project._id
                        ? getPhotos('silent') : null
                        break;
                    case 'room':
                        e.detail?.projectId === props.project._id || e.projectId === props.project._id
                        ? getRooms() : null
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            showUpload,
            isWorking,
            responseError,
            roomResponseError,
            photos,
            rooms,
            getPhotos,
            removePhoto
        }
    }

}
</script>

<style>

</style>