<template>
  <div class="w-full flex flex-col flex-grow items-center space-y-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- Summary Boxes -->
    <div class="w-full flex flex-col items-center px-1 py-2 max-w-3xl space-y-1">
        <div class="w-full flex flex-row items-center justify-center truncate flex-wrap text-sm">
            <div class="min-w-44 flex flex-col p-1 self-stretch">
                <div class="min-w-44 flex flex-col flex-grow p-1 rounded-md items-center justify-start border space-y-1 bg-opacity-60 bg-sky-700 border-sky-900">
                    <div class="w-full text-center whitespace-normal truncate font-semibold">Total Hours</div>
                    <div class="w-full text-center whitespace-normal truncate font-semibold">{{totalHours}}</div>
                    <div class="w-full text-center whitespace-normal truncate"
                    :class="totalHours-totalScheduledHours >= 0 ? 'text-green-600' : 'text-red-500'"
                    >{{totalHours-totalScheduledHours}}</div>
                </div>
            </div>
            <div class="min-w-44 flex flex-col p-1 self-stretch">
                <div class="min-w-44 flex flex-grow flex-col p-1 rounded-md items-center justify-center border space-y-1 bg-opacity-60"
                :class="totalScheduledHours <= totalHours ? 'bg-green-700  border-green-900' : 'bg-red-600 border-red-800'"
                >
                    <div class="w-full text-center whitespace-normal truncate font-semibold">Scheduled</div>
                    <div class="w-full text-center whitespace-normal truncate font-semibold">{{totalScheduledHours}}</div>
                    <div class="w-full text-center whitespace-normal truncate text-xs">{{`${pctTotals.scheduled} %`}}</div>
                </div>
            </div>
            <div class="min-w-44 flex flex-col p-1 self-stretch">
                <div class="min-w-44 flex flex-grow flex-col p-1 rounded-md items-center justify-center border space-y-1 bg-opacity-60"
                :class="totalClockedHours <= totalHours ? 'bg-green-700  border-green-900' : 'bg-red-600 border-red-800'"
                >
                    <div class="w-full text-center whitespace-normal truncate font-semibold">Clocked</div>
                    <div class="w-full text-center whitespace-normal truncate font-semibold">{{totalClockedHours}}</div>
                    <div class="w-full text-center whitespace-normal truncate text-xs">{{`${pctTotals.clocked} %`}}</div>
                </div>
            </div>
        </div>
    </div>
<!-- Breakdown -->
    <div v-if="scheduledTimeGrouped && scheduledTimeGrouped.length > 0" class="w-full flex items-center justify-center">
        <div class="w-full max-w-3xl text-left px-1 whitespace-normal truncate p-1 bg-sky-700 bg-opacity-50 font-semibold">
            Schedule Breakdown
        </div>
    </div>
    <div v-if="scheduledTimeGrouped && scheduledTimeGrouped.length > 0" class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto items-center p-1">
        <div class="w-full flex flex-col flex-grow items-center max-w-3xl overflow-y-auto">
            <div v-for="(item,index) in scheduledTimeGrouped" :key="index" class="w-full flex p-1 border-b">
                <div class="w-full flex flex-row items-center flex-wrap">
                    <div class="flex-grow text-left whitespace-normal truncate p-1 font-semibold">{{item.type}}</div>
                    <div class="text-left whitespace-normal truncate p-1 font-semibold">{{`${item.totalHours} hrs`}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
export default {
	components: { },
  props:{
    project:{type:Object,default:()=>{return {}}},
    allowEdit:{type:Boolean,default:false}
  },
  setup (props) {
    const global = inject('global')
    const {setModalBlocked} = global
    const isWorking = ref(false)
    const responseError = ref(null)
    const clockedTime = ref([])
    const scheduledTime = ref([])

    onMounted(()=>{
        window.addEventListener("data_change_from_socket",handleSocketChange)
        getProjectTime()
    })

    onUnmounted(()=>{
        window.removeEventListener("data_change_from_socket",handleSocketChange)
    })

    const totalHours = computed(()=>{
        if(Array.isArray(props.project.services)) {
            let totalHours = 0
            props.project.services.forEach(service =>{
                totalHours += service.hours
            })
            return parseFloat(totalHours.toFixed(2))
        } else {
            return 0
        }
    })

    const totalClockedHours = computed(()=>{
        let totalHours = 0
        clockedTime.value.forEach(allocation=>{
            totalHours += allocation.totalTime || 0
        })
        return parseFloat(totalHours.toFixed(2))
    })

    const totalScheduledHours = computed(()=>{
        let totalHours = 0
        scheduledTime.value.forEach(appointment=>{
            totalHours += appointment.duration || 0
        })
        return parseFloat(totalHours.toFixed(2))
    })

    const scheduledTimeGrouped = computed(()=>{
        const tempGroup = {}
        scheduledTime.value.forEach(appt=> {
            if(!appt.appointmentType) {
                return;
            }

            const type = appt.appointmentType

            if(!tempGroup[type]) {
                tempGroup[type] = {type:type,totalHours:0}
            }

            tempGroup[type].totalHours += appt.duration || 0
        })

        Object.values(tempGroup).forEach(data=>{
            data.totalHours = parseFloat(data.totalHours.toFixed(2))
        })

        return Object.values(tempGroup).sort((a, b) => a.type.localeCompare(b.type));
    })

    const pctTotals = computed(() => {
        let percentages = { clocked: 0, scheduled: 0 };
        const baseTotalHours = totalHours.value;

        if (baseTotalHours > 0) {  // Ensure totalHours is not zero to avoid division by zero
            percentages.clocked = parseFloat((totalClockedHours.value / baseTotalHours * 100).toFixed(1));
            percentages.scheduled = parseFloat((totalScheduledHours.value / baseTotalHours * 100).toFixed(1));
        } else {
            percentages.clocked = 0.0;  // Ensure consistent formatting by explicitly setting to 0.00
            percentages.scheduled = 0.0;
        }

        return percentages;
    });

    const getProjectTime = async (type)=>{
        if(type !== 'silent') {
            isWorking.value = true
            setModalBlocked(true)
        }
        responseError.value = null
        await api
        .get(`projects/projectTime/${props.project?._id}`)
        .then((res)=>{
            if(res.data?.data && typeof res.data.data === 'object' && !Array.isArray(res.data.data)) {
                if(Array.isArray(res.data.data.clocked)) {clockedTime.value = res.data.data.clocked}
                if(Array.isArray(res.data.data.scheduled)) {scheduledTime.value = res.data.data.scheduled}
            }
        })
        .catch((err)=>{
            responseError.value = err.response?.data?.error || err.message
        })
        .finally(()=>{
            isWorking.value = false
            setModalBlocked(false)
        })
    }

    const handleSocketChange = (e)=>{
        if(e.detail?.type) {
            switch(e.detail?.type) {
                case 'project':
                    if(e.detail.data?.projectId && e.detail.data.projectId === props.project._id) {
                        getProjectTime('silent')
                    }
                    break;
                case 'timeclock':
                    getProjectTime('silent')
                    break;
                default:
                    break
            }
        }
    }

    return {
        isWorking,
        responseError,
        totalHours,
        totalClockedHours,
        totalScheduledHours,
        pctTotals,
        scheduledTimeGrouped
    }
  }
}
</script>

<style>

</style>