<template>
  <div v-if="showModal" class="h-screen w-screen bg-gray-300 absolute top-0 left-0 pt-4 sm:pt-16 pb-4 px-4 overflow-auto flex justify-center min-h-768 z-90"
    @drop.prevent
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
  >
    <div class="bg-white w-full rounded-lg p-4 h-full max-w-8xl flex flex-col">
            <div class="flex flex-row items-center border-b-2 w-full justify-between p-2">
                <div class="font-extrabold text-2xl text-primary overflow-y-auto">
                    <slot name="modalHeader"></slot>
                </div>
                <button class="btn-icon btn-primary" @click="toggleModal()" :disabled="blockClose || isModalBlocked">
                    <XIcon class="h-5 w-5" />
                </button>
            </div>
            <div class="w-full p-1 flex flex-col overflow-y-auto flex-grow" id="ModalTop">
                <slot name="modalBody"></slot>
            </div>
            <div class="w-full p-2">
                <slot name="modalFooter"></slot>
            </div>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeMount, onBeforeUnmount } from "vue";
import {XIcon} from "@heroicons/vue/outline"
export default {
    components:{XIcon},
    props:{
        blockClose:{type:Boolean,default:()=>{return false}}
    },
    setup() {
        const showModal = inject("showModal");
        const global = inject('global')
        const {isModalBlocked} = global

        const toggleModal = () => {
        showModal.value = !showModal;
        };

        onBeforeMount(async () => {
            window.addEventListener("scrollModalTop", () => {
            let element = document.getElementById("ModalTop");
            if(element) {
                element.scrollBy(0, -element.scrollHeight)
            }
            });
        });

        onBeforeUnmount(() => {
        window.removeEventListener("scrollModalTop", () => {});
        });
    
    return {
        showModal,
        toggleModal,
        isModalBlocked
        }
    }

}
</script>

<style>

</style>