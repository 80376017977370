<template>
  <div class="w-full px-2">
      <div class="flex flex-row flex-wrap justify-center w-full md:flex-nowrap md:flex-grow-0 border panel"
        :class="largeShadow ? 'shadow-2xl': 'shadow-md'"
      >
        <div class="p-1 flex flex-row w-24 flex-grow-0 justify-center">
          <div v-if="!userInfo?.profileImageId || userInfo?.profileImageId ===''" >
            <div class="bg-neutral-300 dark:bg-neutral-600 h-16 w-16 mx-auto rounded-full"></div>
          </div>
          <div v-else class="max-w-24">
            <img :src="`https://drive.google.com/thumbnail?authuser=0&size=w50&id=${userInfo.profileImageId}`" class="rounded-full object-contain w-16 h-16 mx-auto" alt="" />
          </div>
        </div>
        <div class=" w-full flex-grow">
          <div class="flex flex-row h-auto md:h-full flex-wrap md:flex-nowrap w-full" >
            <div class="h-full flex flex-col justify-center flex-grow w-full">
              <div v-if="!showInfo" class="md:px-1 h-full flex flex-col w-full justify-center">
                <div class=" w-full text-center md:text-left text-sm font-bold truncate px-1">{{userInfo?.name}}</div>
                <div class=" w-full text-center md:text-left md:px-1 text-xs">{{userInfo?.role?.role}}</div>
              </div>
              <div v-else class=" md:px-1 h-full flex flex-col w-full justify-center">
                <div class=" w-full text-center md:text-left text-sm px-1 font-semibold">{{userInfo?.email}}</div>
                <div class=" w-full text-center md:text-left text-xs px-1"><span class=" mr-2 font-semibold" :class="!userInfo.officePhone?.phoneNumber ? 'opacity-0': 'opacity-100'">o:</span>  {{userInfo.officePhone?.phoneNumber}}</div>
                <div class=" w-full text-center md:text-left text-xs px-1"><span class=" mr-1 font-semibold" :class="!userInfo.mobilePhone?.phoneNumber ? 'opacity-0': 'opacity-100'">m:</span> {{userInfo.mobilePhone?.phoneNumber}}</div>
              </div>
            </div>
            <div class=" w-full md:w-auto md:text-center flex justify-start md:justify-end p-1">
              <button class="bn-icon-small dark:bn-solid" @click="toggleInfo">
                <InformationCircleIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {InformationCircleIcon} from '@heroicons/vue/outline' 
export default {
    props:{
        userInfo:{
            type:Object,
            required:true,
            default:()=>{return {}}
        },
        largeShadow: {
            type:Boolean,
            default: false
        }
    },
    components:{InformationCircleIcon},
    data: ()=>{
        return{
            showInfo:false
        }
    },
    methods:{
    toggleInfo: function () {
      this.showInfo = !this.showInfo
    }
    }

}
</script>

<style>

</style>