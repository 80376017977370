<template>
  <div class="w-full p-1">
    <LoadingData v-if="isWorking" />
    <div v-else-if="responseError" class="error">{{responseError}}</div>
    <div v-else-if="companyManagers.length === 0">No Project Managers Available</div>
    <select v-else v-model="selectedManagerId" class="w-full" @change="selectProjectManager()" :disabled="isDisabled">
      <option hidden disabled :value="null">Client Manager</option>
      <option v-for="mgr in companyManagers" :key="mgr._id" :value="mgr._id">{{mgr.name}}</option>
    </select>
  </div>
</template>

<script>
import {onMounted, ref } from 'vue'
import api from "@/api"
export default {
  props:{
    currentManagerId:{type:String,default:null},
    isDisabled:{type:Boolean,default:false}
  },
  emits:["managerSelected"],
  setup (props,{emit}) {
    const isWorking = ref(false)
    const responseError = ref(null)
    const selectedManagerId = ref(props.currentManagerId)
    const companyManagers = ref([])

    onMounted(()=>{
      getClientManagers()
    })

    const getClientManagers = async ()=>{
      isWorking.value = true
      responseError.value = null
      await api
      .get("projects/companyManagers/")
      .then((res) => {
        companyManagers.value = res.data?.data || []
      })
      .catch((err) => {
        responseError.value = err.response?.data?.error || err.message
      })
      .finally(() => {
        isWorking.value = false
      });
    }

    const selectProjectManager = ()=>{
      selectedManagerId.value !== props.currentManagerId
      ? emit("managerSelected",selectedManagerId.value)
      : null
    }

    return  {
      isWorking,
      responseError,
      selectedManagerId,
      selectProjectManager,
      companyManagers
    }
  }

}
</script>

<style>

</style>