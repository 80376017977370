<template>
  <div class="w-full flex flex-row space-x-1 py-1 items-center">
    <div class="px-0.5 flex flex-row justify-center" :class="numberInput ? !isPhoneNumberValid ? 'text-red-500' : 'text-green-500' : null">
      <DeviceMobileIcon v-if="iconType==='mobile'" class="w-4 h-4 flex-shrink-0" />
      <PhoneIcon v-else class="w-4 h-4 flex-shrink-0" />
    </div>
    <select v-model="countrySelected" class="w-14 flex-shrink-0" 
    @change="formatPhoneNumber()" :disabled="!allowEdit || disable"
    >
      <option v-for="country in countryAbbrOptions" :key="country .abbr" :value="country">{{country.abbr}}</option>
    </select>
    <input v-model="numberInput" type="text" class="w-32" :class="numberInput && !isPhoneNumberValid ? 'text-red-500' : null"
    @input="formatPhoneNumber();emitNumber()" :disabled="!allowEdit || disable"
    >
    <input v-if="iconType !== 'mobile'" v-model="extension" type="text" class="w-16 flex-shrink-0 text-center" placeholder=".ext" :disabled="!allowEdit || disable"
    @blur="emitNumber()"
    >
    <button v-if="allowDelete && currentValue.rawInput" class="bn-icon-small bn-red" @click="deleteNumber()" :disabled="!allowEdit || disable">
      <TrashIcon />
    </button>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUpdated } from 'vue'
import {PhoneIcon,DeviceMobileIcon,TrashIcon} from "@heroicons/vue/outline"
export default {
  props:{
    iconType:{type:String,default:'office'},
    currentValue:{type:Object,default:()=>{return {}}},
    allowDelete:{type:Boolean,default:false},
    allowEdit:{type:Boolean,default:true},
    disable:{type:Boolean,default:false}
  },
  components:{PhoneIcon,DeviceMobileIcon,TrashIcon},
  emits:["phoneValidated"],
  setup (props,{emit}) {
    const countryAbbrOptions = ref([
        { abbr: "US", code: "+1"},
        { abbr: "CA", code: "+1"},
        { abbr: "UK", code: "+44"},
    ])
    const countrySelected = ref({ abbr: props.currentValue.countryAbbr || "US", code: props.currentValue.countryCode || "+1"})
    const numberInput = ref(props.currentValue.rawValue || null)
    const phoneType = ref(props.currentValue.phoneType || props.iconType)
    const extension = ref(props.currentValue.phoneExtension || props.currentValue.extension || null)

    watch(()=>props.currentValue,
      (newValue)=>{
        numberInput.value = newValue.rawValue || null
        extension.value = newValue.phoneExtension || newValue.extension || null
      }
    )

    onMounted(()=>{
      formatPhoneNumber()
    })

    onUpdated(()=>{
      formatPhoneNumber()
    })

    const isPhoneNumberValid = computed(() => {
      if(numberInput.value) {
        let number = numberInput.value.replace(/\D/g, '')
        if (countrySelected.value.abbr === 'UK') {
            number = number.slice(0, 11)
            // UK phone numbers are typically 10 or 11 digits
            return number && (number.length === 10 || number.length === 11);
        } else {
            number = number.slice(0, 10)
            // Default validation for other countries (can be adjusted as needed)
            return number && number.length === 10;
        }
      } else {
        return false
      }
    })

    watch([countrySelected,isPhoneNumberValid], ()=>{
          isPhoneNumberValid.value
          ? emitNumber()
          : null
    })

    const formatPhoneNumber = ()=>{
      if(numberInput.value) {
        let number = numberInput.value.replace(/\D/g, '')
        if(countrySelected.value.abbr === 'UK') {
          number = number.slice(0, 11)
          if (number.length === 10) {
              // Format: XXXX XXX XXX
              numberInput.value = number.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
          } else if (number.length === 11) {
              // Format: XXXXX XXXXXX or XXXX XXX XXXX
              numberInput.value = number.replace(/(\d{5})(\d{6})/, '$1 $2');
              // Alternative pattern: digitsOnly.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
          } else {
              // Default to unformatted for other lengths
              numberInput.value = number
          }
        } else {
          number = number.slice(0, 10)
          numberInput.value = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        }
      }
    }

    const emitNumber = ()=>{
      if(isPhoneNumberValid.value) {
        let ObjectToEmit = {
          'phoneNumber': numberInput.value ? countrySelected.value.code + numberInput.value.replace(/\D/g, '') : null,
          'phoneType': phoneType.value,
          'countryAbbr': countrySelected.value.abbr,
          'countryCode':countrySelected.value.code,
          'rawValue': numberInput.value ? numberInput.value.replace(/\D/g, '') : null,
          'phoneExtension': extension.value || null
        }
        emit("phoneValidated",ObjectToEmit)
      } else if(!numberInput.value || numberInput.value.length === 0) {
        emit("phoneValidated",{})
      }
    }

    const clearNumber = ()=>{
      numberInput.value = null
    }

    const deleteNumber = ()=>{
      emit("phoneValidated",{validated:false})
    }

    return {
      countryAbbrOptions,
      countrySelected,
      numberInput,
      formatPhoneNumber,
      isPhoneNumberValid,
      deleteNumber,
      phoneType,
      emitNumber,
      extension,
      clearNumber
    }
  }

}
</script>

<style>

</style>