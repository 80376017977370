<template>
  <div class="w-full p-1" v-if="uploadPercentage > 0">
    <div
      class="
        bg-sky-600
        rounded-md
        flex flex-nowrap
        px-2
        text-white text-xs
        font-bold
        h-4
        shadow-inner
      "
      :class="
        uploadPercentage > 0 && uploadPercentage < 100 ? 'animate-pulse' : ''
      "
      :style="{ width: uploadPercentage + '%' }"
    >
      <div
        v-if="uploadPercentage > 0"
        class="bg-yellow p-2 h-full flex items-center"
      >
        {{ uploadPercentage }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadPercentage: { type: Number, default: 0 },
  },
};
</script>

<style>
</style>