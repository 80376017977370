<template>
  <div class="flex items-center h-full w-screen bg-neutral-300 dark:bg-neutral-900 top-0 p-12 justify-start fixed pin flex-col px-4" style="min-height: 568px">
  <!-- Heading -->
    <div class="w-full mb-3 font-extrabold text-xl whitespace-nowrap flex flex-row items-center justify-center space-x-2 p-4">
      <LockClosedIcon class="w-6 h-6" />
      <div>Request Password Reset</div>
    </div>
  <!-- Dialog -->
    <div class="bg-neutral-50 dark:bg-neutral-900 border rounded-md w-full max-w-lg min-w-screen-md p-6 flex flex-col space-y-2 shadow-lg">
      <div v-if="!resetPasswordFlag" class="font-bold text-sm text-left font-sans">
        Please enter registered email address
      </div>
      <div v-if="isLoading">
        <LoadingData />
      </div>
      <div v-if="responseError" class="error">
        {{ responseError }}
      </div>
      <!-- Message on response -->
      <div v-if="resetPasswordFlag" class="flex flex-col space-y-6 text-sm">
        <div class="text-center">If an account exists with that email, a reset password email has been sent.</div>  
        <div class="font-bold">Please close this tab.</div>
      </div>
      <!-- Dialog before submitting -->
      <div v-else class="flex flex-col w-full py-2 space-y-1" >
        <div class="flex flex-row w-full items-center max-w-md">
          <AtSymbolIcon class="h-8 w-8 px-1" :class="error.email ? 'text-red-700' : null" />
          <input v-model.trim="userEmail" type="email" name="email" placeholder="email" class="rounded-none w-full max-w-sm border-b-2 dark:bg-neutral-900"
          :class="error.email ? 'border-b-2 border-red-700' : null" @input="error.email = ''"
           />
        </div>
        <div class="error">{{ error.email }}</div>
        <div class="w-full flex justify-start">
          <button class="p-1" @click="validateEmail">
            <div class="px-1">Reset Password</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AtSymbolIcon,LockClosedIcon} from "@heroicons/vue/outline"

import { ref } from "vue";
import axios from "axios";
import global from "../global";


const isLoading = ref(false);
const responseError = ref("");
const userEmail = ref("");
const error = ref([{ email: "" }]);

export default {
  components: {
    AtSymbolIcon,
    LockClosedIcon
  },
  setup() {
    const { resetPasswordFlag, setResetPasswordFlag } = global;

    const validateEmail = () => {
      !userEmail.value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ? (error.value.email = "Please enter a valid email")
        : (error.value.email = "");

      if (!error.value.email) {
        onSubmit();
      }
    };

    const onSubmit = async () => {
      isLoading.value = true;
      responseError.value = "";
      let requestBody = {
        email: userEmail.value,
      };
      await axios
        .post("/api/v1/auth/forgotpassword", requestBody, {
          withCredentials: false,
        })
        .then((response) => {
          if (response.status == 200) {
            setResetPasswordFlag();
          }
        })
        .catch((err) => {
          responseError.value = err.response.data.error || err.message
        })
        .finally(()=>{
          isLoading.value = false;
        })
    };

    return {
      onSubmit,
      isLoading,
      responseError,
      resetPasswordFlag,
      validateEmail,
      error,
      userEmail,
    };
  },
};
</script>

<style>
</style>