<template>
    <div v-if="address && Object.keys(address).length > 0" class="w-full">
        <div class="flex flex-row space-x-1 p-0.5">
            <div class="whitespace-normal">{{address.street_number}}</div>
            <div class="whitespace-normal">{{address.street}}</div>
        </div>
        <div v-if="showAddressTwo && address.addressTwo || address.address2" class="flex flex-row p-0.5">
            <div class="whitespace-normal">{{address.addressTwo || address.address2}}</div>
        </div>
        <div class="w-full flex flex-row flex-wrap">
            <div class="p-0.5">{{address.city}}</div>,
            <div class="p-0.5">{{address.state}}</div>
            <div class="p-0.5">{{address.postalCode}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        address:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        showAddressTwo:{
            type:Boolean,
            default:true
        }
    }
}
</script>

<style>

</style>