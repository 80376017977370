<template>
  <div class="w-full flex flex-col flex-grow items-center p-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div v-if="!isWorking && sortedStaff.length === 0" class="w-full p-1 error">No Staff Returned</div>
    <div v-else-if="Array.isArray(sortedStaff) && sortedStaff.length > 0"  class="w-full flex flex-col flex-grow max-w-3xl">
        <div class="w-full text-left px-1 py-2 border-b font-semibold text-sky-600 whitespace-normal">Staff assigned to project, ordered by most recent.</div>
        <div class="w-full flex flex-col space-y-1 flex-auto h-0 overflow-y-auto">
            <div v-for="tech in sortedStaff" :key="tech._id" class="w-full flex flex-row items-center p-1 border-b">
                <div class="w-full flex flex-row items-center justify-between truncate flex-wrap">
                    <div class="w-1/2 p-1 text-left font-semibold">
                        {{tech.name}}
                    </div>
                    <div class="flex-grow flex flex-wrap justify-between">
                        <div class="w-auto text-left p-1">
                            {{tech.email}}
                        </div>
                        <div class="w-auto flex justify-end p-1">
                            <div v-if="tech.mobilePhone?.phoneNumber">{{displayUSPhone(tech.mobilePhone.phoneNumber)}}</div>
                            <div v-else-if="tech.officePhone?.phoneNumber">{{`${displayUSPhone(tech.officePhone?.phoneNumber)} ${tech.officePhone?.phoneExtension}`}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import {displayUSPhone} from '@/shared'
import api from '@/api'
export default {
    props:{
        project:{type:Object,default:()=>{return {}}}
    },
    setup(props) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const staff = ref([])

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getProjectTechnicians()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const sortedStaff = computed(()=>{
            const userIdMap = new Map();
            staff.value.forEach(staffMember => {
                const userId = staffMember.userId;
                if (userId && !userIdMap.has(userId._id)) {
                userIdMap.set(userId._id, userId);
                }
            });
            const userArray = Array.from(userIdMap.values());
            return userArray.filter(x=>x.organization?.orgType && x.organization?.orgType.includes('primary') )
        })

        const getProjectTechnicians = async ()=>{
            isWorking.value = true
            responseError.value = null
            await api
            .get(`projects/projectStaff/${props.project?._id}`)
            .then(res=>{
                if(Array.isArray(res.data.data)) {
                    staff.value = res.data.data
                }
            })
            .catch(err=>{
                responseError.value = err?.response?.data?.error || err?.message
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'appointment':
                        break;
                    case 'appointment_delete':
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            isWorking,
            responseError,
            sortedStaff,
            displayUSPhone
        }
    }

}
</script>

<style>

</style>