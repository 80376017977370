<template>
  <div class="h-screen w-screen bg-neutral-500 dark:bg-neutral-900 bg-opacity-80 dark:bg-opacity-90 absolute top-0 left-0 p-4 overflow-hidden flex justify-center min-h-568 z-90 modal-back-fade-in text-xs"
  @drop.prevent
  @dragover.prevent
  @dragenter.prevent
  @dragleave.prevent
  >
    <div ref="modalContainer" class="w-full h-full flex flex-col flex-grow p-1 items-center overflow-y-auto">
      <div class="w-full flex-grow flex flex-col items-center bg-neutral-100 dark:bg-neutral-800 rounded-md modal-fade-in" :class="{'max-w-7xl':!showFull}">
        <div v-if="showTitle" class="modal-header">
          <div class="w-full text-left truncate px-1 text-xl font-semibold">{{title}}</div>
          <button class="bn-icon bn-solid dark:bn-solid-sky" @click="closeModal()" :disabled="isModalBlocked"><XIcon /></button>
        </div>
        <div class="w-full flex flex-col flex-grow p-1 items-center" :class="!showTitle ? 'pt-5' : null">
            <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, nextTick, computed, inject } from 'vue';
import {XIcon} from "@heroicons/vue/outline"

export default {
  props:{
    title:{type:String,default:null},
    showTitle:{type:Boolean,default:true},
    showFull:{type:Boolean,default:false}
  },
  emits:["closeModal"],
  components:{XIcon},
  setup(props,{emit}) {
    let previousTitle
    const global = inject('global')
    const {isModalBlocked} = global
    const modalContainer = ref(null);

    const focusableElementsInModal = computed(() => {
      if (modalContainer.value) {
        let elements = Array.from(modalContainer.value.querySelectorAll(
          'a[href], area[href], input:not([disabled]):not([type="file"][hidden]), select:not([disabled]), textarea:not([disabled]), ' +
          'button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]'
        ));

        // Sort elements by tabindex. Elements without a tabindex or with tabindex="0" are treated as 0 and sorted based on their position in the DOM.
        elements.sort((a, b) => {
          let tabindexA = a.getAttribute('tabindex') || 0;
          let tabindexB = b.getAttribute('tabindex') || 0;

          tabindexA = parseInt(tabindexA, 10);
          tabindexB = parseInt(tabindexB, 10);

          // Elements with a positive tabindex are navigated to first, in order of their tabindex.
          if (tabindexA > 0 && tabindexB > 0) {
            return tabindexA - tabindexB;
          }
          // Elements with a tabindex of 0 or no tabindex are navigated to last, in the order they appear in the DOM.
          if (tabindexA === 0 && tabindexB === 0) {
            return 0; // Keep original order if both are 0
          }
          // Any element with a positive tabindex should come before elements with a tabindex of 0 or no tabindex.
          if (tabindexA > 0) {
            return -1;
          }
          if (tabindexB > 0) {
            return 1;
          }
              // Fallback to original order if none of the above conditions are met, though this line is technically redundant.
              return 0;
        });
        
        return elements;
      } else {
        return [];
      }
    });


    const trapFocus = (e) => {
      if (!modalContainer.value) {
        console.error("Modal container is not available"); // Debugging log
        return;
      }

      const firstFocusableElement = focusableElementsInModal.value[0];
      const lastFocusableElement = focusableElementsInModal.value[focusableElementsInModal.value.length - 1];

      if (e.key !== 'Tab' && e.keyCode !== 9) return;

      const focusableElementsArray = Array.from(focusableElementsInModal.value);

      const currentFocus = document.activeElement
      if (currentFocus.type === 'datetime-local') {
        // Skip trapping logic for datetime-local to allow normal tab behavior
      return;
      }

        if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement || !focusableElementsArray.includes(document.activeElement)) {
            e.preventDefault();
            lastFocusableElement.focus();
        }
        } else {
        if (document.activeElement === lastFocusableElement || !focusableElementsArray.includes(document.activeElement)) {
            e.preventDefault();
            firstFocusableElement.focus();
        }
        }
    };

    onMounted(() => {
      previousTitle = document.title
      if(props.title) {document.title = props.title}
      nextTick(() => {
        document.addEventListener('keydown', trapFocus);
        //if (modalContainer.value && modalContainer.value.querySelector) {
        //  const firstFocusableElement = modalContainer.value.querySelector(
        //    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), ' +
        //    'button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]'
        //  );
        //  if (firstFocusableElement) {
        //    firstFocusableElement.focus();
        //  }
        //}
      });
    });

    onUnmounted(() => {
      document.title = previousTitle
      document.removeEventListener('keydown', trapFocus);
    });

    const closeModal = ()=>{
      emit("closeModal")
    }

    return {
      modalContainer,
      closeModal,
      isModalBlocked
    };
  }
}
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-fade-in {
  animation: fadeIn 0.7s ease-out;
}

.modal-back-fade-in {
  animation: fadeIn 0.3s ease-out;
}

</style>