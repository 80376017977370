<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 114.3"
    fill="currentColor"
  >
    <path
      d="M17,45.7V73.34a20,20,0,0,0,40,0V45.8a29,29,0,0,1,57.93,0V68.6h5.36a2.59,2.59,0,0,1,2.58,2.58V98.41A2.59,2.59,0,0,1,120.3,101h-.52v9.53A3.79,3.79,0,0,1,116,114.3H104.91a3.77,3.77,0,0,1-2.59-1l-.08-.07a3.78,3.78,0,0,1-1.11-2.67V101h-.52A2.59,2.59,0,0,1,98,98.41V71.18a2.59,2.59,0,0,1,2.58-2.58H106V45.8a20,20,0,0,0-40,0V73.34a29,29,0,0,1-57.93,0V45.7H2.58A2.59,2.59,0,0,1,0,43.12V15.89a2.59,2.59,0,0,1,2.58-2.58H3.1V3.78A3.79,3.79,0,0,1,6.88,0H18a3.81,3.81,0,0,1,3.78,3.78v9.53h.52a2.59,2.59,0,0,1,2.58,2.58V43.12a2.59,2.59,0,0,1-2.58,2.58ZM117.38,101H103.53v9.53a1.38,1.38,0,0,0,.4,1l0,0a1.39,1.39,0,0,0,.94.37H116a1.38,1.38,0,0,0,1.38-1.39V101ZM5.5,13.31H19.35V3.78A1.38,1.38,0,0,0,18,2.39H6.88A1.38,1.38,0,0,0,5.5,3.78v9.53Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>