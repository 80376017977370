<template>
  <div class="w-full h-full lg:px28 flex flex-row space-x-3 items-center justify-center error py-6">
    <BanIcon class="w-8 h-8" />
    <div class="text-lg">Access Denied</div>
  </div>
</template>

<script>
import {BanIcon} from "@heroicons/vue/outline"
export default {
    components:{BanIcon}
}
</script>

<style>

</style>